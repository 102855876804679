import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import { Convert } from "./types/LimbLengthType";
import { generateForm } from "./InitialLimbLengthData";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import moment from "moment";

export const LimbLengthSchema = z.object({
  id: z.string().optional().default(""),
  masterAssessmentId: z.string().optional().default(""),
  clientId: z.string().optional().default(""),
  entityId: z.string().optional().default(""),
  entityBranchId: z.string().optional().default(""),
  trueLimbLengthLeft: z.number().optional().default(0),
  trueLimbLengthRight: z.number().optional().default(0),
  apparentLimbLengthLeft: z.number().optional().default(0),
  apparentLimbLengthRight: z.number().optional().default(0),
  humeralLimbLengthLeft: z.number().optional().default(0),
  humeralLimbLengthRight: z.number().optional().default(0),
  ulnarLimbLengthLeft: z.number().optional().default(0),
  ulnarLimbLengthRight: z.number().optional().default(0),
  femoralLimbLengthLeft: z.number().optional().default(0),
  femoralLimbLengthRight: z.number().optional().default(0),
  tibialLimbLengthLeft: z.number().optional().default(0),
  tibialLimbLengthRight: z.number().optional().default(0),
});
export type LimbLengthSchemaType = z.infer<typeof LimbLengthSchema>;

type LimbLengthAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function LimbLengthAssessmentComponent(
  props: LimbLengthAssessmentProps
) {
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  const initialize = () => {
    // set default values for the form
    return generateForm(props.masterAssessmentId);
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<LimbLengthSchemaType>({
    resolver: zodResolver(LimbLengthSchema),
    defaultValues: LimbLengthSchema.parse(initialize()),
  });

  const userData = getUserPersistedOnLocalStorage();

  const [lastUpdateAt, setLastUpdatedAt] = React.useState("");

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    const initializeVitalAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getLimbLengthAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const convertedLimbLength = Convert.toLimbLength(
            JSON.stringify(data)
          );

          reset(convertedLimbLength);
          setLastUpdatedAt(data["audit"]["lastUpdatedAt"]);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          setLoading(false);
        }
      }
    };
    initializeVitalAssessment();
  }, []);

  const onSubmit: SubmitHandler<LimbLengthSchemaType> = async (data: any) => {
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        LimbLengthSchema.parse(data);
        data.id = props.assessmentId;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";

        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateLimbLengthAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Limb Length Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    } else {
      try {
        LimbLengthSchema.parse(data);

        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createLimbLengthAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Limb Length Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col md:flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Limb Length Assessment
        </h3>
        <p className="text-sm text-gray-500">
          {props.assessmentId !== "000000000000000000000000" &&
            `Last updated: ${
              lastUpdateAt &&
              moment(new Date(lastUpdateAt)).format("DD-MM-YYYY") !==
                "01-01-0001"
                ? moment(new Date(lastUpdateAt)).format("DD-MM-YYYY")
                : "N/A"
            }`}
        </p>
      </div>

      <hr className="w-full" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
            Limb Length
          </div>
          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="border px-2 py-1 border-slate-300 text-center">
                  Name
                </th>
                <th className="border px-2 py-1 border-slate-300 text-center">
                  Left{" "}
                </th>
                <th className="border px-2 py-1 border-slate-300 text-center">
                  Right{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 text-center">
                  True Limb Length
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`trueLimbLengthLeft`, {
                      valueAsNumber: true,
                    })}
                    name={`trueLimbLengthLeft`}
                    key={`trueLimbLengthLeft`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`trueLimbLengthRight`, {
                      valueAsNumber: true,
                    })}
                    name={`trueLimbLengthRight`}
                    key={`trueLimbLengthRight`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 text-center">
                  Apprant Limb Length
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`apparentLimbLengthLeft`, {
                      valueAsNumber: true,
                    })}
                    name={`apparentLimbLengthLeft`}
                    key={`apparentLimbLengthLeft`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`apparentLimbLengthRight`, {
                      valueAsNumber: true,
                    })}
                    name={`apparentLimbLengthRight`}
                    key={`apparentLimbLengthRight`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
            Segmental Limb Length
          </div>

          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="border px-2 py-1 border-slate-300 text-center">
                  Name
                </th>
                <th className="border px-2 py-1 border-slate-300 text-center">
                  Left{" "}
                </th>
                <th className="border px-2 py-1 border-slate-300 text-center">
                  Right{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 text-center">
                  Humeral Limb Length
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`humeralLimbLengthLeft`, {
                      valueAsNumber: true,
                    })}
                    name={`humeralLimbLengthLeft`}
                    key={`humeralLimbLengthLeft`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`humeralLimbLengthRight`, {
                      valueAsNumber: true,
                    })}
                    name={`humeralLimbLengthRight`}
                    key={`humeralLimbLengthRight`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 text-center">
                  Ulnar Limb Length
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`ulnarLimbLengthLeft`, {
                      valueAsNumber: true,
                    })}
                    name={`ulnarLimbLengthLeft`}
                    key={`ulnarLimbLengthLeft`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`ulnarLimbLengthRight`, {
                      valueAsNumber: true,
                    })}
                    name={`ulnarLimbLengthRight`}
                    key={`ulnarLimbLengthRight`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 text-center">
                  Femoral Limb Length
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`femoralLimbLengthLeft`, {
                      valueAsNumber: true,
                    })}
                    name={`femoralLimbLengthLeft`}
                    key={`femoralLimbLengthLeft`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`femoralLimbLengthRight`, {
                      valueAsNumber: true,
                    })}
                    name={`femoralLimbLengthRight`}
                    key={`femoralLimbLengthRight`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 text-center">
                  Tibial Limb Length
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`tibialLimbLengthLeft`, {
                      valueAsNumber: true,
                    })}
                    name={`tibialLimbLengthLeft`}
                    key={`tibialLimbLengthLeft`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    type="number"
                    {...register(`tibialLimbLengthRight`, {
                      valueAsNumber: true,
                    })}
                    name={`tibialLimbLengthRight`}
                    key={`tibialLimbLengthRight`}
                    disabled={isDisabled}
                    className=" className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
          <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
            <OutlinedButton
              type="button"
              onClick={() => {
                props.postSubmitCallback();
              }}
              className="mr-2"
              variant={ButtonVariant.PRIMARY}
            >
              Close
            </OutlinedButton>
            {isDisabled ? (
              <Button
                type="button"
                key="EditButton"
                onClick={() => {
                  setIsDisabled(false);
                }}
                variant={ButtonVariant.PRIMARY}
              >
                Edit
              </Button>
            ) : props.assessmentId !== "000000000000000000000000" ? (
              <Button
                type="submit"
                key="UpdateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                key="CreateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
