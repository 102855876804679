// To parse this data:
//
//   import { Convert, Pain } from "./file";
//
//   const pain = Convert.toPain(json);

export interface Pain {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  onset: number;
  progression: string;
  painIntensity: number;
  painSite: string;
  painSide: string;
  headPainLeft: boolean;
  headPainRight: boolean;
  neckPainLeft: boolean;
  neckPainRight: boolean;
  axillaPainLeft: boolean;
  axillaPainRight: boolean;
  scapulaPainLeft: boolean;
  scapulaPainRight: boolean;
  shoulderPainLeft: boolean;
  shoulderPainRight: boolean;
  armPainLeft: boolean;
  armPainRight: boolean;
  elbowPainLeft: boolean;
  elbowPainRight: boolean;
  forearmPainLeft: boolean;
  forearmPainRight: boolean;
  wristPainLeft: boolean;
  wristPainRight: boolean;
  thumbPainLeft: boolean;
  thumbPainRight: boolean;
  palmPainLeft: boolean;
  palmPainRight: boolean;
  handPainLeft: boolean;
  handPainRight: boolean;
  chestPainLeft: boolean;
  chestPainRight: boolean;
  backPainLeft: boolean;
  backPainRight: boolean;
  sacrolliacPainLeft: boolean;
  sacrolliacPainRight: boolean;
  hipPainLeft: boolean;
  hipPainRight: boolean;
  thighPainLeft: boolean;
  thighPainRight: boolean;
  iTBandPainLeft: boolean;
  iTBandPainRight: boolean;
  kneePainLeft: boolean;
  kneePainRight: boolean;
  shinPainLeft: boolean;
  shinPainRight: boolean;
  anklePainLeft: boolean;
  anklePainRight: boolean;
  heelPainLeft: boolean;
  heelPainRight: boolean;
  greatToePainLeft: boolean;
  greatToePainRight: boolean;
  stiffnessMorning: boolean;
  stiffnessEvening: boolean;
  stiffnessNight: boolean;
  stiffnessWholeDay: boolean;
  sleepDisturbed: boolean;
  sleepNormal: boolean;
  travellingTwoWheeler: boolean;
  travellingFourWheeler: boolean;
  travellingPublicTransport: boolean;
  dailyTravelKm: number;
  footPainLeft: boolean;
  footPainRight: boolean;
  constantIntermittentLeft: boolean;
  constantIntermittentRight: boolean;
  sharpLeft: boolean;
  sharpRight: boolean;
  shootingLeft: boolean;
  shootingRight: boolean;
  burningLeft: boolean;
  burningRight: boolean;
  throbbingLeft: boolean;
  throbbingRight: boolean;
  numbnessLeft: boolean;
  numbnessRight: boolean;
  pinsAndNeedlesLeft: boolean;
  pinsAndNeedlesRight: boolean;
  aggravatingFactors: string;
  relievingFactors: string;
  activityLimitation: string;
  audit: Audit;
}

export interface Audit {
  createdBy: string;
  lastUpdatedBy: string;
  createdAt: Date;
  lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class ConvertPain {
  public static toPain(json: string): Pain {
    return JSON.parse(json);
  }

  public static painToJson(value: Pain): string {
    return JSON.stringify(value);
  }
}
