import { ValidationError } from "@components/hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { endpoints, replaceParams } from "@utils/axios";
import {
  Convert,
  MedicalHistoryDetails,
} from "@pages/manage-patient-new/types/MedicalHistoryDetails";
import useAxios from "@routes/hooks/use-axios";
import { ManagePatientNewContext } from "./ManagePatientNew";
import Applabel from "@components/hook-form/applabel";
import { NewMasterAssessmentData } from "@pages/manage-patient-new/types/NewMasterAssessment";

export const MedicalHistorySchema = z.object({
  chiefComplaintDetails: z.string().optional(),
  historyOfPresentIllness: z.string().optional(),
  historyOfPastIllness: z.string().optional(),
  healthConditionDetails: z.string().optional(),

  surgeryDetails: z.string().optional(),

  pastTreatmentDetails: z.string().optional(),
  familyHistoryDetails: z.string().optional(),
});

export type MedicalHistory = z.infer<typeof MedicalHistorySchema>;

export default function MedicalHistory(props: {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
  getMasterAssessmentLedger: () => Promise<void>;
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<MedicalHistory>({ resolver: zodResolver(MedicalHistorySchema) });

  const [history, setHistory] = React.useState<MedicalHistoryDetails | null>(
    null
  );

  const [apiError, setApiError] = React.useState("");
  const patientId = useContext(ManagePatientNewContext);

  useEffect(() => {
    const getMedicalData = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ["id", props.masterAssessmentData?.medicalHistoryId ?? ""],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.medicalHistory.view,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];
        const convertedMedicalHistory = Convert.toMedicalHistoryDetails(
          JSON.stringify(data)
        );
        setHistory(convertedMedicalHistory);

        reset({ ...convertedMedicalHistory });
      } catch (error) {
        reset({
          healthConditionDetails: "",
          pastTreatmentDetails: "",
          surgeryDetails: "",
          familyHistoryDetails: "",
        });
        console.error("Error fetching data:", error);
      }
    };
    getMedicalData();
  }, [props.masterAssessmentData]);

  const axios = useAxios();
  const onSubmit: SubmitHandler<MedicalHistory> = async (data) => {
    if (
      props.masterAssessmentData?.medicalHistoryId ===
      "000000000000000000000000"
    ) {
      try {
        MedicalHistorySchema.parse(data);
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentData?.id!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.medicalHistory.addMedicalHistory,
          paramsMap
        );

        const response = await axios.post(filteredEndpoint, data);

        props.getMasterAssessmentLedger();
      } catch (error) {
        console.error("Error submitting form:", error);
        setApiError(error.response.data["data"].toString());
      }
    } else {
      try {
        MedicalHistorySchema.parse(data);
        const paramsMap = new Map<string, string>([
          ["id", props.masterAssessmentData?.medicalHistoryId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.medicalHistory.updateMedicalHistory,
          paramsMap
        );

        const response = await axios.put(filteredEndpoint, data);
      } catch (error) {
        console.error("Error submitting form:", error);
        setApiError(error.response.data["data"].toString());
      }
    }
  };

  return (
    <div className="grid-cols-1 gap-x-8 gap-y-8 pt-2 md:grid-cols-3 bg-grey-200 ">
      <form>
        <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
          <div className="flex flex-col grow basis-1/4 justify-center">
            <span className="flex-none text-sm mb-0">Chief Complaint</span>
          </div>
          <div className="flex basis-3/4">
            <textarea
              {...register("chiefComplaintDetails")}
              onBlur={handleSubmit(onSubmit)}
              placeholder="Enter the details"
              value={watch("chiefComplaintDetails")}
              rows={4}
              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            />
            {errors.chiefComplaintDetails && (
              <ValidationError
                message={
                  errors.chiefComplaintDetails?.message?.toString() ?? ""
                }
              />
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
          <div className="flex flex-col grow basis-1/4 justify-center">
            <span className="flex-none text-sm mb-0">
              History of Present Illness
            </span>
          </div>
          <div className="flex basis-3/4">
            <textarea
              {...register("historyOfPresentIllness")}
              onBlur={handleSubmit(onSubmit)}
              placeholder="Enter the details"
              value={watch("historyOfPresentIllness")}
              rows={4}
              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            />
            {errors.historyOfPresentIllness && (
              <ValidationError
                message={
                  errors.historyOfPresentIllness?.message?.toString() ?? ""
                }
              />
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
          <div className="flex flex-col grow basis-1/4 justify-center">
            <span className="flex-none text-sm mb-0">
              History of Past Illness
            </span>
          </div>
          <div className="flex basis-3/4">
            <textarea
              {...register("historyOfPastIllness")}
              onBlur={handleSubmit(onSubmit)}
              placeholder="Enter the details"
              value={watch("historyOfPastIllness")}
              rows={4}
              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            />
            {errors.historyOfPastIllness && (
              <ValidationError
                message={errors.historyOfPastIllness?.message?.toString() ?? ""}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
          <div className="flex flex-col grow basis-1/4 justify-center">
            <span className="flex-none text-sm mb-0">
              Pre-existing Health Conditions
            </span>
          </div>
          <div className="flex basis-3/4">
            <textarea
              {...register("healthConditionDetails")}
              onBlur={handleSubmit(onSubmit)}
              placeholder="Enter the details"
              value={watch("healthConditionDetails")}
              rows={4}
              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            />
            {errors.healthConditionDetails && (
              <ValidationError
                message={
                  errors.healthConditionDetails?.message?.toString() ?? ""
                }
              />
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
          <div className="flex flex-col basis-1/4  justify-center">
            <span className="flex-none text-sm mb-0">Past Treatments</span>
          </div>
          <div className="flex basis-3/4">
            <textarea
              {...register("pastTreatmentDetails")}
              onBlur={handleSubmit(onSubmit)}
              placeholder="Enter the details"
              value={watch("pastTreatmentDetails")}
              rows={4}
              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            />
            {errors.pastTreatmentDetails && (
              <ValidationError
                message={errors.pastTreatmentDetails?.message?.toString() ?? ""}
              />
            )}
          </div>
        </div>

        <div className="flex flex-row md:flex-row w-full mt-4 md:space-x-4">
          <div className="flex flex-col grow basis-1/4 justify-center">
            <span className="flex-none text-sm mb-0">Surgeries</span>
          </div>
          <div className="flex basis-3/4">
            <textarea
              {...register("surgeryDetails")}
              onBlur={handleSubmit(onSubmit)}
              placeholder="Enter the details"
              value={watch("surgeryDetails")}
              rows={4}
              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            />
            {errors.surgeryDetails && (
              <ValidationError
                message={errors.surgeryDetails?.message?.toString() ?? ""}
              />
            )}
          </div>
        </div>
        <div className="flex flex-row md:flex-row w-full mt-4 md:space-x-4">
          <div className="flex flex-col grow basis-1/4  justify-center">
            <span className="flex-none text-sm mb-0">
              Family Medical History
            </span>
          </div>
          <div className="flex basis-3/4">
            <textarea
              {...register("familyHistoryDetails")}
              onBlur={handleSubmit(onSubmit)}
              placeholder="Enter the details"
              value={watch("familyHistoryDetails")}
              rows={4}
              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            />
            {errors.familyHistoryDetails && (
              <ValidationError
                message={errors.familyHistoryDetails?.message?.toString() ?? ""}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
