import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { paths } from '@routes/paths';
import { ManagePatientContext } from '@pages/manage-patient/view/ManagePatient';
import moment from 'moment';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';

import { Grid } from 'gridjs-react';
import Button from '@components/button/button';
import { ButtonVariant } from '@components/button/button';

import InputField from '@components/hook-form/input-field';
import { Convert, Posture } from './types/Posture';
import { generateForm } from './InitialPostureData';
import { toast } from 'react-toastify';
import { OutlinedButton } from '@components/button';

export const ForConditionSchema = z.object({
  name: z.string(),
  category: z.string(),
  exists: z.string(),
});
export type ForCondition = z.infer<typeof ForConditionSchema>;

export const ForSideSchema = z.object({
  name: z.string(),
  category: z.string(),
  right: z.boolean(),
  left: z.boolean(),
});
export type ForSide = z.infer<typeof ForSideSchema>;

export const FrontViewSchema = z.object({
  forSide: z.array(ForSideSchema),
});
export type FrontView = z.infer<typeof FrontViewSchema>;

export const ThoracicSpineSchema = z.object({
  backView: FrontViewSchema,
});
export type ThoracicSpine = z.infer<typeof ThoracicSpineSchema>;

export const UpperBodySchema = z.object({
  forConditions: z.array(ForConditionSchema),
});
export type UpperBody = z.infer<typeof UpperBodySchema>;

export const LowerBodySchema = z.object({
  forSide: z.array(ForSideSchema).optional(),
  forConditions: z.array(ForConditionSchema),
});
export type LowerBody = z.infer<typeof LowerBodySchema>;

export const LateralViewSchema = z.object({
  upperBody: UpperBodySchema,
  lowerBody: LowerBodySchema,
});
export type LateralView = z.infer<typeof LateralViewSchema>;

export const HipComplexSchema = z.object({
  frontViewSteppingLeg: FrontViewSchema,
  frontViewTrunk: FrontViewSchema,
  frontViewStanceLeg: FrontViewSchema,
});
export type HipComplex = z.infer<typeof HipComplexSchema>;

export const AnteriorPosteriorViewSchema = z.object({
  upperBody: FrontViewSchema,
  lowerBody: FrontViewSchema,
});
export type AnteriorPosteriorView = z.infer<typeof AnteriorPosteriorViewSchema>;

export const AnkleComplexSchema = z.object({
  frontView: FrontViewSchema,
  sideView: FrontViewSchema,
});
export type AnkleComplex = z.infer<typeof AnkleComplexSchema>;

export const PostureSchema = z.object({
  masterAssessmentId: z.string(),
  lateralView: LateralViewSchema,
  anteriorPosteriorView: AnteriorPosteriorViewSchema,
  ankleComplex: AnkleComplexSchema,
  hipComplex: HipComplexSchema,
  thoracicSpine: ThoracicSpineSchema,
});
export type PostureSchemaType = z.infer<typeof PostureSchema>;

type PostureAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function PostureAssessmentComponent(
  props: PostureAssessmentProps,
) {
  const [postureAssessment, setPostureAssessment] =
    React.useState<Posture | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [error, setError] = React.useState('');

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  const initialize = () => {
    // set default values for the form
    return generateForm(props.masterAssessmentId);
  };

  React.useEffect(() => {
    const initializeVitalAssessment = async () => {
      if (props.assessmentId !== '000000000000000000000000') {
        try {
          const paramsMap = new Map<string, string>([
            ['mid', props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getPostureAssessment,
            paramsMap,
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data['data'];
          const modifiedData = {
            ...data,
            lateralView: {
              upperBody: {
                forConditions: data.lateralView.upperBody.forConditions.map(
                  (condition: any) => ({
                    ...condition,
                    exists: condition.exists.toString(),
                  }),
                ),
              },
              lowerBody: {
                forConditions: data.lateralView.lowerBody.forConditions.map(
                  (condition: any) => ({
                    ...condition,
                    exists: condition.exists.toString(),
                  }),
                ),
              },
            },
          };
          const convertedPostureAssessment = Convert.toPosture(
            JSON.stringify(modifiedData),
          );
          setPostureAssessment(convertedPostureAssessment);

          methods.reset({ ...convertedPostureAssessment });

          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching Posture assessment:', error);
          toast.error(error.message);

          setError(error.message);
          setLoading(false);
        }
      }
    };
    initializeVitalAssessment();
  }, []);

  const methods = useForm<PostureSchemaType>({
    resolver: zodResolver(PostureSchema),
    defaultValues: PostureSchema.parse(initialize()),
  });

  const control = methods.control;
  const { fields: antPostUpperBodyForSide } = useFieldArray({
    control,
    name: 'anteriorPosteriorView.upperBody.forSide',
    rules: {
      required: 'This is required',
    },
  });
  const { fields: antPostLowerBodyForSide } = useFieldArray({
    control,
    name: 'anteriorPosteriorView.lowerBody.forSide',
    rules: {
      required: 'This is required',
    },
  });

  const { fields: lateralUpperBodyForCondition } = useFieldArray({
    control,
    name: 'lateralView.upperBody.forConditions',
  });
  const { fields: lateralLowerBodyForCondition } = useFieldArray({
    control,
    name: 'lateralView.lowerBody.forConditions',
  });

  const { fields: ankleComplexFrontViewForSide } = useFieldArray({
    control,
    name: 'ankleComplex.frontView.forSide',
  });
  const { fields: ankleComplexSideViewForSide } = useFieldArray({
    control,
    name: 'ankleComplex.sideView.forSide',
  });

  const { fields: hipComplexFrontViewStanceLegForSide } = useFieldArray({
    control,
    name: 'hipComplex.frontViewStanceLeg.forSide',
  });
  const { fields: hipComplexFrontViewSteppingLegForSide } = useFieldArray({
    control,
    name: 'hipComplex.frontViewSteppingLeg.forSide',
  });

  const { fields: hipComplexFrontViewTrunkForSide } = useFieldArray({
    control,
    name: 'hipComplex.frontViewTrunk.forSide',
  });

  const { fields: thoracicSpineBackViewForSide } = useFieldArray({
    control,
    name: 'thoracicSpine.backView.forSide',
  });

  const navigateToMasterAssessment = (masterAssessmentId: string) => {
    const paramsMap = new Map<string, string>([
      ['patientId', patientId.patientId],
      ['masterAssessmentId', masterAssessmentId],
    ]);
    navigate(
      `${replaceParams(paths.patient.masterAssessment.route, paramsMap)}`,
    );
  };

  const onSubmit: SubmitHandler<PostureSchemaType> = async data => {
    if (props.assessmentId !== '000000000000000000000000') {
      try {
        PostureSchema.parse(data);
        const modifiedData = {
          ...data,
          lateralView: {
            upperBody: {
              forConditions: data.lateralView.upperBody.forConditions.map(
                condition => ({
                  ...condition,
                  exists: condition.exists === 'true',
                }),
              ),
            },
            lowerBody: {
              forConditions: data.lateralView.lowerBody.forConditions.map(
                condition => ({
                  ...condition,
                  exists: condition.exists === 'true',
                }),
              ),
            },
          },
        };

        const paramsMap = new Map<string, string>([
          ['id', props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updatePostureAssessment,
          paramsMap,
        );
        const response = await axios.put(filteredEndpoint, modifiedData);
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Posture Assessment updated successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        console.error('Error submitting form:', error);
        setError(error.response.data['data'].toString());
      }
    } else {
      try {
        PostureSchema.parse(data);
        const modifiedData = {
          ...data,
          lateralView: {
            upperBody: {
              forConditions: data.lateralView.upperBody.forConditions.map(
                condition => ({
                  ...condition,
                  exists: condition.exists === 'true',
                }),
              ),
            },
            lowerBody: {
              forConditions: data.lateralView.lowerBody.forConditions.map(
                condition => ({
                  ...condition,
                  exists: condition.exists === 'true',
                }),
              ),
            },
          },
        };
        const paramsMap = new Map<string, string>([
          ['mid', props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createPostureAssessment,
          paramsMap,
        );
        const response = await axios.post(filteredEndpoint, modifiedData);
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Posture Assessment created successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        console.error('Error submitting form:', error);
        setError(error.response.data['data'].toString());
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Posture Assessment
        </h3>
      </div>

      <hr className="w-full" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-primary-300 text-white ">
            PTA Static Postural Assessment
          </div>
          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
            Anterior & Posterior View
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
                  Upper Body
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{' '}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {antPostUpperBodyForSide.map((value, index) => {
                      return (
                        <>
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              {value.category + '-' + value.name}
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="checkbox"
                                key={`anteriorPosteriorView.upperBody.forSide[${index}].left`}
                                name={`anteriorPosteriorView.upperBody.forSide[${index}].left`}
                                disabled={isDisabled}
                              />
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="checkbox"
                                key={`anteriorPosteriorView.upperBody.forSide[${index}].right`}
                                name={`anteriorPosteriorView.upperBody.forSide[${index}].right`}
                                disabled={isDisabled}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
                  Lower Body
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{' '}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {antPostLowerBodyForSide.map((value, index) => {
                      return (
                        <>
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 break-all">
                              {value.category + '-' + value.name}
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="checkbox"
                                key={`anteriorPosteriorView.lowerBody.forSide[${index}].left`}
                                name={`anteriorPosteriorView.lowerBody.forSide[${index}].left`}
                                disabled={isDisabled}
                              />
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="checkbox"
                                key={`anteriorPosteriorView.lowerBody.forSide[${index}].right`}
                                name={`anteriorPosteriorView.lowerBody.forSide[${index}].right`}
                                disabled={isDisabled}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
            Lateral View
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
                  Upper Body
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Yes
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        No
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lateralUpperBodyForCondition.map((value, index) => {
                      return (
                        <>
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              {value.category + '-' + value.name}
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="radio"
                                key={`lateralView.upperBody.forConditions[${index}].exists`}
                                name={`lateralView.upperBody.forConditions[${index}].exists`}
                                disabled={isDisabled}
                                value="true"
                              />
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="radio"
                                key={`lateralView.upperBody.forConditions[${index}].exists`}
                                name={`lateralView.upperBody.forConditions[${index}].exists`}
                                disabled={isDisabled}
                                value="false"
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
                  Lower Body
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Yes
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        No
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lateralLowerBodyForCondition.map((value, index) => {
                      return (
                        <>
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 break-all">
                              {value.category + '-' + value.name}
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="radio"
                                key={`lateralView.lowerBody.forConditions[${index}].exists`}
                                name={`lateralView.lowerBody.forConditions[${index}].exists`}
                                disabled={isDisabled}
                                value="true"
                              />
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="radio"
                                key={`lateralView.lowerBody.forConditions[${index}].exists`}
                                name={`lateralView.lowerBody.forConditions[${index}].exists`}
                                disabled={isDisabled}
                                value="false"
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-primary-300 text-white ">
            PTA Dynamic Postural Assessment
          </div>

          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
            Ankle Complex
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
                  Front View
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{' '}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ankleComplexFrontViewForSide.map((value, index) => {
                      return (
                        <>
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              {value.category + '-' + value.name}
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="checkbox"
                                key={`ankleComplex.frontView.forSide[${index}].left`}
                                name={`ankleComplex.frontView.forSide[${index}].left`}
                                disabled={isDisabled}
                              />
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="checkbox"
                                key={`ankleComplex.frontView.forSide[${index}].right`}
                                name={`ankleComplex.frontView.forSide[${index}].right`}
                                disabled={isDisabled}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
                  Side View
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{' '}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ankleComplexSideViewForSide.map((value, index) => {
                      return (
                        <>
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 break-all">
                              {value.category + '-' + value.name}
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="checkbox"
                                key={`ankleComplex.sideView.forSide[${index}].left`}
                                name={`ankleComplex.sideView.forSide[${index}].left`}
                                disabled={isDisabled}
                              />
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <InputField
                                type="checkbox"
                                key={`ankleComplex.sideView.forSide[${index}].right`}
                                name={`ankleComplex.sideView.forSide[${index}].right`}
                                disabled={isDisabled}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
            Hip Complex - Lateral Step/Lunge
          </div>
          <div className="flex flex-col">
            <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
              Front View - Stance Leg
            </div>
            <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Name
                  </th>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Left{' '}
                  </th>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Right{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                {hipComplexFrontViewStanceLegForSide.map((value, index) => {
                  return (
                    <>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          {value.category + '-' + value.name}
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <InputField
                            type="checkbox"
                            key={`hipComplex.frontViewStanceLeg.forSide[${index}].left`}
                            name={`hipComplex.frontViewStanceLeg.forSide[${index}].left`}
                            disabled={isDisabled}
                          />
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <InputField
                            type="checkbox"
                            key={`hipComplex.frontViewStanceLeg.forSide[${index}].right`}
                            name={`hipComplex.frontViewStanceLeg.forSide[${index}].right`}
                            disabled={isDisabled}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex flex-col">
            <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
              Front View - Stepping Leg
            </div>
            <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Name
                  </th>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Left{' '}
                  </th>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Right{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                {hipComplexFrontViewSteppingLegForSide.map((value, index) => {
                  return (
                    <>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          {value.category + '-' + value.name}
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <InputField
                            type="checkbox"
                            key={`hipComplex.frontViewSteppingLeg.forSide[${index}].left`}
                            name={`hipComplex.frontViewSteppingLeg.forSide[${index}].left`}
                            disabled={isDisabled}
                          />
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <InputField
                            type="checkbox"
                            key={`hipComplex.frontViewSteppingLeg.forSide[${index}].right`}
                            name={`hipComplex.frontViewSteppingLeg.forSide[${index}].right`}
                            disabled={isDisabled}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex flex-col">
            <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
              Front View - Trunk
            </div>
            <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Name
                  </th>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Left{' '}
                  </th>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Right{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                {hipComplexFrontViewTrunkForSide.map((value, index) => {
                  return (
                    <>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          {value.category + '-' + value.name}
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <InputField
                            type="checkbox"
                            key={`hipComplex.frontViewTrunk.forSide[${index}].left`}
                            name={`hipComplex.frontViewTrunk.forSide[${index}].left`}
                            disabled={isDisabled}
                          />
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <InputField
                            type="checkbox"
                            key={`hipComplex.frontViewTrunk.forSide[${index}].right`}
                            name={`hipComplex.frontViewTrunk.forSide[${index}].right`}
                            disabled={isDisabled}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
            Thoracic Spine
          </div>
          <div className="flex flex-col">
            <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
              Back view
            </div>
            <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Name
                  </th>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Left{' '}
                  </th>
                  <th className="border px-2 py-1 border-slate-300 text-center">
                    Right{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                {thoracicSpineBackViewForSide.map((value, index) => {
                  return (
                    <>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          {value.category + '-' + value.name}
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <InputField
                            type="checkbox"
                            key={`thoracicSpine.backView.forSide[${index}].left`}
                            name={`thoracicSpine.backView.forSide[${index}].left`}
                            disabled={isDisabled}
                          />
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <InputField
                            type="checkbox"
                            key={`thoracicSpine.backView.forSide[${index}].right`}
                            name={`thoracicSpine.backView.forSide[${index}].right`}
                            disabled={isDisabled}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          {methods.formState.errors && <p className="text-red-500"> {}</p>}
          {/* Add a empty div with height to avoid overlapping */}
          <div className="h-20"></div>
          <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
            <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
              <OutlinedButton
                type="button"
                onClick={() => {
                  props.postSubmitCallback();
                }}
                className="mr-2"
                variant={ButtonVariant.PRIMARY}
              >
                Close
              </OutlinedButton>
              {isDisabled ? (
                <Button
                  type="button"
                  key="EditButton"
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                  variant={ButtonVariant.PRIMARY}
                >
                  Edit
                </Button>
              ) : props.assessmentId !== '000000000000000000000000' ? (
                <Button
                  type="submit"
                  key="UpdateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  key="CreateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
