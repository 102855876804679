import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

import { useParams, useSearchParams } from "react-router-dom";

import NewHomeExerciseProgram from "@pages/manage-patient/view/HomeExerciseProgramComponents/HomeExerciseProgram";
import { NewMasterAssessmentData } from "@pages/manage-patient-new/types/NewMasterAssessment";
import { TreatmentPlanTab } from "./TreatmentPlanTab";
import GoalsTab from "./GoalsTab";
interface Tab {
  name: string;
  current: boolean;
}

function TreatmentPlan(props: {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
  getMasterAssessmentLedger: () => Promise<void>;
}) {
  const [activeTab, setActiveTab] = useState("Goals");
  const tabComponents: Record<string, React.ReactNode> = {
    Prescription: (
      <>
        <TreatmentPlanTab
          getMasterAssessmentLedger={props.getMasterAssessmentLedger}
          masterAssessmentData={props.masterAssessmentData}
          patientId={props.patientId}
        />
      </>
    ),
    "Home Exercise Program": (
      <NewHomeExerciseProgram
        masterAssessmentData={props.masterAssessmentData}
      />
    ),
    Goals: (
      // Your new Goals tab component
      <GoalsTab
        masterAssessmentData={props.masterAssessmentData}
        patientId={props.patientId}
      />
    ),
  };

  const tabs: Tab[] = [
    { name: "Goals", current: activeTab === "Goals" },
    { name: "Prescription", current: activeTab === "Prescription" },
    {
      name: "Home Exercise Program",
      current: activeTab === "Home Exercise Program",
    },
  ];

  function handleTabClick(tabName: string) {
    setActiveTab(tabName);
  }
  return (
    <>
      <div className="flex flex-col">
        <div className="flex-shrink">
          <div className="border-b border-gray-200 pb-5 sm:pb-0">
            <div className="mt-3 sm:mt-4">
              <div className="block md:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  value={activeTab}
                  onChange={(e) => handleTabClick(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.name}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-3 sm:mt-4 hidden md:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <button
                      disabled={
                        tab.name === "Home Exercise Program" &&
                        props.masterAssessmentData?.treatmentPlanId ===
                          "000000000000000000000000"
                      }
                      key={tab.name}
                      className={`${
                        tab.current
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      } whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium focus:outline-none`}
                      onClick={() => {
                        handleTabClick(tab.name);
                      }}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
        {tabComponents[activeTab]}
      </div>
    </>
  );
}

export default TreatmentPlan;
