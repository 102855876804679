import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import moment from "moment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

import { Grid } from "gridjs-react";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";

import InputField from "@components/hook-form/input-field";
import { Convert, RangeOfMotionAssessment } from "./types/RangeOfMotion";
import { generateForm } from "./InitialTableData";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";

export const RangeOfMotionAssessmentSchema = z.object({
  id: z.string().optional().default(""),
  masterAssessmentId: z.string().optional().default(""),
  clientId: z.string().optional().default(""),
  entityId: z.string().optional().default(""),
  entityBranchId: z.string().optional().default(""),
  hipFlexionLeft: z.number().optional().default(0),
  hipFlexionRight: z.number().optional().default(0),
  hipExtensionLeft: z.number().optional().default(0),
  hipExtensionRight: z.number().optional().default(0),
  hipAbductionLeft: z.number().optional().default(0),
  hipAbductionRight: z.number().optional().default(0),
  hipAdductionLeft: z.number().optional().default(0),
  hipAdductionRight: z.number().optional().default(0),
  hipMedialRotationLeft: z.number().optional().default(0),
  hipMedialRotationRight: z.number().optional().default(0),
  hipLateralRotationLeft: z.number().optional().default(0),
  hipLateralRotationRight: z.number().optional().default(0),
  shoulderFlexionLeft: z.number().optional().default(0),
  shoulderFlexionRight: z.number().optional().default(0),
  shoulderExtensionLeft: z.number().optional().default(0),
  shoulderExtensionRight: z.number().optional().default(0),
  shoulderAbductionLeft: z.number().optional().default(0),
  shoulderAbductionRight: z.number().optional().default(0),
  shoulderAdductionLeft: z.number().optional().default(0),
  shoulderAdductionRight: z.number().optional().default(0),
  shoulderMedialRotationLeft: z.number().optional().default(0),
  shoulderMedialRotationRight: z.number().optional().default(0),
  shoulderLateralRotationLeft: z.number().optional().default(0),
  shoulderLateralRotationRight: z.number().optional().default(0),
  kneeFlexionLeft: z.number().optional().default(0),
  kneeFlexionRight: z.number().optional().default(0),
  kneeExtensionLeft: z.number().optional().default(0),
  kneeExtensionRight: z.number().optional().default(0),
  elbowFlexionLeft: z.number().optional().default(0),
  elbowFlexionRight: z.number().optional().default(0),
  elbowExtensionLeft: z.number().optional().default(0),
  elbowExtensionRight: z.number().optional().default(0),
  ankleFootDorsiFlexionLeft: z.number().optional().default(0),
  ankleFootDorsiFlexionRight: z.number().optional().default(0),
  ankleFootPlantarFlexionLeft: z.number().optional().default(0),
  ankleFootPlantarFlexionRight: z.number().optional().default(0),
  ankleFootInversionLeft: z.number().optional().default(0),
  ankleFootInversionRight: z.number().optional().default(0),
  ankleFootEversionLeft: z.number().optional().default(0),
  ankleFootEversionRight: z.number().optional().default(0),
  forearmPronationLeft: z.number().optional().default(0),
  forearmPronationRight: z.number().optional().default(0),
  forearmSupinationLeft: z.number().optional().default(0),
  forearmSupinationRight: z.number().optional().default(0),
  neckFlexionLeft: z.number().optional().default(0),
  neckFlexionRight: z.number().optional().default(0),
  neckExtensionLeft: z.number().optional().default(0),
  neckExtensionRight: z.number().optional().default(0),
  neckLateroFlexionRLeft: z.number().optional().default(0),
  neckLateroFlexionRRight: z.number().optional().default(0),
  neckLateroFlexionLLeft: z.number().optional().default(0),
  neckLateroFlexionLRight: z.number().optional().default(0),
  neckRotationRLeft: z.number().optional().default(0),
  neckRotationRRight: z.number().optional().default(0),
  neckRotationLLeft: z.number().optional().default(0),
  neckRotationLRight: z.number().optional().default(0),
  wristFlexionLeft: z.number().optional().default(0),
  wristFlexionRight: z.number().optional().default(0),
  wristExtensionLeft: z.number().optional().default(0),
  wristExtensionRight: z.number().optional().default(0),
  wristAbductionLeft: z.number().optional().default(0),
  wristAbductionRight: z.number().optional().default(0),
  wristAdductionLeft: z.number().optional().default(0),
  wristAdductionRight: z.number().optional().default(0),
  trunkGlobalFlexionLeft: z.number().optional().default(0),
  trunkGlobalFlexionRight: z.number().optional().default(0),
  trunkThoracicFlexionLeft: z.number().optional().default(0),
  trunkThoracicFlexionRight: z.number().optional().default(0),
  trunkLumbarFlexionLeft: z.number().optional().default(0),
  trunkLumbarFlexionRight: z.number().optional().default(0),
  trunkGlobalExtensionLeft: z.number().optional().default(0),
  trunkGlobalExtensionRight: z.number().optional().default(0),
  trunkLateroFlexionRLeft: z.number().optional().default(0),
  trunkLateroFlexionRRight: z.number().optional().default(0),
  trunkLateroFlexionLLeft: z.number().optional().default(0),
  trunkLateroFlexionLRight: z.number().optional().default(0),
  trunkRotationRLeft: z.number().optional().default(0),
  trunkRotationRRight: z.number().optional().default(0),
  trunkRotationLLeft: z.number().optional().default(0),
  trunkRotationLRight: z.number().optional().default(0),
  fingersThumboppositionLeft: z.number().optional().default(0),
  fingersThumboppositionRight: z.number().optional().default(0),
  fingersMPFlexionRight: z.number().optional().default(0),
  fingersMPFlexionLeft: z.number().optional().default(0),
  fingersMPExtensionLeft: z.number().optional().default(0),
  fingersMPExtensionRight: z.number().optional().default(0),
  fingersIPFlexionLeft: z.number().optional().default(0),
  fingersIPFlexionRight: z.number().optional().default(0),
});
export type RangeOfMotionAssessmentSchemaType = z.infer<
  typeof RangeOfMotionAssessmentSchema
>;

type RangeOfMotionAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function RangeOfMotionAssessmentComponent(
  props: RangeOfMotionAssessmentProps
) {
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [error, setError] = React.useState("");

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);
  const userData = getUserPersistedOnLocalStorage();

  const [lastUpdateAt, setLastUpdatedAt] = React.useState("");

  const initialize = () => {
    // set default values for the form
    return generateForm(props.masterAssessmentId);
  };

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    const initializeVitalAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getRomAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const convertedRomAssessment = Convert.toRangeOfMotionAssessment(
            JSON.stringify(data)
          );

          methods.reset({ ...convertedRomAssessment });
          setLastUpdatedAt(data["audit"]["lastUpdatedAt"]);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching ROM assessment:", error);
          setError(error.message);
          setLoading(false);
        }
      }
    };
    initializeVitalAssessment();
  }, []);

  const methods = useForm<RangeOfMotionAssessmentSchemaType>({
    resolver: zodResolver(RangeOfMotionAssessmentSchema),
    defaultValues: RangeOfMotionAssessmentSchema.parse(initialize()),
  });

  const control = methods.control;

  const onSubmit: SubmitHandler<RangeOfMotionAssessmentSchemaType> = async (
    data
  ) => {
    methods.trigger();
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        RangeOfMotionAssessmentSchema.parse(data);

        data.id = props.assessmentId;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateRomAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Range of Motion Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
        setError(error.response.data["data"].toString());
      }
    } else {
      try {
        RangeOfMotionAssessmentSchema.parse(data);
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createRomAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Range of Motion Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
        setError(error.response.data["data"].toString());
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col md:flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Range of Motion Assessment
        </h3>
        <p className="text-sm text-gray-500">
          {props.assessmentId !== "000000000000000000000000" &&
            `Last updated: ${
              lastUpdateAt &&
              moment(new Date(lastUpdateAt)).format("DD-MM-YYYY") !==
                "01-01-0001"
                ? moment(new Date(lastUpdateAt)).format("DD-MM-YYYY")
                : "N/A"
            }`}
        </p>
      </div>

      <hr className="w-full" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Hip
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipFlexionLeft`}
                          name={`hipFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipFlexionRight`}
                          name={`hipFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Extension
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipExtensionLeft`}
                          name={`hipExtensionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipExtensionRight`}
                          name={`hipExtensionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Abduction
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipAbductionLeft`}
                          name={`hipAbductionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipAbductionRight`}
                          name={`hipAbductionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Adduction
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipAdductionLeft`}
                          name={`hipAdductionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipAdductionRight`}
                          name={`hipAdductionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Medial Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipMedialRotationLeft`}
                          name={`hipMedialRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipMedialRotationRight`}
                          name={`hipMedialRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Lateral Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipLateralRotationLeft`}
                          name={`hipLateralRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipLateralRotationRight`}
                          name={`hipLateralRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Shoulder
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 break-all">
                        Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderFlexionLeft`}
                          name={`shoulderFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderFlexionRight`}
                          name={`shoulderFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 break-all">
                        Extension
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderExtensionLeft`}
                          name={`shoulderExtensionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderExtensionRight`}
                          name={`shoulderExtensionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 break-all">
                        Abduction
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAbductionLeft`}
                          name={`shoulderAbductionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAbductionRight`}
                          name={`shoulderAbductionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 break-all">
                        Adduction
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAdductionLeft`}
                          name={`shoulderAdductionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAdductionRight`}
                          name={`shoulderAdductionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 break-all">
                        Medial Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderMedialRotationLeft`}
                          name={`shoulderMedialRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderMedialRotationRight`}
                          name={`shoulderMedialRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 break-all">
                        Lateral Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderLateralRotationLeft`}
                          name={`shoulderLateralRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderLateralRotationRight`}
                          name={`shoulderLateralRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Knee
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`kneeFlexionLeft`}
                          name={`kneeFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`kneeFlexionRight`}
                          name={`kneeFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Extension
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`kneeExtensionLeft`}
                          name={`kneeExtensionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`kneeExtensionLeft`}
                          name={`kneeExtensionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Elbow
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`elbowFlexionLeft`}
                          name={`elbowFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`elbowFlexionRight`}
                          name={`elbowFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Extension
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`elbowExtensionLeft`}
                          name={`elbowExtensionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`elbowExtensionRight`}
                          name={`elbowExtensionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Ankle
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300align-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Dorsi Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleFootDorsiFlexionLeft`}
                          name={`ankleFootDorsiFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleFootDorsiFlexionRight`}
                          name={`ankleFootDorsiFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Plantar Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleFootPlantarFlexionLeft`}
                          name={`ankleFootPlantarFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleFootPlantarFlexionRight`}
                          name={`ankleFootPlantarFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Inversion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleFootInversionLeft`}
                          name={`ankleFootInversionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleFootInversionRight`}
                          name={`ankleFootInversionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Eversion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleFootEversionLeft`}
                          name={`ankleFootEversionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleFootEversionRight`}
                          name={`ankleFootEversionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Fore-arm
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Pronation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`forearmPronationLeft`}
                          name={`forearmPronationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`forearmPronationRight`}
                          name={`forearmPronationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Supination
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`forearmSupinationLeft`}
                          name={`forearmSupinationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`forearmSupinationRight`}
                          name={`forearmSupinationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Neck
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckFlexionLeft`}
                          name={`neckFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckFlexionRight`}
                          name={`neckFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Extension
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckExtensionLeft`}
                          name={`neckExtensionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckExtensionRight`}
                          name={`neckExtensionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Latero-Flexion R
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckLateroFlexionRLeft`}
                          name={`neckLateroFlexionRLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckLateroFlexionRRight`}
                          name={`neckLateroFlexionRRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Latero-Flexion L
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckLateroFlexionLLeft`}
                          name={`neckLateroFlexionLLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckLateroFlexionLRight`}
                          name={`neckLateroFlexionLRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Rotation R
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckRotationRLeft`}
                          name={`neckRotationRLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckRotationRRight`}
                          name={`neckRotationRRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Rotation L
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckRotationLLeft`}
                          name={`neckRotationLLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`neckRotationLRight`}
                          name={`neckRotationLRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Wrist
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristFlexionLeft`}
                          name={`wristFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristFlexionRight`}
                          name={`wristFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Extension
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristExtensionLeft`}
                          name={`wristExtensionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristExtensionRight`}
                          name={`wristExtensionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Abduction
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristAbductionLeft`}
                          name={`wristAbductionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristAbductionRight`}
                          name={`wristAbductionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Adduction
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristAdductionLeft`}
                          name={`wristAdductionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristAdductionRight`}
                          name={`wristAdductionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Trunk
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Global Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkGlobalFlexionLeft`}
                          name={`trunkGlobalFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkGlobalFlexionRight`}
                          name={`trunkGlobalFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Thoracic Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkThoracicFlexionLeft`}
                          name={`trunkThoracicFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkThoracicFlexionRight`}
                          name={`trunkThoracicFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Lumbar Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLumbarFlexionLeft`}
                          name={`trunkLumbarFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLumbarFlexionRight`}
                          name={`trunkLumbarFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Global Extension
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkGlobalExtensionLeft`}
                          name={`trunkGlobalExtensionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkGlobalExtensionRight`}
                          name={`trunkGlobalExtensionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Latero-Flexion R
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLateroFlexionRLeft`}
                          name={`trunkLateroFlexionRLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLateroFlexionRRight`}
                          name={`trunkLateroFlexionRRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Latero-Flexion L
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLateroFlexionLLeft`}
                          name={`trunkLateroFlexionLLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLateroFlexionLRight`}
                          name={`trunkLateroFlexionLRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Rotation R
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkRotationRLeft`}
                          name={`trunkRotationRLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkRotationRRight`}
                          name={`trunkRotationRRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Rotation L
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkRotationLLeft`}
                          name={`trunkRotationLLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkRotationLRight`}
                          name={`trunkRotationLRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Fingers
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Thumb Opposition
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersThumboppositionLeft`}
                          name={`fingersThumboppositionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersThumboppositionRight`}
                          name={`fingersThumboppositionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        MP Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersMPFlexionLeft`}
                          name={`fingersMPFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersMPFlexionRight`}
                          name={`fingersMPFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        MP Extension
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersMPExtensionLeft`}
                          name={`fingersMPExtensionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersMPExtensionRight`}
                          name={`fingersMPExtensionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        IP Flexion
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersIPFlexionLeft`}
                          name={`fingersIPFlexionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersIPFlexionRight`}
                          name={`fingersIPFlexionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {methods.formState.errors && <p className="text-red-500"> {}</p>}
          {/* Add a empty div with height to avoid overlapping */}
          <div className="h-20"></div>
          <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
            <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
              <OutlinedButton
                type="button"
                onClick={() => {
                  props.postSubmitCallback();
                }}
                className="mr-2"
                variant={ButtonVariant.PRIMARY}
              >
                Close
              </OutlinedButton>
              {isDisabled ? (
                <Button
                  type="button"
                  key="EditButton"
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                  variant={ButtonVariant.PRIMARY}
                >
                  Edit
                </Button>
              ) : props.assessmentId !== "000000000000000000000000" ? (
                <Button
                  type="submit"
                  key="UpdateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  key="CreateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
