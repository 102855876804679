import { OutlinedButton } from "@components/button";
import axios from "axios";
import React from "react";
import {
  Convert,
  PatientPaymentDetailsDomain,
  PaymentDetail,
} from "../types/PatientPaymentDetailsDomain";
import { PersonalInformationDetails } from "../types/PatientPersonalDetails";

import PaymentDetails from "@pages/payments/PaymentDetails";
import {
  BillingDetails,
  Convert as ConvertBilling,
} from "@pages/manage-patient-new/types/BillingDetails";
import ViewBill from "./ViewBill";
import { endpoints, replaceParams } from "@utils/axios";
import {
  Appointment,
  Convert as ConvertAppointment,
  Appointment as PatientAppointment,
} from "@pages/appointments/types/Appointment";
import { ConvertSessionCredit, SessionCredits } from "../types/SessionCredits";
import moment from "moment";
import Button, { ButtonVariant } from "@components/button/outline-button";
import SolidButton from "@components/button/button";
import ViewPaymentDetails from "@pages/appointments/appointment-index-table/ViewPaymentDetails";
import AppointmentDetails, {
  CancelAppointmentDialog,
} from "@pages/appointments/appointment-index-table/AppointmentDetails";
import { Appointments } from "@pages/appointments/types/Appointments";
import RescheduleAppointmentPage from "@pages/appointments/reschedule-appointment/RescheduleAppointmentPage";
import BillingAndPaymentDetails from "@pages/payments/BillingAndPaymentDetails";
import BillingIndexPage from "@pages/finances/view/BillingIndex";
import RefundDetails from "@pages/payments/RefundDetails";
import RefundIndexPage from "@pages/finances/view/RefundIndex";
import { ConvertRefundDetails1, RefundDetails1 } from "../types/RefundDetails";
import BillDetailsPage from "@pages/finances/view/BillDetailsPage";
import RightOffcanvas from "@components/right-offcanvas";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import InvoicePage from "@pages/finances/view/InvoicePage";

export default function Billing(props: {
  patientId: string;
  onClose: () => void;
  patientPersonalInformation: PersonalInformationDetails | null;
}) {
  const [details, setDetails] =
    React.useState<PatientPaymentDetailsDomain | null>();
  const [credits, setCredits] = React.useState<SessionCredits | null>();

  const [patientAppointments, setPatientAppointments] = React.useState<
    PatientAppointment[] | null
  >(null);

  const [total, setTotal] = React.useState(0);
  const [totalAppointemnts, setTotalAppointments] = React.useState(0);
  const [showPaymentDetails, setShowPaymentDetails] = React.useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = React.useState("");
  const [appointmentDetails, setAppointmentDetails] =
    React.useState<Appointments | null>(null);
  const [addPaymentDetailsForAppt, setAddPaymentDetailsForAppt] =
    React.useState(false);

  const [addPaymentDetails, setAddPaymentDetails] = React.useState(false);
  const [addRefundDetails, setAddRefundDetails] = React.useState(false);
  const [patientChargesDialog, setPatientChargesDialog] = React.useState(false);

  const [viewBillDialog, setViewBill] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<PaymentDetail>();
  const [showAppointmentDetails, setShowAppointmentDetails] =
    React.useState(false);
  const [cancelDialog, setCancelDialog] = React.useState(false);
  const [appointmentId, setAppointmentId] = React.useState("");
  const [rescheduleAppointmentDialog, setRescheduleAppointmentDialog] =
    React.useState(false);
  const [appointment, setAppointment] = React.useState<Appointment | null>(
    null
  );
  const [billingDetails, setBillingDetails] =
    React.useState<BillingDetails | null>(null);

  const getPatientBillingDetails = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", props.patientId]]);
      const filteredEndpoint = replaceParams(
        endpoints.patient.getBillingDetails,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];

      const convertedDetails = ConvertBilling.toBillingDetails(
        JSON.stringify(data)
      );
      setBillingDetails(convertedDetails);
    } catch (error) {}
  };

  const getPatientPaymentDetails = async () => {
    const paramsMap = new Map<string, string>([["patientId", props.patientId]]);
    const filteredEndpoint = replaceParams(
      endpoints.patient.getPaymentDetails,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];

    const convertedDetails = Convert.toPatientPaymentDetails(
      JSON.stringify(data)
    );
    setDetails(convertedDetails);
    var toAddPayment: number = 0;
    if (convertedDetails.paymentDetails) {
      convertedDetails.paymentDetails.map((count) => {
        if (!isNaN(count.amount)) {
          toAddPayment = toAddPayment + count.amount;
        }
      });
      setTotal(toAddPayment);
    } else {
      setTotal(0);
    }
    setTotalAppointments(
      (convertedDetails.appointmentsCount.Consultation ?? 0) +
        (convertedDetails.appointmentsCount["Follow-up"] ?? 0) +
        (convertedDetails.appointmentsCount.Therapy ?? 0)
    );
  };
  const getPatientAppointments = async () => {
    const response = await axios.get(endpoints.appointment.list, {
      params: { clientid: props.patientId },
    });

    const data = response.data["data"];
    const convertedAppointmentList = data.map((appointment: any) => {
      let appointmentShadow = ConvertAppointment.toAppointment(
        JSON.stringify(appointment)
      );
      let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

      appointmentShadow.apptdate = new Date(
        moment(appointmentShadow.apptdate).toLocaleString()
      );

      appointmentShadow.startTime = moment(appointmentShadow.apptdate).format(
        "hh:mm A"
      );
      appointmentShadow.endTime = moment(appointment.startTime, "hh:mm A")
        .add(duration, "minutes")
        .format("hh:mm A");
      appointmentShadow.startTimeMinutes =
        appointmentShadow.apptdate.getHours() * 60 +
        appointmentShadow.apptdate.getMinutes();
      appointmentShadow.endTimeMinutes =
        appointmentShadow.apptdate.getHours() * 60 +
        appointmentShadow.apptdate.getMinutes() +
        duration;
      return appointmentShadow;
    });
    setPatientAppointments(convertedAppointmentList);
  };

  /*  const getPatientAppointments = async () => {
     const paramsMap = new Map<string, string>([['id', props.patientId]]);
     const filteredEndpoint = replaceParams(
       endpoints.patient.getPaymentAppointments,
       paramsMap,
     );
     const response = await axios.get(filteredEndpoint);
     const data = response.data['data'];
 
     const convertedList = data.map((staff: any) =>
       Conversion.toPatientAppointment(JSON.stringify(staff)),
     );
     setPatientAppointments(convertedList);
   };
  */
  const getPatientRemainingCredits = async () => {
    const paramsMap = new Map<string, string>([["id", props.patientId]]);
    const filteredEndpoint = replaceParams(
      endpoints.patient.getPatientRemainingCredits,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];
    const converted = ConvertSessionCredit.toSessionCredits(
      JSON.stringify(data)
    );
    setCredits(converted);
  };

  React.useEffect(() => {
    const _init = async () => {
      await getPatientPaymentDetails();
      //  await getPatientAppointments();
      await getPatientRemainingCredits();
      await getPatientBillingDetails();
      await getPatientRefunds();
    };
    _init();
  }, []);

  const handleViewBillClose = () => {
    setViewBill(false);
  };

  const handleChargesDialogClose = () => {
    setPatientChargesDialog(false);
  };

  const handleViewBillOpen = (detail: PaymentDetail) => {
    setSelectedRow(detail);
    setViewBill(true);
  };

  const callback = async () => {
    await getPatientPaymentDetails();
    await getPatientAppointments();
    await getPatientRemainingCredits();
  };

  const refundCallback = async () => {
    await getPatientRefunds();
  };

  const handleStatusChange = async (
    appointmentId: string,
    appointmentStatus: string
  ) => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", appointmentId],
        ["status", appointmentStatus],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.assistantDashboard.updateAppointmentStatus,
        paramsMap
      );
      await axios.put(filteredEndpoint);
      getPatientAppointments();
    } catch (error) {
      console.error(error);
    }
  };

  const showDialog = (appId: string) => {
    setAppointmentId(appId);
    setShowAppointmentDetails(true);
  };
  const [refundDetails1, setRefundDetails1] = React.useState<
    RefundDetails1[] | undefined
  >([]);

  const [rows1, setRows1] = React.useState<RefundDetails1[]>([]);

  const getPatientRefunds = async () => {
    const paramsMap = new Map<string, string>([["id", props.patientId ?? ""]]);
    const endpoint = replaceParams(endpoints.payment.refundDetails, paramsMap);

    const response = await axios.get(endpoint);
    const data = response.data["data"];
    if (data !== null) {
      const convertedList = data.map((ref: any) => {
        return ConvertRefundDetails1.toRefundDetails1(JSON.stringify(ref));
      });

      setRefundDetails1(convertedList);
      setRows1(convertedList);
    } else {
      setRefundDetails1([]);
      setRows1([]);
    }
  };
  const [newBill, setNewBill] = React.useState(false);
  function createNewBill(): void {
    setNewBill(true);
  }
  const userData = getUserPersistedOnLocalStorage();

  return (
    <div className="flex h-full w-full flex-col ">
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
            Billing & Payments
          </h2>
        </div>
        <div className="flex mt-4 flex-shrink-0 md:ml-4 md:mt-0 justify-end w-full">
          <Button
            type="button"
            onClick={() => createNewBill()}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            New Bill
          </Button>
          <Button
            type="button"
            onClick={() => setAddPaymentDetails(true)}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Advance Payment
          </Button>

          <Button
            type="button"
            onClick={() => setAddRefundDetails(true)}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Refund
          </Button>
          <Button
            type="button"
            onClick={props.onClose}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </Button>
        </div>
      </div>
      <hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />
      <div className="flex flex-col md:flex-row mt-2">
        <div className="flex">
          <div className="flex flex-col mr-1">
            <div className="flex flex-row md:justify-between pl-2 pr-1">
              <div className="flex font-bold py-2 mr-2">Payment Details </div>
            </div>
            <div className="">
              <dl className="mt-5 grid grid-cols-4 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:divide-x md:divide-y-0">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Total Bill Amount
                  </dt>
                  <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                    ₹{billingDetails?.clientTotalBillAmount}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Total Bill Amount Paid
                  </dt>
                  <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                    ₹{billingDetails?.clientTotalBillAmountPaid}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Outstanding/Due Bill Amount
                  </dt>
                  <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                    ₹{billingDetails?.clientOutStandingBillAmount}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Remaining Credits
                  </dt>
                  <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                    {credits?.SessionCredits ?? 0}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
              {props.patientPersonalInformation && (
                <BillingIndexPage
                  patientId={props.patientId}
                  patientInfo={props.patientPersonalInformation}
                  getPatientBillingDetails={getPatientBillingDetails}
                />
              )}
            </div>

            <div className="flex flex-row md:justify-between pl-2 pr-1 mt-8">
              <div className="flex font-bold py-2 mr-2">Refund Details </div>
            </div>
            <div className="mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
              {props.patientPersonalInformation && (
                <RefundIndexPage
                  patientId={props.patientId}
                  rows={rows1}
                  setRows={setRows1}
                  refundDetails={refundDetails1}
                  setRefundDetails={setRefundDetails1}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {addRefundDetails && (
        <RefundDetails
          onConfirm={() => refundCallback()}
          onClose={() => setAddRefundDetails(false)}
          patientId={props.patientId}
        />
      )}
      {addPaymentDetails && (
        <PaymentDetails
          onConfirm={() => callback()}
          patientId={props.patientId}
          outStandingAmount={credits?.OutstandingAmount}
          onClose={() => setAddPaymentDetails(false)}
          patientPersonalInformation={props.patientPersonalInformation}
        />
      )}
      {showAppointmentDetails && (
        <AppointmentDetails
          appId={appointmentId}
          setShowAppointmentDetails={setShowAppointmentDetails}
          setRescheduleAppointmentDialog={setRescheduleAppointmentDialog}
          setCancelDialog={setCancelDialog}
          setAppointment={setAppointment}
          appointment={appointment}
        />
      )}
      {showPaymentDetails && (
        <ViewPaymentDetails
          onClose={() => setShowPaymentDetails(false)}
          onConfirm={() => {}}
          appointmentId={selectedAppointmentId}
          appointmentDetails={appointmentDetails!}
        />
      )}
      {viewBillDialog && (
        <ViewBill
          selectedRow={selectedRow}
          handleViewBillClose={handleViewBillClose}
          patientPersonalInformation={props.patientPersonalInformation}
        />
      )}

      {addPaymentDetailsForAppt && (
        <BillingAndPaymentDetails
          appointmentDetails={appointmentDetails!}
          outStandingAmount={credits?.OutstandingAmount}
          onClose={() => setAddPaymentDetailsForAppt(false)}
          onConfirm={() =>
            handleStatusChange(selectedAppointmentId, "Completed")
          }
        />
      )}
      {cancelDialog && (
        <CancelAppointmentDialog
          setCancelDialog={setCancelDialog}
          appId={appointmentId}
          getAppointments={getPatientAppointments}
        />
      )}
      {rescheduleAppointmentDialog && (
        <>
          {appointment && (
            <RescheduleAppointmentPage
              onClose={() => setRescheduleAppointmentDialog(false)}
              appointmentDetails={appointment}
              getAppointments={getPatientAppointments}
            />
          )}
        </>
      )}
      <RightOffcanvas
        isOpen={newBill}
        onClose={() => {
          setNewBill(false);
        }}
      >
        {newBill && (
          <>
            <InvoicePage
              clientName={
                (props.patientPersonalInformation &&
                  props.patientPersonalInformation.salutation ?
                  (props.patientPersonalInformation?.salutation + " ")  : "") +
                props.patientPersonalInformation?.firstName +
                " " +
                props.patientPersonalInformation?.lastName
              }
              invoiceDetails={{
                invoiceNumber: "",
                invoiceDate: new Date(),
                dueDate: new Date(),
                entityId: userData?.entity_id,
                entityBranchId: userData?.branch_id,
                clientName:
                  props.patientPersonalInformation?.firstName +
                  " " +
                  props.patientPersonalInformation?.lastName,
                clientId: props.patientId,
                items: [],
                totalAmount: 0,
                status: "pending",
                billForAppointments: false,
                totalPaid: 0,
                tax: 0,
                outStandingAmount: 0,
                paymentDetails: [],
                discount: 0,
                subtotal: 0,
                paymentMode: "",
                notes: "",
                taxes: [],
              }}
              onClose={() => setNewBill(false)}
            />

            {/*             <BillDetailsPage
              onClose={() => setNewBill(false)}
              bill={{
                invoiceNumber: "",
                invoiceDate: new Date(),
                dueDate: new Date(),
                entityId: userData?.entity_id,
                entityBranchId: userData?.branch_id,
                clientName:
                  props.patientPersonalInformation?.firstName +
                  " " +
                  props.patientPersonalInformation?.lastName,
                clientId: props.patientId,
                items: [],
                totalAmount: 0,
                status: "pending",
                billForAppointments: false,
                totalPaid: 0,
                tax: 0,
                outStandingAmount: 0,
                paymentDetails: [],
              }}
            /> */}
          </>
        )}
      </RightOffcanvas>
    </div>
  );
}
