import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MuscleDetailsCard from "./components/MuscleDetailsCard";
import MuscleDetails from "./type/MuscleDetails";
import { Convert } from "./type/MuscleTightness";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import moment from "moment";

export const DetailSchema = z.object({
  name: z.string().optional(),
  grading: z.string().optional(),
});
export type Detail = z.infer<typeof DetailSchema>;

export const MuscleTightnessSchema = z.object({
  id: z.string().optional().default(""),
  masterAssessmentId: z.string().optional().default(""),
  clientId: z.string().optional().default(""),
  entityId: z.string().optional().default(""),
  entityBranchId: z.string().optional().default(""),
  details: z.array(DetailSchema),
});
export type MuscleTightnessSchemaType = z.infer<typeof MuscleTightnessSchema>;

type MuscleTightnessAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function MuscleTightnessAssessmentComponent(
  props: MuscleTightnessAssessmentProps
) {
  const [musclesList, setMusclesList] = React.useState<MuscleDetails[]>([]);

  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);
  const userData = getUserPersistedOnLocalStorage();

  const [lastUpdateAt, setLastUpdatedAt] = React.useState("");

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(MuscleTightnessSchema),
    defaultValues: { masterAssessmentId: props.masterAssessmentId },
  });

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getMuscleTightnessAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"].details;

          // const converted = data.map((muscle: any) =>
          //   Convert.toMuscleTightness(JSON.stringify(muscle)),
          // );

          const converted = data.map((muscle: any) => ({
            ...muscle,
            id: uuidv4(),
          }));

          // const musclesListTotal = [...musclesList, ...converted];
          setMusclesList(converted);
          setLastUpdatedAt(response.data["data"]["audit"]["lastUpdatedAt"]);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Muscle Tightness:", error);

          setLoading(false);
        }
      }
    };

    if (props.assessmentId !== "000000000000000000000000")
      initializeAssessment();
    else
      setMusclesList([
        {
          id: uuidv4(),
          name: "",
          grading: "",
        },
      ]);
  }, []);

  const onSubmit = async () => {
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        //MuscleTightnessSchema.parse(data);
        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateMuscleTightnessAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, {
          id: props.assessmentId,
          masterAssessmentId: props.masterAssessmentId,
          clientId: props.patientId,
          entityId: userData?.entity_id ?? "",
          entityBranchId: userData?.branch_id ?? "",
          details: musclesList,
        });
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Muscle Tightness updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    } else {
      try {
        // MuscleTightnessSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createMuscleTightnessAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, {
          masterAssessmentId: props.masterAssessmentId,
          clientId: props.patientId,
          entityId: userData?.entity_id ?? "",
          entityBranchId: userData?.branch_id ?? "",
          details: musclesList,
        });
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Muscle Tightness created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    }
  };
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col md:flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Muscle Tightness Assessment
        </h3>
        <p className="text-sm text-gray-500">
          {props.assessmentId !== "000000000000000000000000" &&
            `Last updated: ${
              lastUpdateAt &&
              moment(new Date(lastUpdateAt)).format("DD-MM-YYYY") !==
                "01-01-0001"
                ? moment(new Date(lastUpdateAt)).format("DD-MM-YYYY")
                : "N/A"
            }`}
        </p>
      </div>
      <hr className="w-full" />

      {musclesList.map((muscle) => (
        <MuscleDetailsCard
          muscle={muscle}
          muscleList={musclesList}
          setMuscleDetailsList={setMusclesList}
          isDisabled={isDisabled}
        />
      ))}
      <div className="mt-5">
        <Button
          variant={ButtonVariant.SECONDARY}
          type="button"
          isDisabled={isDisabled}
          className="ml-3 bg-success-500 hover:bg-success-500"
          onClick={() => {
            setMusclesList((all) => [
              ...all,
              {
                id: uuidv4(),
                name: "",
                grading: "",
              },
            ]);
          }}
          children={
            <div className="flex flex-row items-center">
              <FontAwesomeIcon
                icon={faPlus}
                className="h-4 w-4 mr-2 "
                aria-hidden="true"
              />
              <span className="text-sm ">Add Muscle</span>
            </div>
          }
        />
      </div>
      <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
        <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
          <OutlinedButton
            type="button"
            onClick={() => {
              props.postSubmitCallback();
            }}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </OutlinedButton>
          {isDisabled ? (
            <Button
              type="button"
              key="EditButton"
              onClick={() => {
                setIsDisabled(false);
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Edit
            </Button>
          ) : props.assessmentId !== "000000000000000000000000" ? (
            <Button
              type="submit"
              key="UpdateButton"
              onClick={() => {
                onSubmit();
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Update
            </Button>
          ) : (
            <Button
              type="submit"
              key="CreateButton"
              onClick={() => {
                onSubmit();
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
