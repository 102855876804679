import Button, { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import DetailsStep from "./DetailsStep";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import EConvert, { Entity } from "@pages/add-patient/types/EntityType";
import BConvert, { Branch } from "@pages/add-patient/types/BranchType";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { ValidationError } from "@components/hook-form";
import Applabel from "@components/hook-form/applabel";
import moment from "moment";
import CountryInputSelect from "@components/hook-form/country-input-select";
import DoctorSearch from "../components/DoctorSearch";
import HospitalSearch from "../components/HospitalSearch";
import {
  ConvertReferringUsersHospitals,
  ReferringUsersHospitals,
} from "../types/ReferringUsersHospitals";

import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import { convertPhoneNumberAndCodeToNumber } from "@utils/phoneUtils";
import OtherReferralSearch from "../components/OtherReferralSearch";
import SalutationSearch from "../components/SalutationSearch";

// TO DO  : Change Country state city from optional to required with respective messages

export const AddressSchema = z.object({
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  country: z.string().min(1, { message: "Country is required" }),
  postalCode: z.string().optional(),
});
export type Address = z.infer<typeof AddressSchema>;

export const PatientSchema = z.object({
  salutation: z.string().optional(),
  firstName: z.string().min(1, { message: "First name is required" }),
  middleName: z.string().optional(),
  lastName: z.string().min(1, { message: "Last name is required" }),
  entityId: z.string(),
  entityBranchId: z.string(),
  email: z
    .string()
    .email({ message: "Must be a valid email" })
    .optional()
    .or(z.literal("")),
  isActive: z.boolean().optional(),
  phoneCode: z.string().min(1, { message: "Phone Code is required" }),
  phone: z.string().superRefine((val, ctx) => {
    //
    if (!val || val.trim() === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter Phone`,
        fatal: true,
      });
      return z.NEVER;
    }
    if (!isValidPhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: "Invalid phone number format",
      });

      return z.NEVER;
    }

    if (!isPossiblePhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid phone number length`,
        fatal: true,
      });
      return z.NEVER;
    }
  }),
  emergencyPhoneCode: z.string().optional(),
  emergencyPhone: z
    .string()
    .refine(
      (val) => {
        if (!val || val.trim() === "") return true; // Allow empty string if field is optional
        return isValidPhoneNumber(val);
      },
      { message: "Invalid emergency phone number format" }
    )
    .optional(),
  birthPlace: z.string().optional(),
  bloodGroup: z.string(),
  address: z.union([AddressSchema, z.null()]).optional(),
  age: z.coerce.number().nonnegative(),
  gender: z.string().optional(),
  dob: z
    .string()
    .optional()
    .refine(
      (val) => {
        if (!val || val.trim() === "") return true;

        return val.length > 1;
      },
      { message: "Please enter date of birth" }
    ),
  sendOnboardEmail: z.boolean().refine(
    (val) => {
      return true;
    },
    { message: "Optional" }
  ),
  whatsAppConsent: z.boolean(),

  // consent: ConsentSchema,
  referringUser: z.string().optional().default(""),
  referringHospital: z.string().optional().default(""),
  otherReferral: z.string().optional().default(""),
});
export type PatientSchemaType = z.infer<typeof PatientSchema>;

// Infer the TS type according to the zod schema.
// const steps = [DetailsStep, ConfigurationStep, DeclarationStep];
const steps = [DetailsStep];

function AddPatientPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({}); // Store form data here
  const [entities, setEntities] = React.useState<Entity[] | null>(null); // this is the list of entities [{}]
  const [entityId, setEntityId] = React.useState<string | null>(null);

  const [branches, setBranches] = React.useState<Branch[] | null>(null); // this is the list of entities [{}]
  const [branchId, setBranchId] = React.useState<string | null>(null);

  const [referringData, setReferringData] =
    React.useState<ReferringUsersHospitals | null>(null); // This is the list of doctors
  const [selectedDoctorName, setSelectedDoctorName] = useState("");
  const [selectedHospitalName, setSelectedHospitalName] = useState("");
  const [selectedOtherReferralName, setSelectedOtherReferralName] =
    useState("");

  const [selectedSalutationSearch, setSelectedSalutationSearch] = useState("");

  const [loading, setLoading] = React.useState(true);
  const [error, setApiError] = React.useState("");

  const [isPlatformAdmin, setIsPlatformAdmin] = React.useState(false);
  const [isEntityOwner, setIsEntityOwner] = React.useState(false);

  const [phoneNumber, setPhoneNumber] = useState("91");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("91");
  const handlePhoneNumberChange = (value: E164Number | undefined) => {
    setValue("phone", value?.toString() ?? "");
  };
  const handleEmergencyPhoneNumberChange = (value: E164Number | undefined) => {
    setValue("emergencyPhone", value?.toString() ?? "");
  };

  const getBranches = async () => {
    try {
      const paramsMap = new Map<string, string>([["entityId", entityId ?? ""]]);
      const filteredEndpoint = replaceParams(endpoints.branch.list, paramsMap);

      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      const convertedBranchList = data.map((entity: any) =>
        BConvert.toBranch(JSON.stringify(entity))
      );

      setBranches(convertedBranchList);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching entity list:", error);
      setApiError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const _init = async () => {
      await getBranches();
      await fetchDoctorsList();
    };
    if (entityId && !branchId) {
      _init(); // This will be called whenever entityId has a value
    } // This will be called whenever entityId changes
  }, [entityId]);

  const userData = getUserPersistedOnLocalStorage();
  useEffect(() => {
    const getEntities = async () => {
      try {
        const response = await axios.get(endpoints.entity.list);
        const data = response.data["data"];
        const convertedEntityList = data.map((entity: any) =>
          EConvert.toEntity(JSON.stringify(entity))
        );

        setEntities(convertedEntityList);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching entity list:", error);
        setApiError(error.message);
        setLoading(false);
      }
    };

    if (userData?.user_role === "PLATFORM_ADMIN") {
      getEntities();

      setIsPlatformAdmin(true);
    } else if (userData?.user_role === "ENTITY_OWNER") {
      setEntityId(userData?.entity_id ?? "");
      setIsEntityOwner(true);
    } else {
      setEntityId(userData?.entity_id ?? "");
      setBranchId(userData?.branch_id ?? "");
      setLoading(false);
    }

    // setLoading(false);
  }, []);

  const fetchDoctorsList = async () => {
    try {
      const response = await axios.get(endpoints.patient.getReferrals);

      const data = response.data["data"];
      const converted =
        ConvertReferringUsersHospitals.toReferringUsersHospitals(
          JSON.stringify(data)
        );

      setReferringData(converted);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching staff list:", error);

      setLoading(false);
    }
  };

  const handleEntitySelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setEntityId(event.target.value);
    setBranchId(null);
    // getBranches(); // Update the state with the selected value
  };

  const handleBranchSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBranchId(event.target.value);
    // getBranches(); // Update the state with the selected value
  };

  const navigator = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<PatientSchemaType>({ resolver: zodResolver(PatientSchema) });

  useEffect(() => {
    const countryCode = getCountryCallingCode("IN");
    setValue("phoneCode", countryCode?.toString() ?? "");
    setValue("phone", "");
  }, []);

  const onSubmit: SubmitHandler<PatientSchemaType> = async (data) => {
    try {
      const combinedData = {
        ...formData,
        ...data,
        // consent: {
        //   time: consentTime,
        //   id: consentId,
        //   version: consentVersion,
        //   consentgiven: getValues('consent.consentgiven'),
        // },
      };
      //  PatientSchema.parse(combinedData);
      const phoneData = convertPhoneNumberAndCodeToNumber({
        phoneNumber: combinedData.phone,
        phoneCode: combinedData.phoneCode,
      });

      const emergencyPhoneData = convertPhoneNumberAndCodeToNumber({
        phoneNumber: combinedData.emergencyPhone,
        phoneCode: combinedData.emergencyPhoneCode,
      });

      const response = await axios.post(
        `${endpoints.patient.onboard}/${watch("sendOnboardEmail")}`,
        {
          ...combinedData,
          phone: phoneData.phoneNumber,
          phoneCode: phoneData.phoneCode,
          emergencyPhone: emergencyPhoneData.phoneNumber,
          emergencyPhoneCode: emergencyPhoneData.phoneCode,
          isAppointmentNotificationEnabled: combinedData.whatsAppConsent,
          whatsAppConsent: combinedData.whatsAppConsent,
        }
      );
      if (response.data.status === 200)
        toast.success(
          response.data.message === "success"
            ? "Patient Added successfully"
            : response.data.message
        );

      navigator("/patient");
    } catch (error) {
      toast.error(error.message);

      console.error("Error submitting form:", error);
    }
  };

  const StepComponent = steps[currentStep];

  // const StepTitles = ['Details', 'Configuration', 'Declaration'];
  const StepTitles = ["Details"];

  return (
    <>
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col justify-start">
          <div className="flex flex-row justify-between">
            <h1 className="text-2xl font-bold">Add Patient</h1>
            <Button
              variant={ButtonVariant.PRIMARY}
              type="submit"
              className="ml-3"
              onClick={() => {
                navigator("/patient");
              }}
            >
              Back
            </Button>
          </div>
          <span className="text-gray-500">Add a new Patient to the system</span>
        </div>

        <hr className="my-4" />

        <div className="flex flex-row justify-between md:justify-start items-center w-full">
          {StepTitles.map((title, index) => (
            <div
              key={index}
              className={`flex flex-row py-2 px-3 mx-2 items-center justify-center rounded-xl ${
                index === currentStep
                  ? "bg-primary-100 text-primary"
                  : "bg-gray-200 text-gray-500"
              }`}
            >
              <div className="flex flex-col mr-2 h-6 w-6 rounded-full bg-primary-600 text-white text-sm text-primary justify-center items-center">
                <span>{index + 1}</span>
              </div>
              <span>{title}</span>
            </div>
          ))}
        </div>

        <hr className="my-4" />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-32">
            <div>
              {loading && <p>Loading...</p>}
              {error && <p>Error: {error}</p>}
              {!loading && !error && entities && (
                <div className="flex flex-col w-full space-y-4">
                  <div className="flex flex-col ">
                    <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                      Select Entity
                    </label>

                    <select
                      {...register("entityId")}
                      onChange={handleEntitySelectChange}
                      name="entityId"
                      className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      {entities!.map((entity) => (
                        <option key={entity.id} value={entity.id}>
                          {entity.name}
                        </option>
                      ))}
                    </select>
                    {errors.entityId && (
                      <ValidationError
                        message={errors.entityId?.message?.toString() ?? ""}
                      />
                    )}
                  </div>
                </div>
              )}
              {!loading && !error && branches && (
                <div className="flex flex-col w-full space-y-4">
                  <div className="flex flex-col ">
                    <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                      Select Branch
                    </label>

                    <select
                      {...register("entityBranchId")}
                      onChange={handleBranchSelectChange}
                      name="entityBranchId"
                      className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      {branches!.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.name}
                        </option>
                      ))}
                    </select>
                    {errors.entityBranchId && (
                      <ValidationError
                        message={
                          errors.entityBranchId?.message?.toString() ?? ""
                        }
                      />
                    )}
                  </div>
                </div>
              )}
              {!loading && !error && !isPlatformAdmin && (
                <div className="flex flex-col w-full space-y-4">
                  <div className="flex flex-col ">
                    <input
                      {...register("entityId")}
                      name="entityId"
                      className={`hidden lock w-full rounded-lg bg-gray-100 border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm `}
                      value={userData?.entity_id}
                    />

                    {errors.entityId && (
                      <ValidationError
                        message={errors.entityId?.message?.toString() ?? ""}
                      />
                    )}
                  </div>
                </div>
              )}
              {!loading && !error && !isPlatformAdmin && !isEntityOwner && (
                <div className="flex flex-col w-full space-y-4">
                  <div className="flex flex-col ">
                    <input
                      {...register("entityBranchId")}
                      name="entityBranchId"
                      className={`hidden lock w-full rounded-lg bg-gray-100 border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm `}
                      value={userData?.branch_id}
                    />

                    {errors.entityBranchId && (
                      <ValidationError
                        message={
                          errors.entityBranchId?.message?.toString() ?? ""
                        }
                      />
                    )}
                  </div>
                </div>
              )}

              {!loading && !error && entityId && branchId && (
                <div
                  className={
                    entityId === ""
                      ? "invisible"
                      : branchId === ""
                      ? "invisible"
                      : "visible"
                  }
                >
                  <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                    <div className="flex flex-col grow">
                      <Applabel label="Referring Hospital" />

                      <HospitalSearch
                        data={referringData?.ReferringHospitals || []}
                        setSelectedHospitalName={setSelectedHospitalName}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                      />
                      {errors.referringHospital && (
                        <ValidationError
                          message={
                            errors.referringHospital?.message?.toString() ?? ""
                          }
                        />
                      )}
                    </div>
                    <div className="flex flex-col grow">
                      <Applabel label="Referring Doctor" />

                      <DoctorSearch
                        data={referringData?.ReferringUsers || []}
                        setSelectedDoctorName={setSelectedDoctorName}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                      />
                      {errors.referringUser && (
                        <ValidationError
                          message={
                            errors.referringUser?.message?.toString() ?? ""
                          }
                        />
                      )}
                    </div>
                    <div className="flex flex-col grow">
                      <Applabel label="Other Referral" />

                      <OtherReferralSearch
                        data={referringData?.OtherReferrals || []}
                        setSelectedOtherReferralName={
                          setSelectedOtherReferralName
                        }
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                      />
                      {errors.referringHospital && (
                        <ValidationError
                          message={
                            errors.referringHospital?.message?.toString() ?? ""
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                    <div className="flex flex-col w-full md:w-1/4">
                      <Applabel label="Title" />

                      <SalutationSearch
                        data={[
                          "Mr",
                          "Mrs",
                          "Ms",
                          "Miss",
                          "Dr",
                          "Prof",
                          "Rev",
                          "Hon",
                          "Capt",
                          "Lt. Col",
                          "Lt",
                          "Capt",
                          "Maj",
                          "Lt. Col",
                          "Col",
                          "Brig",
                          "Maj. Gen",
                          "Lt. Gen",
                          "Gen",
                          "Fld. Mshl",
                          "Sub Lt",
                          "Lt. Cdr",
                          "Cmdr",
                          "Commod",
                          "RAdm",
                          "VAdm",
                          "Adm",
                          "Adm. of the Fleet",
                          "Flt. Off",
                          "Flt. Lt",
                          "Sqn. Ldr",
                          "Wg. Cdr",
                          "Gp. Capt",
                          "Air Cmde",
                          "AVM",
                          "AM",
                          "ACM",
                          "Mshl. of the AF",
                        ]}
                        setSelectedSalutationSearch={
                          setSelectedSalutationSearch
                        }
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                      />
                      {errors.salutation && (
                        <ValidationError
                          message={errors.salutation?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="First Name" mandatory />
                      <input
                        {...register("firstName")}
                        placeholder="Patient First Name"
                        value={watch("firstName")}
                        type="text"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      />
                      {errors.firstName && (
                        <ValidationError
                          message={errors.firstName?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Middle Name" />
                      <input
                        {...register("middleName")}
                        placeholder="Patient Middle Name"
                        value={watch("middleName")}
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      />
                      {errors.middleName && (
                        <ValidationError
                          message={errors.middleName?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Last Name" mandatory />
                      <input
                        {...register("lastName")}
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        placeholder="Patient Last Name"
                        value={watch("lastName")}
                      />
                      {errors.lastName && (
                        <ValidationError
                          message={errors.lastName?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Date of Birth" />
                      <input
                        {...register("dob")}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        className={`flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        type="date"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (
                            event.target.value != "" ||
                            event.target.value != null
                          ) {
                            const birthdate = new Date(event.target.value);

                            const currentDate = new Date();
                            let age =
                              currentDate.getFullYear() -
                              birthdate.getFullYear();

                            // Check if the birthdate has occurred this year already
                            if (
                              birthdate.getMonth() > currentDate.getMonth() ||
                              (birthdate.getMonth() ===
                                currentDate.getMonth() &&
                                birthdate.getDate() > currentDate.getDate())
                            ) {
                              age = age - 1;
                            }
                            setValue("age", age);
                            if (errors.age) {
                              clearErrors("age");
                            }
                            setValue(
                              "dob",
                              moment(event.target.value).format("YYYY-MM-DD")
                            );
                            clearErrors("dob");
                          }
                        }}
                      />
                      {errors.dob && (
                        <ValidationError
                          message={errors.dob?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Age" />
                      <input
                        {...register("age")}
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        type="number"
                        min={1}
                        max={200}
                        placeholder="Patient Age"
                        value={watch("age")}
                      />
                    </div>
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Place of Birth" />
                      <input
                        {...register("birthPlace")}
                        placeholder="Patient Place of Birth"
                        value={watch("birthPlace")}
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      />
                      {errors.birthPlace && (
                        <ValidationError
                          message={errors.birthPlace?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Gender" />
                      <select
                        {...register("gender")}
                        placeholder="Patient Gender"
                        value={watch("gender")}
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {["Male", "Female", "Other"].map((Gender) => {
                          return (
                            <option key={Gender} value={Gender}>
                              {Gender}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Email" />
                      <input
                        {...register("email")}
                        placeholder="Patient Email"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        value={watch("email")}
                        required
                      />
                      {errors.email && (
                        <ValidationError
                          message={errors.email?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Mobile Number" mandatory />
                      <div className="flex flex-row">
                        <PhoneInput
                          onCountryChange={(
                            country?: CountryCode | undefined
                          ) => {
                            if (country) {
                              const countryCode =
                                getCountryCallingCode(country);
                              setValue(
                                "phoneCode",
                                countryCode?.toString() ?? ""
                              );
                            }
                          }}
                          {...register("phone")}
                          international={true}
                          defaultCountry="IN"
                          countryCallingCodeEditable={false}
                          placeholder="Enter phone number"
                          value={watch("phone")}
                          onChange={(value?: E164Number | undefined) => {
                            clearErrors("phone");
                            handlePhoneNumberChange(value);
                          }}
                          onBlur={() => {
                            trigger("phone");
                          }}
                          className="w-full"
                        />
                      </div>
                      {errors.phone && (
                        <ValidationError
                          message={errors.phone?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                  </div>
                  <span className="flex-none font-medium w-1/6 mb-0">
                    Correspondence Address
                  </span>
                  <br />
                  <span className="text-gray-500">
                    Please fill the patient's most recent correspondence address
                    below
                  </span>

                  <hr className="my-2" />
                  <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                    <div className="flex flex-col grow">
                      <Applabel label="Address" mandatory />

                      <input
                        {...register("address.address")}
                        placeholder="Patient Address"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        required
                      />
                      {errors.address?.address && (
                        <ValidationError
                          message={
                            errors.address?.address?.message?.toString() ?? ""
                          }
                        />
                      )}
                    </div>
                    <div className="flex flex-col grow">
                      <Applabel label="Postal/Zip code" />

                      <input
                        {...register("address.postalCode")}
                        placeholder="Patient Zip Code"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        required
                        value={watch("address.postalCode")}
                      />
                      {errors.address?.postalCode && (
                        <ValidationError
                          message={
                            errors.address.postalCode?.message?.toString() ?? ""
                          }
                        />
                      )}
                    </div>
                  </div>

                  <CountryInputSelect
                    isDisabled={false}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    country="address.country"
                    state="address.state"
                    city="address.city"
                    isRequired={true}
                    clearErrors={clearErrors}
                    errors={errors}
                  />

                  <span className="flex-none font-medium w-1/6 mb-0">
                    Medical Details
                  </span>
                  <br />
                  <span className="text-gray-500">
                    Please enter the patient's medical information below
                  </span>

                  <hr className="my-2" />
                  <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Blood Group" />

                      <select
                        {...register("bloodGroup")}
                        placeholder="BloodGroup"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        value={watch("bloodGroup")}
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {[
                          "A +ve",
                          "A -ve",
                          "B +ve",
                          "B -ve",
                          "AB +ve",
                          "AB -ve",
                          "O +ve",
                          "O -ve",
                        ].map((bloodGroup) => {
                          return (
                            <option key={bloodGroup} value={bloodGroup}>
                              {bloodGroup}
                            </option>
                          );
                        })}
                      </select>
                      {errors.bloodGroup && (
                        <ValidationError
                          message={errors.bloodGroup?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                    <div className="flex flex-col  w-full md:w-1/3">
                      <Applabel label="Emergency Phone" />
                      <div className="flex flex-row">
                        {" "}
                        <PhoneInput
                          onCountryChange={(
                            country?: CountryCode | undefined
                          ) => {
                            if (country) {
                              const countryCode =
                                getCountryCallingCode(country);
                              setValue(
                                "emergencyPhoneCode",
                                countryCode?.toString() ?? ""
                              );
                            }
                          }}
                          {...register("emergencyPhone")}
                          international={true}
                          countryCallingCodeEditable={false}
                          placeholder="Enter emergency phone number"
                          value={watch("emergencyPhone")}
                          onChange={(value?: E164Number | undefined) => {
                            clearErrors("emergencyPhone");
                            handleEmergencyPhoneNumberChange(value);
                          }}
                          onBlur={() => {
                            trigger("emergencyPhone");
                          }}
                          className="w-full"
                        />
                      </div>
                      {errors.emergencyPhone && (
                        <ValidationError
                          message={
                            errors.emergencyPhone?.message?.toString() ?? ""
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row items-center mt-6">
                    <input
                      {...register("sendOnboardEmail")}
                      type="checkbox"
                      className="form-checkbox h-6 w-6 text-indigo-600 rounded-md focus:ring-2 focus:ring-indigo-600"
                      defaultChecked
                    />
                    <label className="ml-3 text-gray-800 font-medium">
                      Send onboarding and consent form to patient
                    </label>
                  </div>
                  <div className="flex flex-row items-center mt-6">
                    <input
                      {...register("whatsAppConsent")}
                      type="checkbox"
                      className="form-checkbox h-6 w-6 text-indigo-600 rounded-md focus:ring-2 focus:ring-indigo-600"
                      defaultChecked
                    />
                    <label className="ml-3 text-gray-800 font-medium">
                      Send notifications, reminders, important updates through
                      WhatsApp
                    </label>
                  </div>
                  {/* {consentDialog && (
                    <ConsentDialog
                      setConsentDialog={setConsentDialog}
                      consentData={editorState}
                    />
                  )}

                  <div className="flex flex-row items-center mt-6">
                    <input
                      {...register('consent.consentgiven')}
                      type="checkbox"
                      className="form-checkbox h-6 w-6 text-indigo-600 rounded-md focus:ring-2 focus:ring-indigo-600"
                      onChange={e => {
                        if (e.target.checked) {
                          setConsentTime(moment(new Date()));
                        }
                      }}
                    />
                    <label
                      className="ml-3 text-gray-800 font-medium hover:underline"
                      onClick={() => setConsentDialog(true)}
                    >
                      I Agree to give my
                      <span className="text-[#4b6cd8]"> Consent</span> to the
                      Data Entered
                    </label>
                  </div>
                  {errors.consent && (
                    <ValidationError
                      message={
                        errors.consent.consentgiven?.message?.toString() ?? ''
                      }
                    />
                  )} */}
                  <div className="fixed items-center w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
                    <div className="flex flex-row ">
                      <div>
                        <Button
                          variant={ButtonVariant.PRIMARY}
                          type="submit"
                          className="ml-3"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
              {currentStep > 0 && (
                <OutlinedButton
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="mr-2"
                  type="button"
                >
                  Previous
                </OutlinedButton>
              )}
              {currentStep < steps.length - 1 && (
                <OutlinedButton
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => {
                    setCurrentStep(currentStep + 1);
                    setFormData({ ...formData, ...methods.getValues() });
                  }}
                  isDisabled={methods.formState.isDirty || !methods.formState.isValid}
                >
                  Next
                </OutlinedButton>
              )}
              {currentStep === steps.length - 1 && (
                <Button
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => {
                    setFormData({ ...formData, ...methods.getValues() });
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              )}
            </div> */}
        </form>
      </div>
    </>
  );
}

export default AddPatientPage;
