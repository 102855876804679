import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  ServiceList,
  ServiceFormData,
  SearchBarProps,
  ServicesConfigurationProps,
  ServiceApiResponse,
  Convert,
  initialServiceFormState,
} from "../types/ServiceList";
import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import { AxiosError } from "axios";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/outline-button";
import Modal from "@components/modal/modal";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { toast } from "react-toastify";
import SolidButton from "@components/button/button";
import ServiceForm from "./ServiceForm";

function SearchBar({ searchQuery, onSearch, setModalOpen }: SearchBarProps) {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <>
      <hr className="block xl:hidden border-t border-gray-200 mb-8" />
      <div className="px-0 xl:px-4 xl:mt-4">
        <div className="flex flex-col xl:flex-row gap-4">
          <div className="w-full xl:w-[80%]">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search for Service Name"
              className="w-full px-4 py-2 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500"
            />
          </div>
          <div className="w-full xl:w-[20%]">
            <button
              type="button"
              onClick={() => setModalOpen(true)}
              className="w-full bg-primary-600 text-white px-4 py-2 rounded-xl hover:bg-primary-700 focus:outline-none"
            >
              Create New Service
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function ServicesConfigurationPage({
  branchId,
  entityId,
}: ServicesConfigurationProps) {
  const [serviceList, setServiceList] = useState<ServiceList[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [serviceData, setServiceData] = useState<ServiceFormData>(
    initialServiceFormState
  );
  const [isViewMode, setIsViewMode] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedService, setSelectedService] = useState<ServiceList | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");

  const axios = useAxios();

  const getServices = useCallback(async () => {
    try {
      const paramsMap = new Map<string, string>([["branchId", branchId]]);
      const endpoint = replaceParams(endpoints.branch.services, paramsMap);
      const response = await axios.get<ServiceApiResponse>(endpoint);
      if (response.status === 200) {
        setServiceList(response.data["data"]);
        setLoading(false);
      } else {
        toast.error(response.data["message"]);
      }
    } catch (err) {
      console.error("Error fetching service list:", err);
      const error = err as AxiosError;
      setError(error.message);
      setLoading(false);
    }
  }, [branchId]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  const viewServiceDetails = (service: ServiceList) => {
    setServiceData(Convert.toServiceFormData(service));
    setIsViewMode(true);
    setModalOpen(true);
  };

  const editServiceDetails = (service: ServiceList) => {
    setServiceData(Convert.toServiceFormData(service));
    setIsEditMode(true);
    setTimeout(() => {
      setModalOpen(true);
    }, 0);
  };

  const handleSaveService = async () => {
    try {
      let endpoint: string;
      if (isEditMode) {
        const params: Map<string, string> = new Map([["id", serviceData.id]]);
        endpoint = replaceParams(endpoints.branch.updateService, params);
      } else {
        endpoint = endpoints.branch.createService;
      }

      await axios[isEditMode ? "put" : "post"](endpoint, {
        branchId,
        ...serviceData,
      });

      toast.success(
        `Service ${isEditMode ? "updated" : "created"} successfully!`
      );
      setModalOpen(false);
      getServices();
      resetForm();
    } catch (err) {
      console.error(
        `Error ${isEditMode ? "updating" : "creating"} service:`,
        err
      );
      toast.error(`Failed to ${isEditMode ? "update" : "create"} service.`);
    }
  };

  const resetForm = () => {
    setServiceData(initialServiceFormState);
    setIsEditMode(false);
    setIsViewMode(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setTimeout(() => {
      setIsViewMode(false);
      setIsEditMode(false);
      setServiceData(initialServiceFormState);
    }, 300);
  };

  const handleUpdateServiceStatus = (service: ServiceList) => {
    setSelectedService(service);
    setShowConfirmationPopup(true);
  };

  const handleSave = async (service: ServiceList, newStatus: boolean) => {
    try {
      const params: Map<string, string> = new Map([["id", service.id]]);
      const endpoint: string = replaceParams(
        endpoints.branch.updateService,
        params
      );

      await axios.put(endpoint, {
        ...service,
        isActive: newStatus,
        branchId,
      });

      toast.success("Service status updated successfully");
      getServices();
    } catch (err) {
      console.error("Error updating service status:", err);
      toast.error("Failed to update service status");
    }
  };

  const confirmServiceStatusChange = async (confirm: boolean) => {
    if (confirm && selectedService) {
      const newStatus = !selectedService.isActive;
      await handleSave(selectedService, newStatus);
    }
    setShowConfirmationPopup(false);
    setSelectedService(null);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const filteredServices = serviceList?.filter((service) =>
    service.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <SearchBar
        searchQuery={searchQuery}
        onSearch={handleSearch}
        setModalOpen={setModalOpen}
      />

      <div className="mt-5">
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && (!serviceList || serviceList.length === 0) && (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <p className="text-gray-500">
              No services available. Create your first service!
            </p>
          </div>
        )}
        {!loading && !error && serviceList && serviceList.length > 0 && (
          <>
            <div className="px-4 sm:px-6 lg:px-2 w-full hidden xl:block">
              <div className="sm:flex sm:items-center justify-between">
                <div></div>
              </div>
              <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Service Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Duration
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Active/Inactive
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {filteredServices?.map((service) => (
                          <tr key={service.id}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {service.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              ₹{service.defaultCost}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500">
                              <p
                                className="whitespace-normal break-words max-w-xs overflow-hidden"
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                                title={service.description}
                              >
                                {service.description}
                              </p>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {service.duration} mins
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="flex justify-start">
                                <Switch
                                  checked={service.isActive}
                                  onChange={() =>
                                    handleUpdateServiceStatus(service)
                                  }
                                  className={`${
                                    service.isActive
                                      ? "bg-secondary-600"
                                      : "bg-secondary-50"
                                  } relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                  <span
                                    aria-hidden="true"
                                    className={`${
                                      service.isActive
                                        ? "translate-x-9"
                                        : "translate-x-0"
                                    } pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                  />
                                </Switch>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="flex justify-center space-x-2">
                                <OutlinedButton
                                  variant={ButtonVariant.SECONDARY}
                                  type="button"
                                  onClick={() => viewServiceDetails(service)}
                                  children="View"
                                />
                                <OutlinedButton
                                  variant={ButtonVariant.SECONDARY}
                                  type="button"
                                  onClick={() => editServiceDetails(service)}
                                  children="Edit"
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile view */}
            <div className="block xl:hidden w-full">
              <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 bg-gray-50 -mx-8 p-8">
                {filteredServices?.map((service) => (
                  <div
                    key={service.id}
                    className="flex flex-col justify-between rounded-2xl shadow-md bg-white overflow-hidden"
                  >
                    <div className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4">
                      <div className="flex flex-col w-full">
                        <span className="flex-initial text-md text-black font-bold">
                          {service.name}
                        </span>
                        <span className="flex-initial truncate text-secondary-500">
                          {service.description}
                        </span>
                        <span className="flex-initial text-secondary-500">
                          ₹{service.defaultCost}
                        </span>
                        <span className="flex-initial text-secondary-500">
                          {service.duration} mins
                        </span>
                        <div className="flex text-secondary-500 justify-between mt-4">
                          <div className="flex flex-row">
                            <OutlinedButton
                              variant={ButtonVariant.SECONDARY}
                              type="button"
                              onClick={() => viewServiceDetails(service)}
                              className="mr-2"
                              children="View"
                            />
                            <OutlinedButton
                              variant={ButtonVariant.SECONDARY}
                              type="button"
                              onClick={() => editServiceDetails(service)}
                              className="mr-2"
                              children="Edit"
                            />
                          </div>
                          <div className="flex flex-row justify-end w-full items-center">
                            {service.isActive ? (
                              <span className="text-gray-500 mr-2">Active</span>
                            ) : (
                              <span className="text-gray-500 mr-2">
                                Inactive
                              </span>
                            )}
                            <Switch
                              checked={service.isActive}
                              onChange={() =>
                                handleUpdateServiceStatus(service)
                              }
                              className={`${
                                service.isActive
                                  ? "bg-secondary-600"
                                  : "bg-secondary-50"
                              } relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                              <span
                                aria-hidden="true"
                                className={`${
                                  service.isActive
                                    ? "translate-x-9"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>

      <Modal isOpen={isModalOpen && !isViewMode} onClose={handleModalClose}>
        <div className="w-[99.5vw] md:w-full md:max-w-6xl mx-auto">
          <ServiceForm
            key={isEditMode ? "edit" : "create"}
            initialData={isEditMode ? serviceData : undefined}
            branchId={branchId}
            closeForm={handleModalClose}
            onSuccess={getServices}
            isViewMode={false}
          />
        </div>
      </Modal>

      <Modal isOpen={isModalOpen && isViewMode} onClose={handleModalClose}>
        <div className="w-[99.5vw] md:w-full md:max-w-6xl mx-auto">
          <ServiceForm
            initialData={serviceData}
            branchId={branchId}
            closeForm={handleModalClose}
            onSuccess={getServices}
            isViewMode={true}
          />
        </div>
      </Modal>

      <Transition appear show={showConfirmationPopup} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 px-6 pt-6 text-gray-900"
                  ></Dialog.Title>
                  <div className="bg-white p-4 rounded-lg">
                    <p>
                      Are you sure you want to make the service{" "}
                      {selectedService?.isActive ? "inactive" : "active"}?
                    </p>
                    <div className="flex justify-end mt-4">
                      <button
                        className="px-4 py-2 bg-red-500 text-white rounded-md mr-4"
                        onClick={() => confirmServiceStatusChange(false)}
                      >
                        Close
                      </button>
                      <SolidButton
                        className="mr-1"
                        variant={ButtonVariant.SECONDARY}
                        type="button"
                        onClick={() => confirmServiceStatusChange(true)}
                        children="Yes"
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ServicesConfigurationPage;
