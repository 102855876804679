import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

interface DashboardNavigationProps {
  initialData: {
    entityLogoUrl?: string;
    EntityName?: string;
    EntityBranchName?: string;
    RoleName?: string;
  };
  logo: string;
  navigation: Array<{ name: string; href: string; icon: React.ReactNode }>;
  isRouteAccessible: (href: string) => boolean;
  activeTabColorClasses: string;
  inActiveTabColorClasses: string;
  initials: string;
  formatRoleName: (roleName: string) => string;
  navigateStaffEntityManage: () => void;
  navigateStaffBranchManage: () => void;
  onClick?: () => void; // Add the onClick prop
}

const DashboardNavigation: React.FC<DashboardNavigationProps> = ({
  initialData,
  logo,
  navigation,
  isRouteAccessible,
  activeTabColorClasses,
  inActiveTabColorClasses,
  initials,
  formatRoleName,
  navigateStaffEntityManage,
  navigateStaffBranchManage,
  onClick, // Destructure the onClick prop
}) => {
  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 border-r border-gray-200">
      <div className="flex shrink-0 justify-center p-2">
        {initialData?.entityLogoUrl ? (
          <img
            src={initialData?.entityLogoUrl}
            alt="Entity Logo"
            className="w-full h-full object-center object-cover"
          />
        ) : (
          <img
            src={logo}
            alt="Default Logo"
            className="w-full h-full object-center object-cover"
          />
        )}
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li onClick={onClick}>
            <ul className="-mx-2 space-y-1">
              {navigation
                .filter((item) => isRouteAccessible(item.href.toString()))
                .map((item) => (
                  <li key={item.name}>
                    <NavLink to={item.href}>
                      {({ isActive, isPending }) => (
                        <>
                          <span
                            className={classNames(
                              isActive || isPending
                                ? activeTabColorClasses
                                : inActiveTabColorClasses,
                              "group flex gap-x-3 rounded-xl p-3 text-base leading-6 font-semibold"
                            )}
                          >
                            {item.icon}
                            {item.name}
                          </span>
                        </>
                      )}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </li>

          <div className="mt-auto space-y-4">
            <li>
              <div className="flex flex-col justify-between rounded-xl shadow-md bg-white w-full overflow-clip">
                {/* ...content of the staff card... */}
                <div className="flex flex-row rounded-xl bg-white-300 items-center text-xs font-semibold space-x-2 p-4 pb-0 w-full">
                  <span
                    className={classNames(
                      "text-tertiary-600 border-tertiary-600 group-hover:border-tertiary-600 group-hover:text-tertiary-600",
                      "flex h-10 w-10 shrink-0 items-center justify-center rounded-lg border text-lg font-extrabold bg-white "
                    )}
                  >
                    {initials}
                  </span>
                  <div className="flex flex-col w-full ">
                    <span className="whitespace-nowrap overflow-hidden truncate">
                      {initialData?.EntityName}
                    </span>
                    <span className="whitespace-nowrap overflow-hidden truncate">
                      {initialData?.EntityBranchName}
                    </span>
                  </div>
                </div>

                <div className="flex flex-row rounded-bl-xl rounded-br-xl bg-tertiary-500 items-center px-4 py-2 mt-2">
                  <div className="flex flex-row w-full justify-center font-semibold text-white text-sm">
                    {formatRoleName(initialData?.RoleName ?? "")}
                  </div>
                </div>
              </div>
            </li>
            {initialData?.RoleName === "ENTITY_OWNER" && (
              <>
                <li className="mt-auto">
                  <span
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-base font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-tertiary-600"
                    onClick={navigateStaffEntityManage}
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-tertiary-600"
                      aria-hidden="true"
                    />
                    Manage Entity
                  </span>
                </li>
              </>
            )}
            {initialData?.RoleName === "BRANCH_ADMIN" && (
              <>
                <li className="mt-auto">
                  <span
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-base font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-tertiary-600"
                    onClick={navigateStaffBranchManage}
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-tertiary-600"
                      aria-hidden="true"
                    />
                    Manage Branch
                  </span>
                </li>
              </>
            )}
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default DashboardNavigation;
