import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import moment from "moment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

import { Grid } from "gridjs-react";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";

import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";

import { ValidationError } from "@components/hook-form";
import { Convert } from "./types/Gait";

export const GaitSchema = z.object({
  id: z.union([z.null(), z.string()]).optional(),
  masterAssessmentId: z.union([z.null(), z.string()]).optional(),
  clientId: z.union([z.null(), z.string()]).optional(),
  entityId: z.union([z.null(), z.string()]).optional(),
  entityBranchId: z.union([z.null(), z.string()]).optional(),
  independent: z.union([z.null(), z.string()]).optional(),
  dependent: z.union([z.null(), z.string()]).optional(),
  walkingAid: z.union([z.null(), z.string()]).optional(),
  weightBearingStatus: z.union([z.null(), z.string()]).optional(),
  alteredTrunk: z.union([z.null(), z.string()]).optional(),
  circumduction: z.union([z.null(), z.string()]).optional(),
  hipHiking: z.union([z.null(), z.string()]).optional(),
  alteredHipRoatation: z.union([z.null(), z.string()]).optional(),
  alteredFootContact: z.union([z.null(), z.string()]).optional(),
  alteredBaseOfWalking: z.union([z.null(), z.string()]).optional(),
  initialContact: z.union([z.null(), z.string()]).optional(),
  alteredAnkleAndFootPlacement: z.union([z.null(), z.string()]).optional(),
  midStance: z.union([z.null(), z.string()]).optional(),
  terminalStance: z.union([z.null(), z.string()]).optional(),
  alteredKneeRange: z.union([z.null(), z.string()]).optional(),
  alteredHipRange: z.union([z.null(), z.string()]).optional(),
  heelOff: z.union([z.null(), z.string()]).optional(),
  toeOff: z.union([z.null(), z.string()]).optional(),
  preSwing: z.union([z.null(), z.string()]).optional(),
  initialSwing: z.union([z.null(), z.string()]).optional(),
  midSwing: z.union([z.null(), z.string()]).optional(),
  terminalSwing: z.union([z.null(), z.string()]).optional(),
  alteredTrunkPosition: z.union([z.null(), z.string()]).optional(),
  alteredLumbarLordosis: z.union([z.null(), z.string()]).optional(),
  initiationOfGait: z.union([z.null(), z.string()]).optional(),
  stepLength: z.union([z.null(), z.string()]).optional(),
  stepSymmetry: z.union([z.null(), z.string()]).optional(),
  strideLength: z.union([z.null(), z.string()]).optional(),
  strideDuration: z.union([z.null(), z.string()]).optional(),
  cadence: z.union([z.null(), z.string()]).optional(),
});
export type GaitSchemaType = z.infer<typeof GaitSchema>;

type GaitAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function GaitAssessmentComponent(props: GaitAssessmentProps) {
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const userData = getUserPersistedOnLocalStorage();
  const [lastUpdateAt, setLastUpdatedAt] = React.useState("");
  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<GaitSchemaType>({
    resolver: zodResolver(GaitSchema),
    defaultValues: {
      masterAssessmentId: props.masterAssessmentId,
      entityId: userData?.entity_id,
      clientId: props.patientId,
      entityBranchId: userData?.branch_id,
    },
  });

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getGaitAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const convertedAssessment = Convert.toGait(JSON.stringify(data));

          reset(convertedAssessment);
          setLastUpdatedAt(data["audit"]["lastUpdatedAt"]);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Gait assessment:", error);

          setLoading(false);
        }
      }
    };
    initializeAssessment();
  }, []);

  const navigateToMasterAssessment = (masterAssessmentId: string) => {
    const paramsMap = new Map<string, string>([
      ["patientId", patientId.patientId],
      ["masterAssessmentId", masterAssessmentId],
    ]);
    navigate(
      `${replaceParams(paths.patient.masterAssessment.route, paramsMap)}`
    );
  };

  const onSubmit: SubmitHandler<GaitSchemaType> = async (data: any) => {
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        GaitSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateGaitAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Gait Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    } else {
      try {
        GaitSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createGaitAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Gait Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col md:flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Gait Assessment
        </h3>
        <p className="text-sm text-gray-500">
          {props.assessmentId !== "000000000000000000000000" &&
            `Last updated: ${
              lastUpdateAt &&
              moment(new Date(lastUpdateAt)).format("DD-MM-YYYY") !==
                "01-01-0001"
                ? moment(new Date(lastUpdateAt)).format("DD-MM-YYYY")
                : "N/A"
            }`}
        </p>
      </div>

      <hr className="w-full" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-stretch">
          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "70%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Independent
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("independent")}
                    key={`independent`}
                    name={`independent`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Dependent
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("dependent")}
                    key={`dependent`}
                    name={`dependent`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Walking Aid
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("walkingAid")}
                    key={`walkingAid`}
                    name={`walkingAid`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Weight Bearing Status
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("weightBearingStatus")}
                    key={`weightBearingStatus`}
                    name={`weightBearingStatus`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
            Gait Analysis Form
          </div>
          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
            Anterior & Posterior Assessment
          </div>
          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "70%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Trunk Position/ Lateral Bending/ Roatation
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredTrunkPosition")}
                    key={`alteredTrunkPosition`}
                    name={`alteredTrunkPosition`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Circumduction
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("circumduction")}
                    key={`circumduction`}
                    name={`circumduction`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Hip Hiking
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("hipHiking")}
                    key={`hipHiking`}
                    name={`hipHiking`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Hip Roatation
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredHipRoatation")}
                    key={`alteredHipRoatation`}
                    name={`alteredHipRoatation`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Foot Contact
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredFootContact")}
                    key={`alteredFootContact`}
                    name={`alteredFootContact`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Base Of Walking / Walking Stance
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredBaseOfWalking")}
                    key={`alteredBaseOfWalking`}
                    name={`alteredBaseOfWalking`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
            Lateral Assessment
          </div>
          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "70%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Initial Contact/Heel Strike
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("initialContact")}
                    key={`initialContact`}
                    name={`initialContact`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Ankle And Foot Placement / Range/ Foot Clearance.
                  Loading Response/Foot Flat
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredAnkleAndFootPlacement")}
                    key={`alteredAnkleAndFootPlacement`}
                    name={`alteredAnkleAndFootPlacement`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Mid Stance
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("midStance")}
                    key={`midStance`}
                    name={`midStance`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Terminal Stance
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("terminalStance")}
                    key={`terminalStance`}
                    name={`terminalStance`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Knee Range
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredKneeRange")}
                    key={`alteredKneeRange`}
                    name={`alteredKneeRange`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Hip Range
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredHipRange")}
                    key={`alteredHipRange`}
                    name={`alteredHipRange`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Heel Off</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("heelOff")}
                    key={`heelOff`}
                    name={`heelOff`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Toe off</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("toeOff")}
                    key={`toeOff`}
                    name={`toeOff`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Pre-Swing
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("preSwing")}
                    key={`preSwing`}
                    name={`preSwing`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Initial Swing/Acceleration
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("initialSwing")}
                    key={`initialSwing`}
                    name={`initialSwing`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Mid Swing
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("midSwing")}
                    key={`midSwing`}
                    name={`midSwing`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Terminal Swing/ Deceleration
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("terminalSwing")}
                    key={`terminalSwing`}
                    name={`terminalSwing`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Trunk Position- Anterior/Posterior Bending
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredTrunkPosition")}
                    key={`alteredTrunkPosition`}
                    name={`alteredTrunkPosition`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Altered Lumbar Lordosis
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("alteredLumbarLordosis")}
                    key={`alteredLumbarLordosis`}
                    name={`alteredLumbarLordosis`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
            Other Gait Analysis
          </div>
          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "70%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Initiation of Gait
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("initiationOfGait")}
                    key={`initiationOfGait`}
                    name={`initiationOfGait`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Step Length
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("stepLength")}
                    key={`stepLength`}
                    name={`stepLength`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Step Symmetry
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("stepSymmetry")}
                    key={`stepSymmetry`}
                    name={`stepSymmetry`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Stride Length
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("strideLength")}
                    key={`strideLength`}
                    name={`strideLength`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Stride Duration
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("strideDuration")}
                    key={`strideDuration`}
                    name={`strideDuration`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Cadence</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("cadence")}
                    key={`cadence`}
                    name={`cadence`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="h-20"></div>
        <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
          <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
            <OutlinedButton
              type="button"
              onClick={() => {
                props.postSubmitCallback();
              }}
              className="mr-2"
              variant={ButtonVariant.PRIMARY}
            >
              Close
            </OutlinedButton>
            {isDisabled ? (
              <Button
                type="button"
                key="EditButton"
                onClick={() => {
                  setIsDisabled(false);
                }}
                variant={ButtonVariant.PRIMARY}
              >
                Edit
              </Button>
            ) : props.assessmentId !== "000000000000000000000000" ? (
              <Button
                type="submit"
                key="UpdateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                key="CreateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
