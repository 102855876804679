import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import Applabel from "@components/hook-form/applabel";

import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import { Convert, Therapy } from "../types/Therapy";
import TherapiesAutocompleteInput from "../components/therapies-autocomplete-input";

export interface TherapiesFieldData {
  tid: string;
  therapyName: string;
}

export default function TherapyInformation(props: { entityId: string }) {
  const [therapies, setTherapies] = React.useState([
    { tid: uuidv4(), therapyName: "CRYOTHERAPY" },
    { tid: uuidv4(), therapyName: "HOT PACKS" },
    { tid: uuidv4(), therapyName: "IR" },
    { tid: uuidv4(), therapyName: "UVR" },
    { tid: uuidv4(), therapyName: "PARAFFIN WAX BATH" },
    { tid: uuidv4(), therapyName: "DEEP HEATING MODALITIES" },
    { tid: uuidv4(), therapyName: "SWD" },
    { tid: uuidv4(), therapyName: "ULTRASOUND" },
    { tid: uuidv4(), therapyName: "TRACTION" },
    { tid: uuidv4(), therapyName: "MASSAGE" },
    { tid: uuidv4(), therapyName: "HYDROTHERAPY" },
    { tid: uuidv4(), therapyName: "TENS" },
    { tid: uuidv4(), therapyName: "LASER" },
    { tid: uuidv4(), therapyName: "IFT" },
    { tid: uuidv4(), therapyName: "EMS" },
    { tid: uuidv4(), therapyName: "DRY NEEDLING" },
    { tid: uuidv4(), therapyName: "CUPPING" },
    { tid: uuidv4(), therapyName: "TAPING" },
  ]);

  const [selectedTherapies, setSelectedTherapies] = React.useState<
    TherapiesFieldData[]
  >([]);
  const [selectedTherapy, setSelectedTherapy] =
    React.useState<TherapiesFieldData>(therapies[0]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const getEntityData = async () => {
      try {
        const paramsMap = new Map<string, string>([["id", props.entityId]]);
        const filteredEndpoint = replaceParams(
          endpoints.entity.therapy,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];
        if (data != null) {
          const convertedTherapies = Convert.toTherapy(JSON.stringify(data));
          setSelectedTherapies(convertedTherapies);
          // below code is to filter the dropdown list such that it should not include therapies that are already seleted
          const selectedTherapyNames: string[] = convertedTherapies.map(
            (therapy) => therapy.therapyName
          );
          const filteredTherapies: Therapy[] = therapies.filter(
            (therapy) => !selectedTherapyNames.includes(therapy.therapyName)
          );
          setTherapies(filteredTherapies);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.message);

        console.error("Error fetching staff data:", error);

        setLoading(false);
      }
    };
    getEntityData();
  }, []);

  function removeTherapyFromSelectedList(therapy: TherapiesFieldData): void {
    const thera = selectedTherapies.filter((th) => {
      return therapy.tid !== th.tid;
    });
    setSelectedTherapies(thera);

    const toSet = [...therapies, therapy];
    setTherapies(toSet);
  }

  function addTherapyToSelectedList(therapy: TherapiesFieldData): void {
    setSelectedTherapies([...selectedTherapies, therapy]);
    const filteredDropDownTherapy = therapies.filter((dropdownTherapy) => {
      return dropdownTherapy.therapyName !== therapy.therapyName;
    });
    setTherapies(filteredDropDownTherapy);
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      const paramsMap = new Map<string, string>([["id", props.entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.therapy,
        paramsMap
      );

      const response = await axios.put(filteredEndpoint, selectedTherapies);
      if (response.data.status === 200)
        toast.success(
          response.data.message === "success"
            ? "Therapies submitted successfully"
            : response.data.message
        );

      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Applabel label="Select Treatment Modalities (Therapies) " mandatory />
      <div className="w-full flex flex-row justify-start items-center">
        <TherapiesAutocompleteInput
          fieldDataList={therapies}
          fieldName="Therapy "
          addTherapyTo={addTherapyToSelectedList}
          selectedTherapies={selectedTherapies}
        />
      </div>
      <table className="w-full xl:w-1/3 md:w-full divide-y divide-gray-300 ">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Treatment Modalities Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {selectedTherapies.map((therapy) => (
            <tr key={therapy.tid}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 ">
                {therapy.therapyName}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-center">
                <button
                  type="button"
                  className=" h-8 px-2 text-sm text-primary-500 placeholder-primary-500 border hover:border-gray-400 rounded-lg focus:shadow-outline"
                  onClick={() => removeTherapyFromSelectedList(therapy)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-4 md:-ml-6 lg:-ml-8 shadow bg-gray-100">
        <Button
          variant={ButtonVariant.PRIMARY}
          type="button"
          className="lg:ml-3"
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
