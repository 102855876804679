import { endpoints, replaceParams } from "@utils/axios";
import React, { Fragment, useEffect } from "react";
import {
  Convert,
  PersonalInformationDetails,
} from "../types/PatientPersonalDetails";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EditPersonalInformation from "./EditPersonalInformation";
import SolidButton from "@components/button/button";
import Modal from "@components/modal";
import Button from "@components/button/outline-button";
import { ButtonVariant } from "@components/button/button";

import FeedbackRating from "./FeedbackRating";
import RightOffcanvas from "@components/right-offcanvas";
import Reports from "./Reports";
import ImportantNote from "./ImportantNote";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import PatientPaymentDetails from "./PatientPaymentDetails";
import PatientJournal from "./PatientJournal";
import ProgressTracker from "./ProgressTracker";
import { OutlinedButton } from "@components/button";
import Billing from "@pages/manage-patient-new/view/Billing";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
interface DummyProfilePictureContainerProps {
  selectedImage: string;
  setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
  patientId: string;
}
function DummyProfilePictureContainer({
  selectedImage,
  setSelectedImage,
  patientId,
}: DummyProfilePictureContainerProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState<string>("");
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size <= 1024 * 1024) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result as string);
        };
        reader.readAsDataURL(file);
        const formData = new FormData();
        formData.append("file", file);
        try {
          const paramsMap = new Map<string, string>([["patientId", patientId]]);
          const filteredEndpoint = replaceParams(
            endpoints.patient.updateProfilePicture,
            paramsMap
          );
          axios.put(filteredEndpoint, formData).then(async (response) => {
            if (response.status === 200) {
              toast.success(
                response.data.message === "success"
                  ? "File upload successfully"
                  : response.data.message
              );
            }
          });
        } catch (e) {}
      } else {
        toast.error("File size exceeds the limit of 1MB");
      }
    }
  };
  useEffect(() => {
    setDisplayImage(selectedImage);
  }, [selectedImage]);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="flex flex-col justify-between">
      {displayImage != "" ? (
        <div className="w-full h-24 bg-gray-300 rounded-lg overflow-hidden border border-gray-300 hover:border-secondary-500 cursor-pointer">
          <img
            src={displayImage}
            alt="Profile"
            className="w-full h-full object-cover object-center "
            onClick={openModal}
          />
        </div>
      ) : (
        <div className="w-full h-24 flex items-center justify-center bg-gray-300 rounded-lg border border-gray-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="100%"
            viewBox="0 0 448 512"
            className="text-gray-500 p-2"
          >
            <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
          </svg>
        </div>
      )}

      <label
        htmlFor="imageInput"
        className="mt-2 w-full cursor-pointer text-center text-secondary-500 border border-gray-400 p-1 text-sm rounded justify-center"
      >
        Change
      </label>

      <input
        type="file"
        id="imageInput"
        accept="image/*"
        className="hidden"
        onChange={handleImageChange}
      />
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto "
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            <div className="inline-block align-middle my-16 p-4 w-full max-w-lg text-left overflow-hidden shadow-xl transform transition-all bg-white">
              <img
                src={displayImage}
                alt="Profile"
                className="w-full h-full object-cover rounded-lg"
              />
              <button
                className="absolute top-0 right-1 m-4 text-xl cursor-pointer"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
export default function PatientInfoCard(props: { patientId: string }) {
  const userData = getUserPersistedOnLocalStorage();
  const [patientPersonalInformation, setPatientPersonalInformation] =
    React.useState<PersonalInformationDetails | null>(null);
  const [editPersonalInformationDialog, setEditPersonalInformationDialog] =
    React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isRightOffcanvasOpen, setIsRightOffcanvasOpen] = React.useState(false);
  const [isRightOffcanvasOpenToFeedback, setIsRightOffcanvasOpenToFeedback] =
    React.useState(false);
  const [isRightOffcanvasOpenToJournal, setIsRightOffcanvasOpenToJournal] =
    React.useState(false);
  const [
    isRightOffcanvasOpenToProgressTracker,
    setIsRightOffcanvasOpenToProgressTracker,
  ] = React.useState(false);
  const [isRightOffcanvasOpenToReport, setIsRightOffcanvasOpenToReport] =
    React.useState(false);
  const [
    isRightOffcanvasOpenToMakePayment,
    setIsRightOffcanvasOpenToMakePayment,
  ] = React.useState(false);
  const [isRightOffcanvasOpenToBilling, setIsRightOffcanvasOpenToBilling] =
    React.useState(false);
  const [isImportantNoteModalOpen, setIsImportantNoteModalOpen] =
    React.useState(false);
  const handleCloseImportantNoteModal = () =>
    setIsImportantNoteModalOpen(false);
  const handleOpenImportantNoteModal = () => setIsImportantNoteModalOpen(true);
  const [selectedImage, setSelectedImage] = React.useState<string>("");
  const getPatientData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["patientId", props.patientId],
      ]);
      const filteredEndpoint = replaceParams(endpoints.patient.view, paramsMap);
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      const convertedPersonalInformation = Convert.toPersonalInformation(
        JSON.stringify(data)
      );
      setPatientPersonalInformation(convertedPersonalInformation);
      if (convertedPersonalInformation.profilePictureUrl === "") {
        setSelectedImage("");
      } else {
        setSelectedImage(convertedPersonalInformation.profilePictureUrl);
      }
    } catch (error) {
      console.error("Error getting patient information", error);
    }
  };
  React.useEffect(() => {
    const _init = async () => {
      await getPatientData();
    };
    //This is to get the patient data
    _init();
  }, []);

  const handleCloseRightOffCanvas = () => {
    setIsRightOffcanvasOpenToFeedback(false);
    setIsRightOffcanvasOpenToJournal(false);
    setIsRightOffcanvasOpenToProgressTracker(false);
    setIsRightOffcanvasOpenToReport(false);
    setIsRightOffcanvasOpenToMakePayment(false);
    setIsRightOffcanvasOpenToBilling(false);
  };
  return (
    <>
      <Modal
        isOpen={isImportantNoteModalOpen}
        onClose={handleCloseImportantNoteModal}
      >
        <ImportantNote
          handleCloseImportatntNoteModal={handleCloseImportantNoteModal}
          notes={patientPersonalInformation?.notes ?? ""}
          patientId={props.patientId}
          getPatientData={getPatientData}
        />
      </Modal>
      <RightOffcanvas
        isOpen={isRightOffcanvasOpenToFeedback}
        onClose={handleCloseRightOffCanvas}
      >
        {isRightOffcanvasOpenToFeedback && (
          <FeedbackRating
            handleCloseRightOffCanvas={handleCloseRightOffCanvas}
            patientId={props.patientId}
          />
        )}
      </RightOffcanvas>
      <RightOffcanvas
        isOpen={isRightOffcanvasOpenToJournal}
        onClose={handleCloseRightOffCanvas}
      >
        {isRightOffcanvasOpenToJournal && (
          <PatientJournal
            handleCloseRightOffCanvas={handleCloseRightOffCanvas}
            patientId={props.patientId}
          />
        )}
      </RightOffcanvas>
      <RightOffcanvas
        isOpen={isRightOffcanvasOpenToReport}
        onClose={handleCloseRightOffCanvas}
      >
        {isRightOffcanvasOpenToReport && (
          <Reports
            patientId={props.patientId}
            onClose={handleCloseRightOffCanvas}
          />
        )}
      </RightOffcanvas>
      <RightOffcanvas
        isOpen={isRightOffcanvasOpenToMakePayment}
        onClose={handleCloseRightOffCanvas}
      >
        {isRightOffcanvasOpenToMakePayment && (
          <PatientPaymentDetails
            patientId={props.patientId}
            onClose={handleCloseRightOffCanvas}
            patientPersonalInformation={patientPersonalInformation}
          />
        )}
      </RightOffcanvas>
      <RightOffcanvas
        isOpen={isRightOffcanvasOpenToBilling}
        onClose={handleCloseRightOffCanvas}
      >
        {isRightOffcanvasOpenToBilling && (
          <Billing
            patientId={props.patientId}
            onClose={handleCloseRightOffCanvas}
            patientPersonalInformation={patientPersonalInformation}
          />
        )}
      </RightOffcanvas>
      <RightOffcanvas
        isOpen={isRightOffcanvasOpenToProgressTracker}
        onClose={handleCloseRightOffCanvas}
      >
        {isRightOffcanvasOpenToProgressTracker && (
          <ProgressTracker
            handleCloseRightOffCanvas={handleCloseRightOffCanvas}
            patientId={props.patientId}
            isPopup={true}
          />
        )}
      </RightOffcanvas>
      <div className="hidden md:flex flex-col w-full">
        <div className="flex flex-col w-full">
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row space-x-8 pr-4">
              <DummyProfilePictureContainer
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                patientId={props.patientId}
              />
              <div className="flex flex-col justify-start text-sm ">
                <h3 className="text-xl text-gray-900 font-medium">
                  {patientPersonalInformation?.firstName}{" "}
                  {patientPersonalInformation?.lastName}
                </h3>
                <div className="flex flex-row space-x-2">
                  <div className="hover:underline hover:text-secondary-500 cursor-pointer">
                    +{patientPersonalInformation?.phoneCode}{" "}
                    {userData?.user_role === "ENTITY_OWNER" ||
                    userData?.user_role === "PLATFORM_ADMIN"
                      ? `${patientPersonalInformation?.phone}`
                      : `${
                          patientPersonalInformation?.phone != null
                            ? patientPersonalInformation.phone.toString()
                                .length > 4
                              ? patientPersonalInformation?.phone
                              : `******${patientPersonalInformation?.phone}`
                            : ""
                        }`}
                  </div>
                  <div className="divider divider-horizontal font-bold">
                    {patientPersonalInformation?.email ? "|" : ""}
                  </div>
                  <div className="hover:underline hover:text-secondary-500 cursor-pointer">
                    {patientPersonalInformation?.email}
                  </div>
                  {/* Patient ID */}
                  <div className="divider divider-horizontal font-bold">
                    <span className="font-bold">Patient ID:</span>
                    {patientPersonalInformation?.serialNo ?? ""}
                  </div>
                </div>
                <div>{patientPersonalInformation?.address.address}</div>
                <div>
                  {patientPersonalInformation?.address.city}
                  {patientPersonalInformation?.address.city ? ", " : ""}
                  {patientPersonalInformation?.address.state}
                  {patientPersonalInformation?.address.state ? ", " : ""}
                  {patientPersonalInformation?.address.country}
                </div>
                <div className="mt-5">
                  <label
                    onClick={() => setEditPersonalInformationDialog(true)}
                    className="mt-2 w-full cursor-pointer text-center text-secondary-500 border border-gray-400 py-1 px-4 text-sm rounded justify-center"
                  >
                    Edit Profile
                  </label>
                  {/*                   <OutlinedButton
                    variant={ButtonVariant.TERTIARY}
                    onClick={() => setEditPersonalInformationDialog(true)}
                    className='mr-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                    </svg>
                    Edit Profile
                  </OutlinedButton> */}
                </div>
              </div>
            </div>
            <div className="flex flex-col px-4 space-y-2">
              {patientPersonalInformation?.notes !== "" ? (
                <button
                  type="button"
                  onClick={handleOpenImportantNoteModal}
                  className="inline-flex items-center rounded-lg text-sm px-1 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-2 lg:py-2 lg:font-medium focus:outline-none transition ease-in-out duration-150 hover:shadow bg-red-50 hover:bg-red-100 focus:ring-red-500 focus:ring-offset-red-200 text-red shadow-sm border border-red-600  whitespace-nowrap"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                    />
                  </svg>

                  <span className="pl-1 text-sm">Important Note</span>
                </button>
              ) : (
                <OutlinedButton
                  type="button"
                  onClick={handleOpenImportantNoteModal}
                  variant={ButtonVariant.PRIMARY}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                    />
                  </svg>
                  <span className="pl-1 text-sm"> Important Note</span>
                </OutlinedButton>
              )}
              <OutlinedButton
                variant={ButtonVariant.SECONDARY}
                onClick={() => setIsRightOffcanvasOpenToMakePayment(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#5f6368"
                >
                  <path d="M360-320q-17 0-28.5-11.5T320-360v-80q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520v-80q0-17 11.5-28.5T360-640h240q17 0 28.5 11.5T640-600v80q-17 0-28.5 11.5T600-480q0 17 11.5 28.5T640-440v80q0 17-11.5 28.5T600-320H360Zm120-60q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h400q33 0 56.5 23.5T760-840v720q0 33-23.5 56.5T680-40H280Zm0-120v40h400v-40H280Zm0-80h400v-480H280v480Zm0-560h400v-40H280v40Zm0 0v-40 40Zm0 640v40-40Z" />
                </svg>

                <span className="pl-1 text-sm">Appointment Details</span>
              </OutlinedButton>
              <OutlinedButton
                variant={ButtonVariant.SECONDARY}
                onClick={() => setIsRightOffcanvasOpenToBilling(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                  />
                </svg>

                <span className="pl-1 text-sm">Billing Details</span>
              </OutlinedButton>
            </div>
          </div>
        </div>
        <hr className="my-2" />
        <div className="flex flex-row w-full space-x-2 py-1 ">
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => setIsRightOffcanvasOpenToReport(true)}
            className=""
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
              />
            </svg>
            <span className="pl-1 text-sm">Reports</span>
          </Button>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => setIsRightOffcanvasOpenToFeedback(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
              />
            </svg>

            <span className="pl-1 text-sm"> Feedback</span>
          </Button>
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={() => setIsRightOffcanvasOpenToJournal(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>

            <span className="pl-1 text-sm">Journal </span>
          </Button>
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={() => setIsRightOffcanvasOpenToProgressTracker(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
              />
            </svg>

            <span className="pl-1 text-sm"> Progress Tracker </span>
          </Button>
        </div>
        <hr className="my-2" />
      </div>
      <div className="flex md:hidden w-full">
        <div className="flex flex-col items-center pb-10">
          <DummyProfilePictureContainer
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            patientId={props.patientId}
          />
          <h5 className="my-1 text-xl font-bold text-gray-900 dark:text-white">
            {patientPersonalInformation?.firstName}{" "}
            {patientPersonalInformation?.lastName}
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            +{patientPersonalInformation?.phoneCode}{" "}
            {userData?.user_role === "ENTITY_OWNER" ||
            userData?.user_role === "PLATFORM_ADMIN"
              ? `${patientPersonalInformation?.phone}`
              : `${
                  patientPersonalInformation?.phone != null
                    ? patientPersonalInformation.phone.toString().length > 4
                      ? patientPersonalInformation?.phone
                      : `******${patientPersonalInformation?.phone}`
                    : ""
                }`}
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {patientPersonalInformation?.email}
          </span>
          <div className="flex flex-row items-center mt-4">
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToReport(true)}
            >
              <span className="material-symbols-outlined">
                document_scanner
              </span>
            </a>

            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToFeedback(true)}
            >
              <span className="material-symbols-outlined">description</span>
            </a>
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToJournal(true)}
            >
              <span className="material-symbols-outlined">forum</span>
            </a>
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToProgressTracker(true)}
            >
              <span className="material-symbols-outlined">leaderboard</span>
            </a>
            <a className="mr-4" onClick={handleOpenImportantNoteModal}>
              <span className="material-symbols-outlined">clinical_notes</span>
            </a>
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToMakePayment(true)}
            >
              <span className="material-symbols-outlined">book_online</span>
            </a>
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToBilling(true)}
            >
              <span className="material-symbols-outlined">payments</span>
            </a>

            <a
              className="mr-4"
              onClick={() => setEditPersonalInformationDialog(true)}
            >
              <span className="material-symbols-outlined">edit</span>
            </a>
          </div>
        </div>
      </div>
      {/* <hr className="my-2" /> */}
      {editPersonalInformationDialog && (
        <EditPersonalInformation
          onClose={() => setEditPersonalInformationDialog(false)}
          setAddPaymentDetails={setEditPersonalInformationDialog}
          getPatientData={getPatientData}
        />
      )}
    </>
  );
}
