import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  InvoiceDetails,
  ConvertBilling,
  LineItem,
} from "../types/BillingDetails";
import moment from "moment";
import BillingItem from "./BillingItem";
import axios, { CancelTokenSource } from "axios";
import { endpoints, replaceParams } from "@utils/axios";
import { Appointment, Convert } from "@pages/appointments/types/Appointment";
import UnbilledAppointments from "./UnbilledAppointments";
import { ButtonVariant } from "@components/button/button";
import { Button, OutlinedButton } from "@components/button";
import * as z from "zod";
import Applabel from "@components/hook-form/applabel";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ValidationError } from "@components/hook-form";
import TextAreaField from "@components/hook-form/textarea-field";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { toast } from "react-toastify";
import { CURRENCY_SYMBOL } from "../../../config/currencyConfig";

export default function BillDetailsPage(props: {
  bill: InvoiceDetails;
  onClose: () => void;
  getPatientBillingDetails?: () => Promise<void>;
}) {
  const [formData, setFormData] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm<PaymentDetailsSchemaType>({
    resolver: zodResolver(PaymentDetailsSchema),
  });

  const methods = useForm<BillItemSchemaType>({
    resolver: zodResolver(BillItemSchema),
  });
  const errors1 = methods.formState.errors;

  const [selectedStatus, setSelectedStatus] = React.useState("pending"); // Initialize the selected status

  const handleStatusChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedStatus(event.target.value); // Update the selected status when the user selects an option
  };

  // Function to determine the border color based on the selected status
  const getTextColor = () => {
    if (selectedStatus === "partlyPaid") {
      return "text-warning-500"; // Red border for 'pending' status
    } else if (selectedStatus === "paid") {
      return "text-success-500"; // Green border for 'paid' status
    } else {
      return "text-red-500"; // Default gray border
    }
  };

  const [itemsList, setItemsList] = React.useState<LineItem[]>(
    props.bill.items
  );
  const [taxesPercentage, setTaxesPercentage] = React.useState<number>(0);
  const [taxAmount, setTaxAmount] = React.useState<number>(0);

  const [subTotal, setSubTotal] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [billDetails, setBillDetails] = React.useState<InvoiceDetails>(
    props.bill
  );

  useEffect(() => {
    setItemsList(billDetails.items);
    getUnbilledAppointments();
    setSelectedStatus(billDetails.status);
    setSelectedDate(moment(billDetails.invoiceDate).format("YYYY-MM-DD"));
    if (billDetails.tax > 0) {
      setTaxesPercentage(billDetails.tax);
    }
  }, []);

  const updateSubTotal = (itemsList: LineItem[]) => {
    if (itemsList && itemsList.length > 0) {
      const newSubTotal = itemsList.reduce((acc, item) => {
        if (item.amount !== undefined) {
          return acc + item.amount;
        }
        return acc;
      }, 0);
      setSubTotal(newSubTotal);
      // Since setSubTotal is asynchronous, use the newSubTotal value directly
      const taxesAmount = (newSubTotal * taxesPercentage) / 100;
      setTaxAmount(taxesAmount);
      setTotal(newSubTotal + taxesAmount);
    }
  };

  useEffect(() => {
    if (itemsList && itemsList.length > 0) {
      const newSubTotal = itemsList.reduce((acc, item) => {
        if (item.amount !== undefined) {
          return acc + item.amount;
        }
        return acc;
      }, 0);
      setSubTotal(newSubTotal);
    }
  }, [itemsList]);

  useEffect(() => {
    const taxesAmount = (subTotal * taxesPercentage) / 100;
    setTotal(subTotal + taxesAmount);
    setTaxAmount(taxesAmount);
  }, [taxesPercentage, subTotal]);

  const [includeTax, setIncludeTax] = React.useState(false);

  const handleTaxToggle = () => {
    setIncludeTax(!includeTax); // Toggle the state between true and false
  };

  const [patientAppointments, setPatientAppointments] = React.useState<
    Appointment[]
  >([]);

  const [totalAppointemnts, setTotalAppointments] = React.useState(0);

  const [isApptModelOpen, setIsApptModelOpen] = React.useState(false);

  const [hasErrors, setHasErrors] = useState(false);

  const handleApptModalToggle = () => {
    if (isApptModelOpen) {
      getBillDetails();
    }
    setIsApptModelOpen(!isApptModelOpen); // Toggle the state between true and false
  };

  const getUnbilledAppointments = async () => {
    if (billDetails.clientId) {
      const paramsMap = new Map<string, string>([["id", billDetails.clientId]]);
      const filteredEndpoint = replaceParams(
        endpoints.patient.getUnbilledAppointments,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);

      const data = response.data["data"];
      const convertedAppointmentList = data.map((appointment: any) => {
        return Convert.toAppointment(JSON.stringify(appointment));
      });

      setTotalAppointments(convertedAppointmentList.length);
      setPatientAppointments(convertedAppointmentList);
    }
  };

  const getBillDetails = async () => {
    try {
      if (billDetails.id) {
        const paramsMap = new Map<string, string>([["id", billDetails.id]]);
        const endpoint = replaceParams(
          endpoints.invoice.getBillDetails,
          paramsMap
        );
        const response = await axios.get(endpoint);
        const data = response.data["data"];
        if (data !== null) {
          var billRecord = ConvertBilling.toBilling(JSON.stringify(data));
          setBillDetails(billRecord);
          updateSubTotal(billRecord.items);
          setItemsList(billRecord.items);
        }
      }
    } catch (error) {
      console.error("Error fetching expenses details:", error);
    }
  };
  const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
  const cancelToken = React.useRef<CancelTokenSource | null>(null);

  const onSubmit: SubmitHandler<BillItemSchemaType> = async (data) => {
    if (postInProgress) return;
    trigger();

    if (cancelToken.current) {
      cancelToken.current.cancel("Request already in progress!");
    }
    cancelToken.current = axios.CancelToken.source();

    try {
      setPostInProgress(true);
      const combinedData = { ...formData, ...data };
      PaymentDetailsSchema.parse(combinedData);
      const userData = getUserPersistedOnLocalStorage();

      if (props.bill && props.bill.id) {
        const paramsMap = new Map<string, string>([["id", props.bill.id]]);
        const filteredEndpoint = replaceParams(
          endpoints.invoice.lineItem,
          paramsMap
        );

        await axios.post(
          filteredEndpoint,
          {
            amount: Number(data.amount),
            description: data.description,
            quantity: Number(data.quantity),
            itemId: uuidv4(),
            invoiceDate: selectedDate,
          },
          {
            cancelToken: cancelToken.current.token,
          }
        );
      } else {
        const filteredEndpoint = endpoints.invoice.addBilling;
        props.bill.items.push({
          amount: Number(data.amount),
          description: data.description,
          quantity: Number(data.quantity),
          itemId: uuidv4(),
          appointments: [],
        });
        props.bill.totalAmount = Number(data.amount);
        props.bill.outStandingAmount = Number(data.amount);
        props.bill.invoiceDate = new Date(selectedDate);

        const response = await axios.post(filteredEndpoint, props.bill, {
          cancelToken: cancelToken.current.token,
        });

        if (response.data.status === 200) {
          billDetails.id = response.data.data;
        }
      }
      setPostInProgress(false);
      methods.reset();
      getBillDetails();
      setAddItemVisible(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      setPostInProgress(false);
    }
  };

  const onPaymentSubmit: SubmitHandler<PaymentDetailsSchemaType> = async (
    data
  ) => {
    if (postInProgress) return;

    if (Number(data.amount) <= 0) {
      setError("amount", {
        message: "Please enter amount",
      });
      return;
    }

    trigger();
    if (cancelToken.current) {
      cancelToken.current.cancel("Request already in progress!");
    }
    cancelToken.current = axios.CancelToken.source();

    try {
      setPostInProgress(true);
      const combinedData = { ...formData, ...data };
      PaymentDetailsSchema.parse(combinedData);
      const userData = getUserPersistedOnLocalStorage();
      if (props.bill && props.bill.id) {
        const paramsMap = new Map<string, string>([["id", props.bill.id]]);
        const filteredEndpoint = replaceParams(
          endpoints.invoice.payBill,
          paramsMap
        );

        await axios.post(
          filteredEndpoint,
          {
            amountPaid: Number(data.amount),
            paymentMode: data.paymentMode,
            note: data.note,
            paymentDate: new Date(),
            transactionId: uuidv4(),
            audit: {
              createdBy: userData?.user_id,
              lastUpdatedBy: userData?.user_id,
              createdAt: new Date(),
              lastUpdatedAt: new Date(),
            },
          },
          {
            cancelToken: cancelToken.current.token,
          }
        );
      }
      if (props.getPatientBillingDetails) props.getPatientBillingDetails();
      setPostInProgress(false);
      reset();
      getBillDetails();
    } catch (error) {
      console.error("Error submitting form:", error);
      setPostInProgress(false);
    }
  };
  const [addItemVisible, setAddItemVisible] = React.useState<boolean>(false);

  const handleRemove = async (itemId: string) => {
    if (postInProgress) return;
    trigger();

    if (cancelToken.current) {
      cancelToken.current.cancel("Request already in progress!");
    }
    cancelToken.current = axios.CancelToken.source();

    try {
      setPostInProgress(true);
      if (props.bill && props.bill.id) {
        const paramsMap = new Map<string, string>([
          ["id", props.bill.id],
          ["itemId", itemId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.invoice.removeLineItem,
          paramsMap
        );
        await axios.put(
          filteredEndpoint,
          {},
          { cancelToken: cancelToken.current.token }
        );
      }
      setPostInProgress(false);
      reset();
      getBillDetails();
    } catch (error) {
      console.error("Error submitting form:", error);
      setPostInProgress(false);
    }
  };

  const updateTaxAmount = async () => {
    if (postInProgress) return;
    trigger();

    if (cancelToken.current) {
      cancelToken.current.cancel("Request already in progress!");
    }
    cancelToken.current = axios.CancelToken.source();

    try {
      setPostInProgress(true);
      if (props.bill && props.bill.id) {
        const paramsMap = new Map<string, string>([["id", props.bill.id]]);
        const filteredEndpoint = replaceParams(
          endpoints.invoice.addTax,
          paramsMap
        );
        await axios.post(
          filteredEndpoint,
          { id: props.bill.id, tax: taxesPercentage, totalAmount: total },
          { cancelToken: cancelToken.current.token }
        );
      }
      setPostInProgress(false);
      reset();
      getBillDetails();
    } catch (error) {
      console.error("Error submitting form:", error);
      setPostInProgress(false);
    }
  };

  const handlePrint = async () => {
    try {
      if (billDetails.id) {
        const paramsMap = new Map<string, string>([["id", billDetails.id]]);
        const endpoint = replaceParams(
          endpoints.invoice.printBillDetails,
          paramsMap
        );
        const response = await axios.get(endpoint);
        let htmlContent = response.data;

        const blob = new Blob([htmlContent], { type: "text/html" });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.target = "_blank";
        anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
      }
    } catch (error) {
      console.error("Error in printing", error);
    }
  };

  const [selectedDate, setSelectedDate] = React.useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setSelectedDate(e.target.value);
      billDetails.invoiceDate = new Date(selectedDate);

      if (postInProgress) return;
      trigger();

      if (cancelToken.current) {
        cancelToken.current.cancel("Request already in progress!");
      }
      cancelToken.current = axios.CancelToken.source();

      try {
        setPostInProgress(true);
        if (props.bill && props.bill.id) {
          const paramsMap = new Map<string, string>([["id", props.bill.id]]);
          const filteredEndpoint = replaceParams(
            endpoints.invoice.updateBillingDate,
            paramsMap
          );

          axios
            .put(
              filteredEndpoint,
              { id: props.bill.id, invoiceDate: new Date(e.target.value) },
              { cancelToken: cancelToken.current.token }
            )
            .then((res) => {
              if (res.status === 200) {
                toast.success(
                  res.data.message === "success"
                    ? "Invoice date updated successfully"
                    : res.data.message
                );
              }
            })
            .catch((error) => {
              //console.error('Errors : ', error);
              toast.error(error.message);
            });
        }
        setPostInProgress(false);
        reset();
        getBillDetails();
      } catch (error) {
        console.error("Error submitting form:", error);
        setPostInProgress(false);
      }
    }
  };

  return (
    <div className="flex flex-row  w-full">
      {!isApptModelOpen && (
        <div className={`flex flex-col  w-full pr-2`}>
          <div className="flex flex-col sm:flex-row items-center justify-between">
            <div className="min-w-0 flex-1">
              <h3 className="font-bold leading-7 sm:truncate sm:tracking-tight m-3">
                Billing Details
              </h3>
            </div>
            <div className="min-w-0 flex-1">
              <h5 className=" font-semibold leading-7 sm:truncate sm:tracking-tight mr-2">
                {props.bill.clientName}
              </h5>
            </div>
            <div className="flex mt-4 flex-shrink-0 md:ml-4 md:mt-0 justify-end">
              <OutlinedButton
                type="button"
                onClick={handlePrint}
                className="mr-2"
                variant={ButtonVariant.PRIMARY}
              >
                Print
              </OutlinedButton>
              <OutlinedButton
                type="button"
                onClick={props.onClose}
                className="mr-2"
                variant={ButtonVariant.PRIMARY}
              >
                Close
              </OutlinedButton>
            </div>
          </div>
          <div className="flex flex-col m-3 border border-gray-400 shadow rounded-lg bg-white w-full">
            <div className="flex flex-col md:flex-row md:justify-between md:space-x-1 p-4 bg-gray-200">
              <div className="flex flex-col md:w-1/2">
                <h5 className="font-bold">Invoice Number</h5>
                <span className="font-light">{billDetails.invoiceNumber}</span>
              </div>
              <div className="flex flex-col md:w-1/2">
                <h5 className="font-bold">Invoice Date</h5>
                <span className="font-light">
                  <input
                    className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  {/*          {moment(billDetails.invoiceDate)
                    .format("DD-MM-YYYY")
                    .toString()} */}
                </span>
              </div>
              {billDetails.status !== "paid" && (
                <div className="flex flex-col md:w-1/2">
                  <Button
                    variant={
                      totalAppointemnts > 0
                        ? ButtonVariant.SECONDARY
                        : ButtonVariant.GRAY
                    }
                    type="button"
                    className="w-fit self-end"
                    onClick={() => {
                      handleApptModalToggle();
                    }}
                    isDisabled={totalAppointemnts <= 0}
                  >
                    Unbilled appointments: {totalAppointemnts}
                  </Button>
                </div>
              )}
            </div>
            <div className="font-bold text-gray-800 text-base p-4 m-2">
              Item details:
            </div>
            <div className="w-full">
              {itemsList &&
                itemsList.map((item) => (
                  <div className="flex flex-col border-b mx-2">
                    <div className="flex flex-col md:flex-row w-full md:justify-between p-4">
                      <div className="flex flex-col md:flex-row md:justify-between w-full md:space-x-2">
                        <div className="flex flex-col md:w-3/5">
                          <label className="pb-2">Description</label>
                          <label className=" pr-5 border-gray-200">
                            {item.description}
                          </label>
                        </div>
                        <div className="flex flex-col md:w-1/5">
                          <label className="pb-2">Quantity</label>
                          <label className=" pr-5 border-gray-200">
                            {item.quantity}
                          </label>
                        </div>
                        <div className="flex flex-col md:w-1/5">
                          <label className="pb-2">Total</label>
                          <label className=" pr-5 border-gray-200">
                            {item.amount}
                          </label>
                        </div>
                        <div className="flex flex-col justify-center md:w-1/5">
                          {!(
                            item.appointments && item.appointments.length > 0
                          ) &&
                            billDetails.status !== "paid" &&
                            billDetails.status !== "partlyPaid" && (
                              <button
                                className="custom-button text-red-300"
                                type="button"
                                onClick={() => {
                                  handleRemove(item.itemId);
                                }}
                              >
                                <span className="icon-container">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    className="component-iconify MuiBox-root css-1t9pz9x w-6 h-6 iconify iconify--solar"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M3 6.386c0-.484.345-.877.771-.877h2.665c.529-.016.996-.399 1.176-.965l.03-.1l.115-.391c.07-.24.131-.45.217-.637c.338-.739.964-1.252 1.687-1.383c.184-.033.378-.033.6-.033h3.478c.223 0 .417 0 .6.033c.723.131 1.35.644 1.687 1.383c.086.187.147.396.218.637l.114.391l.03.1c.18.566.74.95 1.27.965h2.57c.427 0 .772.393.772.877s-.345.877-.771.877H3.77c-.425 0-.77-.393-.77-.877"
                                    ></path>
                                    <path
                                      fill="currentColor"
                                      fill-rule="evenodd"
                                      d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886c.904-.886.996-2.339 1.181-5.245l.267-4.188c.1-1.577.15-2.366-.303-2.865c-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5c-.454.5-.404 1.288-.303 2.865l.267 4.188c.185 2.906.277 4.36 1.182 5.245c.905.886 2.296.886 5.079.886m-1.35-9.811c-.04-.434-.408-.75-.82-.707c-.413.043-.713.43-.672.864l.5 5.263c.04.434.408.75.82.707c.413-.043.713-.43.672-.864zm4.329-.707c.412.043.713.43.671.864l-.5 5.263c-.04.434-.409.75-.82.707c-.413-.043-.713-.43-.672-.864l.5-5.263c.04-.434.409-.75.82-.707"
                                      clip-rule="evenodd"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {addItemVisible && billDetails.status !== "paid" && (
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="flex flex-col border-b m-5">
                    <div className="flex flex-col md:flex-row w-full md:justify-between p-4">
                      <div className="flex flex-col md:flex-row md:justify-between w-full md:space-x-2">
                        <div className="flex flex-col md:w-3/5">
                          <label className="pb-2">Description</label>
                          <input
                            {...methods.register("description")}
                            type="text"
                            placeholder="Description"
                            className="border p-2 rounded-lg border-gray-400"
                            onChange={(e) => {
                              if (e.target.value === "") {
                                methods.setError("description", {
                                  message: "Please add description",
                                });
                              } else {
                                methods.clearErrors("description");
                                methods.setValue("description", e.target.value);
                              }
                            }}
                          />
                          {errors1.description && (
                            <ValidationError
                              message={
                                errors1.description?.message?.toString() ?? ""
                              }
                            />
                          )}
                        </div>
                        <div className="flex flex-col md:w-1/5">
                          <label className="pb-2">Quantity</label>
                          <input
                            {...methods.register("quantity")}
                            type="number"
                            placeholder="Quantity"
                            className="border p-2 rounded-lg border-gray-400"
                          />
                          {errors1.quantity && (
                            <ValidationError
                              message={
                                errors1.quantity?.message?.toString() ?? ""
                              }
                            />
                          )}
                        </div>
                        <div className="flex flex-col md:w-1/5">
                          <label className="pb-2">Total</label>
                          <input
                            {...methods.register("amount")}
                            type="number"
                            placeholder="Amount"
                            className="border p-2 rounded-lg border-gray-400"
                          />
                          {errors1.amount && (
                            <ValidationError
                              message={
                                errors1.amount?.message?.toString() ?? ""
                              }
                            />
                          )}
                        </div>
                        <div className="flex flex-col md:w-1/5">
                          {/*  <OutlinedButton type="button" className="custom-button text-primary-500 m-1"
                            variant={ButtonVariant.PRIMARY} onClick={() => methods.handleSubmit(onSubmit)}> */}
                          <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                ...methods.getValues(),
                              });
                            }}
                            type="submit"
                          >
                            Save
                          </Button>
                          <OutlinedButton
                            type="button"
                            className="custom-button text-gray-500 m-1"
                            onClick={() => setAddItemVisible(false)}
                            variant={ButtonVariant.GRAY}
                          >
                            Cancel
                          </OutlinedButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
            {!addItemVisible && billDetails.status !== "paid" && (
              <div className="flex flex-col border-b p-2 self-end">
                <button
                  className="custom-button text-lg"
                  type="button"
                  onClick={() => {
                    setAddItemVisible(true);
                  }}
                >
                  <span className="flex items-center">
                    <span className="icon-container">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          className="text-primary-500"
                          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                        ></path>
                      </svg>
                    </span>
                    <span className=" ml-1 text-md text-primary-500">
                      Add Item
                    </span>
                  </span>
                </button>
              </div>
            )}
            {/*         <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
             */}{" "}
            <div className="flex flex-col">
              <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 w-full justify-between p-4">
                {billDetails.status !== "paid" &&
                  billDetails.status !== "partlyPaid" && (
                    <div className="flex flex-col space-y-2 md:flex-row md:space-x-1 md:space-y-0">
                      <label>
                        <input
                          type="checkbox"
                          checked={includeTax}
                          onChange={handleTaxToggle}
                          className="pr-2 self-end"
                        />
                        <span className="ml-2">Add/Update Tax</span>
                      </label>
                    </div>
                  )}
                {includeTax &&
                  billDetails.status !== "paid" &&
                  billDetails.status !== "partlyPaid" && (
                    <>
                      <div className="flex flex-col space-y-2 md:flex-row md:space-x-1 md:space-y-0">
                        <input
                          type="number"
                          className="border p-2 rounded-lg border-gray-400"
                          placeholder="tax %"
                          onChange={(e) =>
                            setTaxesPercentage(Number(e.target.value))
                          }
                          onBlur={(e) => updateTaxAmount()}
                        />
                        <span
                          className="text-gray-800 font-bold sm:text-sm content-center"
                          id="price-currency"
                        >
                          %
                        </span>
                      </div>
                    </>
                  )}
              </div>

              <div className="flex flex-col items-end w-full p-6 space-y-2">
                {(includeTax || taxesPercentage > 0) && (
                  <>
                    <div className="flex flex-row w-2/5 justify-between">
                      <div className="flex text-gray-500 text-base w-1/2 justify-end">
                        Subtotal
                      </div>
                      <div className="text-gray-500 text-base">
                        {CURRENCY_SYMBOL}
                        {subTotal}
                      </div>
                    </div>
                    <div className="flex flex-row w-2/5 justify-between">
                      <div className="flex text-gray-500 text-base w-1/2 justify-end">
                        Taxes ({taxesPercentage}%)
                      </div>
                      <div className="text-gray-500 text-base">
                        {CURRENCY_SYMBOL}
                        {taxAmount}
                      </div>
                    </div>
                  </>
                )}
                <div className="flex flex-row w-2/5 justify-between mb-2">
                  <div className="flex text-black font-bold w-1/2 justify-end">
                    Total
                  </div>
                  <div className="text-black text-base">
                    {CURRENCY_SYMBOL}
                    {total}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* payment details */}
          <div className="flex flex-col m-3 border border-gray-400 shadow rounded-lg bg-white w-full">
            <div className="flex flex-col sm:flex-row justify-between">
              <div className="m-5">
                <legend className="text-base font-semibold leading-6 text-gray-900 mb-1">
                  Total Amount
                </legend>
                {CURRENCY_SYMBOL}
                {total}
              </div>
              <div className="m-5">
                <legend className="text-base font-semibold leading-6 text-gray-900 mb-1">
                  Paid
                </legend>
                {CURRENCY_SYMBOL}
                {billDetails.totalPaid}
              </div>
              <div className="m-5">
                <legend className="text-base font-semibold leading-6 text-gray-900 mb-1">
                  Outstanding
                </legend>
                {CURRENCY_SYMBOL}
                {billDetails.outStandingAmount}
              </div>
              <div className="m-5">
                <legend className={`text-base font-semibold leading-6 mb-1 `}>
                  Payment Status
                </legend>
                <span className={`font-bold ${getTextColor()}`}>
                  {(() => {
                    switch (billDetails.status) {
                      case "partlyPaid":
                        return "Partly Paid";
                      case "paid":
                        return "Paid";
                      case "pending":
                        return "Pending";
                    }
                  })()}
                </span>
              </div>
            </div>
            <hr className="my-2 border-t border-gray-300" />
            {billDetails.status !== "paid" && (
              <>
                <form onSubmit={handleSubmit(onPaymentSubmit)}>
                  <div className="flex flex-col sm:flex-row m-1">
                    <div className="flex flex-col w-9/12 sm:w-1/5 mx-5">
                      <Applabel label="Amount Paid" mandatory />
                      <input
                        {...register("amount")}
                        placeholder="Enter amount paid"
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setError("amount", {
                              message: "Please enter amount",
                            });
                            setHasErrors(true);
                          } else if (Number(e.target.value) < 0) {
                            setError("amount", {
                              message: "amount should be positive",
                            });
                            setHasErrors(true);
                          } else if (
                            Number(e.target.value) >
                            billDetails.outStandingAmount
                          ) {
                            setError("amount", {
                              message:
                                "Amount paid should be less than outstanding amount",
                            });
                            setHasErrors(true);
                          } else {
                            clearErrors("amount");
                            setValue("amount", e.target.value);
                            setHasErrors(false);
                          }
                        }}
                        type="number"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      />
                      {errors.amount && (
                        <ValidationError
                          message={errors.amount?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                    <div className="flex flex-col w-9/12 sm:w-1/5 mx-5">
                      <Applabel label="Payment Mode" />
                      <select
                        id="paymentMode"
                        {...register("paymentMode")}
                        className={`block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm`}
                      >
                        {["", "UPI", "Cash", "Card", "Online", "Other"].map(
                          (mode) => (
                            <option key={mode} value={mode}>
                              {mode}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="flex flex-col w-9/12 sm:w-2/5 mx-5">
                      <Applabel label="Note" />
                      <input
                        {...register("note")}
                        placeholder="Enter note"
                        type="text"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      />
                      {errors.note && (
                        <ValidationError
                          message={errors.note?.message?.toString() ?? ""}
                        />
                      )}
                    </div>
                    <div className="flex flex-col w-full sm:w-1/5 items-center mt-8">
                      <Button
                        variant={ButtonVariant.PRIMARY}
                        onClick={handleSubmit(onPaymentSubmit)}
                        type="submit"
                        isDisabled={hasErrors}
                      >
                        Mark Paid
                      </Button>
                    </div>
                  </div>
                </form>
                <hr className="my-2 border-t border-gray-300" />
              </>
            )}
            <div className="flex flex-row">
              <fieldset className="m-2">
                <legend className="text-base font-semibold leading-6 text-gray-900 mt-5">
                  Payment History
                </legend>
                <hr className="my-2 border-t border-gray-300" />
                {billDetails.paymentDetails &&
                  billDetails.paymentDetails.map((payment) => (
                    <>
                      <div className="relative flex items-start">
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Amount Paid: {CURRENCY_SYMBOL}
                            {payment.amountPaid}
                            <span
                              id="comments-description"
                              className="text-gray-700 pl-5"
                            >
                              Mode: {payment.paymentMode}
                            </span>
                            <span
                              id="comments-description"
                              className="text-gray-700 pl-5"
                            >
                              Date:{" "}
                              {moment(payment.paymentDate)
                                .format("DD-MM-YYYY")
                                .toString()}
                            </span>
                            <span
                              id="comments-description"
                              className="text-gray-700 pl-5"
                            >
                              Note: {payment.note}
                            </span>
                          </label>
                        </div>
                      </div>
                    </>
                  ))}
              </fieldset>
            </div>
          </div>
          {/* appointment details */}
          <div className="flex flex-col m-3 border border-gray-400 shadow rounded-lg bg-white w-full">
            <fieldset className="m-2">
              {billDetails.items &&
                billDetails.items.map((item) => (
                  <>
                    <legend className="text-base font-semibold leading-6 text-gray-900 mt-5">
                      {item.type} Session
                    </legend>
                    {item.appointments &&
                      item.appointments.map((appt) => (
                        <>
                          <hr className="my-2 border-t border-gray-300" />
                          <div className="relative flex items-start">
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-700"
                              >
                                Date: {appt.date}
                                <span
                                  id="comments-description"
                                  className="text-gray-500 pl-3"
                                >
                                  (Billed Amount: {appt.billedAmount})
                                </span>
                              </label>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ))}
            </fieldset>
          </div>
        </div>
      )}
      {isApptModelOpen && (
        <div className="flex flex-col w-full pl-2">
          <OutlinedButton
            variant={ButtonVariant.PRIMARY}
            className="w-24 self-end"
            type="button"
            onClick={() => {
              handleApptModalToggle();
            }}
          >
            Back
          </OutlinedButton>
          <UnbilledAppointments
            patientId={billDetails?.clientId ?? ""}
            patientAppointments={patientAppointments}
            totalAppointemnts={totalAppointemnts}
          />
        </div>
      )}
    </div>
  );
}

export const BillItemSchema = z.object({
  description: z.string().min(1, "Please add description"),
  quantity: z.string().min(1, "Please add quantity"),
  amount: z.string().min(1, "Please add amount"),
});
export type BillItemSchemaType = z.infer<typeof BillItemSchema>;

export const PaymentDetailsSchema = z.object({
  amount: z.string(),
  paymentMode: z.string().optional(),
  note: z.string().optional(),
});
export type PaymentDetailsSchemaType = z.infer<typeof PaymentDetailsSchema>;
