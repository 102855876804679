import PatientSearch from "@common/components/PatientSearch/PatientSearch";
import { Convert, Patient } from "@common/types/Patient";

import { endpoints, replaceParams } from "@utils/axios";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import React from "react";
import { InvoiceDetails } from "../types/BillingDetails";
import { CheckCircle, FileText, Printer } from "lucide-react";
import { toast } from "react-toastify";
import PatientSearchV2 from "@common/components/PatientSearch/PatientSearchV2";

interface InvoiceHeaderProps {
  onDateChange: (date: string) => void;
  onPatientChange: (patient: Patient | null) => void;
  invoice: InvoiceDetails; // Ensure this property is defined
  clientName: string;
  readOnly: boolean;
  onClose: () => void;
  handleSaveInvoice: () => void;
  handlePrint: () => void;
  resetPaymentFields: boolean; // Add this line
  makeEditable: () => void;
}

export default function InvoiceHeader({
  invoice,
  onDateChange,
  onPatientChange,
  onClose,
  clientName,
  handleSaveInvoice,
  handlePrint,
  readOnly,
  resetPaymentFields,
  makeEditable,
}: InvoiceHeaderProps) {
  // const [patients, setPatients] = React.useState<Patient[] | null>(null);
  // const getPatients = async () => {
  //   try {
  //     const response = await axios.get(endpoints.patient.list);
  //     const data = response.data["data"];
  //     if (data != null) {
  //       const convertedPatientList = data.map((patient: any) =>
  //         Convert.toPatient(JSON.stringify(patient))
  //       );
  //       setPatients(convertedPatientList);
  //     } else {
  //       setPatients(null);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching patients list:", error);
  //     setError(error.message);
  //   }
  // };
  // React.useEffect(() => {
  //   const _init = async () => {
  //     await getPatients();
  //   };
  //   _init();
  // }, []);

  const [selectedDate, setSelectedDate] = React.useState<string>(
    invoice.invoiceDate
      ? moment(invoice.invoiceDate).format("DD-MM-YYYY")
      : moment(new Date()).format("DD-MM-YYYY")
  );
  const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
  const cancelToken = React.useRef<CancelTokenSource | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setSelectedDate(e.target.value);
      invoice.invoiceDate = new Date(e.target.value);

      if (!readOnly) return;
      /** Update date directly id original status is paid */
      if (postInProgress) return;
      if (cancelToken.current) {
        cancelToken.current.cancel("Request already in progress!");
      }
      cancelToken.current = axios.CancelToken.source();

      try {
        setPostInProgress(true);
        if (invoice && invoice.id) {
          const paramsMap = new Map<string, string>([["id", invoice.id]]);
          const filteredEndpoint = replaceParams(
            endpoints.invoice.updateBillingDate,
            paramsMap
          );

          axios
            .put(
              filteredEndpoint,
              { id: invoice.id, invoiceDate: new Date(e.target.value) },
              { cancelToken: cancelToken.current.token }
            )
            .then((res) => {
              if (res.status === 200) {
                toast.success(
                  res.data.message === "success"
                    ? "Invoice date updated successfully"
                    : res.data.message
                );
              }
            })
            .catch((error) => {
              toast.error(error.message);
            });
        }
        setPostInProgress(false);
        //  getBillDetails();
      } catch (error) {
        console.error("Error submitting form:", error);
        setPostInProgress(false);
      }
    }
  };

  return (
    <div className="pt-4 px-2">
      <div className="flex flex-row gap-1">
        {/* Invoice Number Display */}
        <div className="basis-[5%]">
          <span className="h-12 w-12 bg-primary-200 rounded-lg flex items-center justify-center mr-2">
            <FileText className="h-6 w-6 text-primary-900" />
          </span>
        </div>
        <div className="basis-[20%]">
          <div className="flex flex-row">
            {/*             <span className="h-12 w-12 bg-primary-600 rounded-lg flex items-center justify-center mr-2">
              <FileText className="h-6 w-6 text-white" />
            </span> */}
            <div>
              <div className="flex flex-col ">
                <h3 className="text-sm font-medium text-gray-700 mb-1">
                  {" "}
                  Invoice Number
                </h3>
                <span className="text-sm text-gray-500">
                  {invoice?.invoiceNumber || "New Invoice"}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Patient Selection */}
        <div className="basis-[25%] pr-2">
          <h3 className="text-sm font-medium text-gray-700 mb-1">
            {" "}
            Patient Name
          </h3>
          {!invoice?.clientName || invoice?.clientName === "" ? (
            <PatientSearchV2 onSelect={onPatientChange} patient={null} />
          ) : (
            <span className="text-sm text-gray-500">
              {readOnly ? (
                invoice?.clientName ? (
                  invoice.clientName
                ) : (
                  clientName
                )
              ) : (
                <PatientSearchV2
                  onSelect={onPatientChange}
                  patient={{
                    _id: "",
                    firstName: invoice.clientName,
                    lastName: "",
                    middleName: "",
                    isActive: true,
                    phoneCode: 1,
                    profilePictureUrl: "",
                    email: "",
                    address: {
                      address: "",
                      city: "",
                      state: "",
                      country: "",
                      postalCode: "",
                    },
                    entityBranchId: "",
                    entityId: "",
                    salutation: "",
                  }}
                />
              )}
            </span>
          )}
        </div>

        {/* Invoice Date */}
        <div className="basis-[15%]">
          <h3 className="text-sm font-medium text-gray-700 mb-1">
            {" "}
            Invoice Date
          </h3>
          {readOnly ? (
            <span className="text-sm text-gray-500">{selectedDate}</span>
          ) : (
            <input
              className="flex px-2 rounded-lg border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm text-gray-500 cursor-pointer"
              type="date"
              style={{ width: "150px" }}
              value={moment(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD")}
              onChange={(e) => {
                handleDateChange(e);
                setSelectedDate(
                  moment(e.target.value, "YYYY-MM-DD").format("DD-MM-YYYY")
                );
              }}
            />
          )}
        </div>

        <div className="basis-[35%] flex flex-col gap-1 items-end">
          <div className="flex flex-row gap-1">
            <button
              onClick={
                readOnly ? () => setIsModalOpen(true) : handleSaveInvoice
              }
              className={`flex items-center gap-1 px-3 py-2.5 rounded-lg font-base text-white transition-all duration-200 ${
                (invoice && invoice.items.length === 0) || readOnly
                  ? "bg-primary-500 hover:bg-primary-700 active:bg-primary-800"
                  : "bg-primary-600 hover:bg-primary-700 active:bg-primary-800"
              }`}
            >
              {/*               {isCreating ? (
                <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              ) : showSuccess ? (
                <CheckCircle className="h-5 w-5" />
              ) : (
                <FileText className="h-5 w-5" />
              )} */}
              {/*               {showSuccess
                ? "Invoice Created!"
                : isCreating
                ? "Creating..."
                : "Create Invoice"} */}
              {readOnly ? "Edit Invoice" : "Save Invoice"}
            </button>
            <button
              onClick={handlePrint}
              className="flex items-center gap-2  p-2 text-primary-600 hover:text-primary-700 rounded-lg hover:bg-primary-100 transition-colors duration-200 text-sm font-semibold border border-primary-300"
            >
              <Printer className="h-4 w-4" />
              Print
            </button>
            <button
              onClick={onClose}
              className="flex items-center gap-2  p-2 text-primary-600 hover:text-primary-700 font-semibold  rounded-lg hover:bg-primary-100 transition-colors duration-200 text-sm border border-primary-300"
            >
              Close
            </button>
            {/*      <span
              className={`px-2.5 py-1 rounded-2xl text-xs font-semibold ${
                invoice.status === "paid"
                  ? "bg-emerald-100 text-emerald-800"
                  : invoice.status === "partlyPaid"
                  ? "bg-amber-100 text-amber-800"
                  : "bg-rose-100 text-rose-800"
              }`}
            >
              {invoice.status
                ? invoice.status.replace("_", " ").toUpperCase()
                : "UNKNOWN STATUS"}
            </span> */}
          </div>
        </div>
      </div>

      {/* Patient Details */}
      {/*       {patient && (
        <div className="mt-3 pt-3 border-t border-gray-100">
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <span className="text-gray-500">Email:</span>{" "}
              <span className="text-gray-600">{patient.email}</span>
            </div>
            <div>
              <span className="text-gray-500">Phone:</span>{" "}
              <span className="text-gray-600">{patient.phone}</span>
            </div>
          </div>
        </div>
      )} */}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={() => setIsModalOpen(false)}
          ></div>
          <div className="bg-white rounded-lg p-6 z-10">
            <h2 className="text-lg font-semibold">Confirm Edit</h2>
            <p>
              Invoice is already paid. Are you sure you want to edit this
              invoice?
            </p>
            <div className="flex justify-end mt-4">
              <button
                className="mr-2 px-4 py-2 bg-gray-300 rounded"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded"
                onClick={() => {
                  makeEditable();
                  setIsModalOpen(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
function setError(message: any) {
  throw new Error("Function not implemented.");
}
