import React, { useEffect } from "react";
import { DollarSign, IndianRupee } from "lucide-react";
import TaxList from "./TaxList";
import { CURRENCY_SYMBOL } from "../../../config/currencyConfig";
import { TaxItem } from "../types/Invoice";

interface SummaryCardProps {
  subtotal: number;
  discount: number;
  taxes: TaxItem[];
  total: number;
  onDiscountChange: (value: number) => void;
  onTaxesChange: (taxes: TaxItem[]) => void;
  readOnly?: boolean;
}

export default function SummaryCard({
  subtotal,
  discount,
  taxes,
  total,
  onDiscountChange,
  onTaxesChange,
  readOnly = false,
}: SummaryCardProps) {

  const formattedTotal =
      typeof total === "number" ? total.toFixed(2) : "0.00";
  
  const totalTaxAmount = (taxes || []).reduce((sum, tax) => sum + (subtotal - discount) * (tax.rate / 100), 0);
  
  const formattedSubtotal = typeof subtotal === "number" && !isNaN(subtotal) ? subtotal.toFixed(2) : "0.00";
  
  const formattedTotalTaxAmount = typeof totalTaxAmount === "number" && !isNaN(totalTaxAmount)
      ? totalTaxAmount.toFixed(2) : "0.00";
  



  return (
    <div className="px-4 gap-2 py-2">
      {/*       <h2 className="text-base font-semibold text-gray-900 mb-4">Summary</h2>
       */}
      <div className="flex">
        <div className="basis-1/2"></div>
        {/* Summary Items */}
        <div className="basis-1/2 space-y-2 items-end">
          {/* Subtotal */}
          <div className="flex items-center justify-between">
            <label className="text-sm text-gray-600">Subtotal</label>
            <span className="font-mono font-medium text-gray-900 tabular-nums">
              {CURRENCY_SYMBOL}
              {formattedSubtotal}
            </span>
          </div>

          {/* Discount Input */}
          <div className="flex items-center justify-between gap-4">
            <label className="text-sm text-gray-600">Discount</label>
            <div className="relative w-32">
              <IndianRupee className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                type="number"
                className="number-input pl-7 text-sm text-right tabular-nums h-8 w-full"
                value={discount}
                min="0"
                step="0.01"
                onChange={(e) => onDiscountChange(parseFloat(e.target.value))}
                disabled={readOnly}
              />
            </div>
          </div>

          {/* Taxes */}
          <div className="pt-2 border-t">
            <TaxList
              taxes={taxes}
              onTaxesChange={onTaxesChange}
              readOnly={readOnly}
              />
            {totalTaxAmount > 0 && (
              <div className="flex items-center justify-between mt-2 text-sm">
                <span className="text-gray-600">Total Tax</span>
                <span className="font-mono font-medium text-gray-900">
                  {CURRENCY_SYMBOL}
                  {formattedTotalTaxAmount}
                </span>
              </div>
            )}
          </div>

          {/* Total */}
          <div className="flex items-center justify-between pt-2 border-t">
            <label className="font-medium text-gray-900">Total</label>
            <span className="font-mono font-bold text-indigo-700 tabular-nums text-lg">
              {CURRENCY_SYMBOL}
              {formattedTotal}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
