import React from "react";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ValidationError } from "@components/hook-form";
import Applabel from "@components/hook-form/applabel";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import axios from "axios";
import { toast } from "react-toastify";
import { Switch } from "@headlessui/react";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";

export const AuditSchema = z.object({
  createdBy: z.string(),
  lastUpdatedBy: z.string(),
  createdAt: z.coerce.date(),
  lastUpdatedAt: z.coerce.date(),
});

export const ServiceSchema = z.object({
  name: z.string().min(1, { message: "Please enter Service name" }),
  description: z.string().optional(),
  defaultCost: z
    .number()
    .min(0, { message: "Price must be greater than or equal to 0" }),
  duration: z
    .number()
    .min(0, { message: "Duration must be greater than or equal to 0" }),
  isActive: z.boolean(),
  entityId: z.string(),
  branchId: z.string(),
  audit: AuditSchema,
});

export type Service = z.infer<typeof ServiceSchema>;

interface ServiceFormProps {
  initialData?: Service;
  branchId: string;
  closeForm: () => void;
  onSuccess?: () => void;
  isViewMode?: boolean;
}

const ServiceForm = ({
  initialData,
  branchId,
  closeForm,
  onSuccess,
  isViewMode = false,
}: ServiceFormProps) => {
  const userData = getUserPersistedOnLocalStorage();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Service>({
    resolver: zodResolver(ServiceSchema),
    defaultValues: {
      name: initialData?.name ?? "",
      description: initialData?.description ?? "",
      defaultCost: initialData?.defaultCost ?? 0,
      duration: initialData?.duration ?? 0,
      isActive: initialData?.isActive ?? true,
      entityId: userData?.entity_id,
      branchId: branchId,
      audit: {
        createdAt: new Date(),
        lastUpdatedAt: new Date(),
        createdBy: userData?.user_id,
        lastUpdatedBy: userData?.user_id,
      },
    },
  });

  const onSubmit: SubmitHandler<Service> = async (data) => {
    try {
      const endpoint = initialData
        ? `/api/v1/secure/services/${initialData.id}`
        : "/api/v1/secure/services";
      const method = initialData ? "put" : "post";

      const response = await axios[method](endpoint, data);
      if (response.status === 200) {
        toast.success(
          `Service ${initialData ? "updated" : "created"} successfully`
        );
        if (onSuccess) {
          onSuccess();
        }
        closeForm();
      } else {
        toast.error(response.data["message"]);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg w-full mx-auto">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          {isViewMode
            ? "View Service"
            : initialData
            ? "Edit Service"
            : "Create New Service"}
        </h3>
      </div>
      <hr className="w-full" />

      <div className="px-4 sm:px-6 py-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-3">
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                Service Name<span className="text-red-500"> *</span>
              </label>
              <input
                {...register("name")}
                readOnly={isViewMode}
                disabled={isViewMode}
                className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                  isViewMode ? "cursor-default" : ""
                }`}
              />
              {errors.name && <ValidationError message={errors.name.message} />}
            </div>

            <div>
              <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                Description
              </label>
              <textarea
                {...register("description")}
                readOnly={isViewMode}
                disabled={isViewMode}
                rows={4}
                className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                  isViewMode ? "cursor-default" : ""
                }`}
              />
              {errors.description && (
                <ValidationError message={errors.description.message} />
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                  Price<span className="text-red-500"> *</span>
                </label>
                <input
                  {...register("defaultCost", { valueAsNumber: true })}
                  type="number"
                  readOnly={isViewMode}
                  disabled={isViewMode}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                    isViewMode ? "cursor-default" : ""
                  }`}
                />
                {errors.defaultCost && (
                  <ValidationError message={errors.defaultCost.message} />
                )}
              </div>

              <div>
                <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                  Duration (mins)<span className="text-red-500"> *</span>
                </label>
                <input
                  {...register("duration", { valueAsNumber: true })}
                  type="number"
                  readOnly={isViewMode}
                  disabled={isViewMode}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                    isViewMode ? "cursor-default" : ""
                  }`}
                />
                {errors.duration && (
                  <ValidationError message={errors.duration.message} />
                )}
              </div>
            </div>

            <div className="flex items-center justify-between mt-6 mb-8">
              <span className="text-sm font-medium text-gray-700">
                {watch("isActive") ? "Active" : "Inactive"}
              </span>
              <div>
                <Switch
                  checked={watch("isActive")}
                  onChange={
                    isViewMode
                      ? () => {}
                      : (checked) => setValue("isActive", checked)
                  }
                  className={`${
                    watch("isActive")
                      ? "bg-secondary-600"
                      : isViewMode
                      ? "bg-gray-300"
                      : "bg-secondary-50"
                  }
                    relative inline-flex h-[38px] w-[74px] flex-shrink-0 ${
                      isViewMode
                        ? "cursor-default pointer-events-none"
                        : "cursor-pointer"
                    } rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${
                      watch("isActive") ? "translate-x-9" : "translate-x-0"
                    }
                      pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="flex flex-row w-full mt-0 p-4 bg-gray-100 justify-end">
        {!isViewMode ? (
          <>
            <OutlinedButton
              variant={ButtonVariant.PRIMARY}
              type="button"
              onClick={closeForm}
              children="Close"
              className="mr-2"
            />
            <button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              className="px-4 py-2 bg-primary-600 text-white rounded-xl hover:bg-primary-700 text-sm font-medium"
            >
              {initialData ? "Update" : "Create"} Service
            </button>
          </>
        ) : (
          <OutlinedButton
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={closeForm}
            children="Close"
            className="mr-2"
          />
        )}
      </div>
    </div>
  );
};

export default ServiceForm;
