import React, { useState, useEffect, Fragment } from "react";
import { Combobox } from "@headlessui/react";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { v4 as uuidv4 } from "uuid";
import ChevronUpDownIcon from "@heroicons/react/20/solid/ChevronUpDownIcon";
import { toast } from "react-toastify";

export interface TherapiesFieldData {
  tid: string;
  therapyName: string;
}

export interface TherapiesFieldDataProps {
  fieldDataList: TherapiesFieldData[];
  fieldName: string;
  addTherapyTo: (therapy: TherapiesFieldData) => void;
  selectedTherapies: TherapiesFieldData[];
}

export default function TherapiesDropdownInput({
  fieldDataList,
  fieldName,
  addTherapyTo,
  selectedTherapies,
}: TherapiesFieldDataProps) {
  const [selected, setSelected] = useState<TherapiesFieldData | null>(null);
  const [query, setQuery] = useState("");

  const filteredList =
    query === ""
      ? fieldDataList
      : fieldDataList.filter((fld) =>
          fld.therapyName.toLowerCase().includes(query.toLowerCase())
        );

  return (
    <div>
      <div className="flex items-center space-x-3 mt-2 w-md">
        <Combobox value={selected} onChange={setSelected}>
          <div className="relative w-full">
            <Combobox.Input
              className="border rounded-lg py-2 px-4 text-sm w-full"
              displayValue={(therapy: TherapiesFieldData) =>
                therapy?.therapyName || ""
              }
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search theraphy.."
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg z-10">
              {filteredList.length === 0 && query !== "" ? (
                <div className="cursor-default select-none py-2 px-4 text-gray-700">
                  No options found.
                </div>
              ) : (
                filteredList.map((therapy) => (
                  <Combobox.Option
                    key={therapy.tid}
                    value={therapy}
                    className={({ active }) =>
                      `cursor-default select-none py-2 px-4 ${
                        active ? "bg-blue-500 text-gray-900" : "text-gray-900"
                      }`
                    }
                  >
                    {therapy.therapyName}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </div>
        </Combobox>

        <Button
          variant={ButtonVariant.PRIMARY}
          type="button"
          className="ml-3"
          isDisabled={query === "" && !selected}
          onClick={() => {
            const existingTherapyInSelected = selectedTherapies.find(
              (therapy) =>
                therapy.therapyName.toLowerCase() === query.toLowerCase()
            );

            if (query && filteredList.length === 0) {
              if (!existingTherapyInSelected) {
                const newTherapy = { tid: uuidv4(), therapyName: query };
                addTherapyTo(newTherapy);
                setSelected(newTherapy);
              } else {
                toast.error("Therapy already exists in selected therapies");
              }
            } else if (selected) {
              const existingTherapyInSelectedForSelected =
                selectedTherapies.find(
                  (therapy) => therapy.tid === selected.tid
                );

              if (!existingTherapyInSelectedForSelected) {
                addTherapyTo(selected);
              }
            }

            setQuery("");
          }}
        >
          {filteredList.length === 0 && query ? "Add" : "Select"}
        </Button>
      </div>
    </div>
  );
}
