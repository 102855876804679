import React, { useEffect, useState } from "react";
import {
  Banknote,
  CheckCircle,
  Currency,
  DollarSign,
  IndianRupeeIcon,
} from "lucide-react";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { InvoiceDetails } from "../types/BillingDetails";
import { CURRENCY_SYMBOL } from "../../../config/currencyConfig";
import moment from "moment";

interface PaymentSectionProps {
  total: number;
  paidAmount: number;
  outStandingAmount: number;
  status: InvoiceDetails["status"];
  paymentMode: InvoiceDetails["paymentMode"];
  notes: string;
  originalStatus: string;
  onPaidAmountChange: (amount: number) => void;
  onStatusChange: (status: InvoiceDetails["status"]) => void;
  onPaymentModeChange: (mode: InvoiceDetails["paymentMode"]) => void;
  onNotesChange: (notes: string) => void;
  onPaymentDateChange: (date: Date) => void;
  resetPaymentFields: boolean;
}

export default function PaymentSection({
  total,
  paidAmount,
  outStandingAmount,
  status,
  paymentMode,
  notes,
  originalStatus,
  onPaidAmountChange,
  onStatusChange,
  onPaymentModeChange,
  onNotesChange,
  onPaymentDateChange,
  resetPaymentFields,
}: PaymentSectionProps) {
  const [localPaidAmount, setLocalPaidAmount] = useState(0);
  const [paymentModeState, setPaymentModeState] =
    useState<InvoiceDetails["paymentMode"]>("");
  const [localNotes, setLocalNotes] = useState<string>(""); // Initialize state for notes
  const [error, setError] = useState<string | null>(null); // State for error message

  useEffect(() => {
    if (resetPaymentFields) {
      setLocalPaidAmount(0);
      setPaymentModeState("");
      setLocalNotes("");
      setSelectedDate(moment(new Date(), "DD-MM-YYYY").format("YYYY-MM-DD"));
      resetPaymentFields =false
    }
  }, [resetPaymentFields]);

  useEffect(() => {
    // ... side effect logic here ...
    if (status === "paid" && originalStatus !== "paid") {
      setLocalPaidAmount(total);
      onPaidAmountChange(total);
    }
  }, [total]); // Added onPaidAmountChange and status to dependencies

  const handleStatusChange = (newStatus: InvoiceDetails["status"]) => {
    onStatusChange(newStatus);
    if (newStatus === "paid") {
      setLocalPaidAmount(total);
      onPaidAmountChange(total);
    } else {
      setLocalPaidAmount(0);
      onPaidAmountChange(0);
    }
  };

  const handlePaidAmountChange = (value: number) => {
    setLocalPaidAmount(value); // Update local state for immediate feedback
    onPaidAmountChange(value); // Call the prop function to update the parent state
  };

  const handleNotesChange = (value: string) => {
    setLocalNotes(value); // Update local state for immediate feedback
    onNotesChange(value); // Call the prop function to update the parent state
  };
  const onPaymentModeChangeLocal = (mode: InvoiceDetails["paymentMode"]) => {
    setPaymentModeState(mode);
    onPaymentModeChange(mode);
  };
  const [selectedDate, setSelectedDate] = React.useState<string>(
    moment(new Date(), "DD-MM-YYYY").format("YYYY-MM-DD")
  );


  return (
    <div className="p-4">
      {/*       <Receipt className="h-4 w-4 text-secondary-600" />
      <h2 className="text-base font-semibold text-gray-900 flex items-center gap-2">
        <Receipt className="h-4 w-4 text-secondary-600" />
        Line Items
      </h2> */}

      <h2 className="text-base font-semibold text-gray-900 flex items-center gap-2">
        <Banknote className="h-4 w-4 text-secondary-600" />
        Payment Details
      </h2>

      {/* Payment Status */}
      <div className="px-2 py-4">
        <div className="flex gap-1">
          <div className="basis-1/3 flex rounded-md items-center gap-2">
            <h3 className="text-base font-medium text-gray-700">
              <b>Total Paid: </b> {CURRENCY_SYMBOL}
              {paidAmount}
            </h3>
            {/*             <h3 className="text-sm font-medium text-gray-700">
              <b>Outstanding</b> {CURRENCY_SYMBOL}
              {outStandingAmount}
            </h3> */}
            {/*             <h3 className="items-center text-sm font-medium text-gray-700">
              Payment Status
            </h3> */}
            <span
              className={`px-2.5 py-1 rounded-xl text-sm font-semibold ${
                status === "paid"
                  ? "bg-emerald-100 text-emerald-800"
                  : status === "partlyPaid"
                  ? "bg-amber-100 text-amber-800"
                  : "bg-rose-100 text-rose-800"
              }`}
            >
              {status === "partlyPaid" ? "PARTLY PAID" : status.toUpperCase()}
            </span>
          </div>
          <div className="basis-2/3  flex flex-row  gap-2">
            {(["pending", "partlyPaid", "paid"] as const).map((value) => (
              <div className="w-full ">
                <button
                  type="button"
                  disabled={originalStatus !== "pending"}
                  onClick={() => handleStatusChange(value)}
                  className={`flex-1 w-full py-2 px-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                    status === value
                      ? "bg-primary-100 text-primary-700 border-2 border-primary-200"
                      : "bg-gray-100 text-gray-600 border-2 border-transparent hover:bg-primary-100"
                  }`}
                >
                  <div className="flex items-center justify-center gap-1">
                    {status === value && <CheckBadgeIcon className="h-4 w-4" />}
                    <span>
                      {" "}
                      {value === "partlyPaid"
                        ? "PARTLY PAID"
                        : value.toUpperCase()}
                    </span>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Payment Mode and Amount */}

      <div className="space-y-4">
        <div className="flex flex-row gap-4">
          {(status === "paid" || status === "partlyPaid") &&
            originalStatus !== "paid" && (
              <>
                {/* Amount Paid */}
                <div className="p-2 basis-1/4">
                  <h3 className="pb-2 items-center text-sm font-medium text-gray-700">
                    Amount Paid
                  </h3>
                  <div className="flex items-center">
                    <span className="mr-2 font-mono">{CURRENCY_SYMBOL}</span>
                    <input
                      type="number"
                      className="number-input pl-7 text-sm border border-gray-300 rounded"
                      value={localPaidAmount}
                      min="0"
                      max={total}
                      onBlur={(e) => {
                        const newAmount = parseFloat(e.target.value);
                        if (isNaN(newAmount)) {
                          setError("The amount cannot be blank.");
                        }
                      }}
                      onChange={(e) => {
                        const newAmount = parseFloat(e.target.value);
                        if (total - paidAmount - newAmount < 0) {
                          setError(
                            "The amount cannot exceed the outstanding total."
                          );
                        } else {
                          setError(null); // Clear error if valid
                          handlePaidAmountChange(newAmount);
                        }
                      }}
                      disabled={status === "paid"}
                    />
                  </div>
                </div>

                {/* Amount Paid */}
                <div className="p-2 basis-1/4">
                  <h3 className="pb-2 items-center text-sm font-medium text-gray-700">
                    Outstanding Amount
                  </h3>
                  <div className="flex items-center text-red-600">
                    <span className="mr-2 font-mono">{CURRENCY_SYMBOL}</span>
                    <span className="mr-2 font-sm ">
                      {total - paidAmount - localPaidAmount}
                    </span>
                    {/* 
                    <input
                      type="number"
                      className="number-input pl-7 text-sm border border-gray-300 rounded"
                      value={outStandingAmount - localPaidAmount}
                      min="0"
                      max={total}
                      step="1"
                      disabled={true}
                    /> */}
                  </div>
                </div>

                {/* Payment Mode */}
                <div className="p-2 basis-1/4">
                  <h3 className="pb-2 items-center text-sm font-medium text-gray-700">
                    Payment Mode
                  </h3>
                  <select
                    className="input-field text-sm border border-gray-300 rounded"
                    value={paymentModeState}
                    onChange={(e) =>
                      onPaymentModeChangeLocal(
                        e.target.value as InvoiceDetails["paymentMode"]
                      )
                    }
                  >
                    <option value=""></option>
                    <option value="cash">Cash</option>
                    <option value="upi">UPI</option>
                    <option value="online">Online</option>
                    <option value="card">Card</option>
                    <option value="bank_transfer">Bank Transfer</option>
                    <option value="check">Check</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                {/* Notes */}
                <div className="p-2 basis-1/2">
                  <h3 className="items-center text-sm font-medium text-gray-700">
                    Notes
                  </h3>
                  <textarea
                    className="input-field text-sm  border border-gray-300 rounded"
                    value={localNotes}
                    rows={1}
                    onChange={(e) => {
                      handleNotesChange(e.target.value);
                    }}
                    placeholder="Add any additional notes here..."
                  />
                </div>

                <div className="basis-[15%]">
                  <h3 className="text-sm font-medium text-gray-700 mb-1">
                    {" "}
                    Payment Date
                  </h3>
                  {/*                   {readOnly ? (
                    <span className="text-sm text-gray-500">
                      {selectedDate}
                    </span>
                  ) : ( */}
                  <input
                    className="flex px-2 rounded-lg border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm text-gray-500 cursor-pointer"
                    type="date"
                    style={{ width: "150px" }}
                    value={selectedDate}
                    onChange={(e) => {
                      onPaymentDateChange(new Date(e.target.value));
                      setSelectedDate(
                        moment(e.target.value).format("YYYY-MM-DD")
                      );
                    }}
                  />
                  {/*      )} */}
                </div>
              </>
            )}
        </div>
        <div>
          {error && (
            <div className="error-message text-red-500 text-xs">{error}</div>
          )}
        </div>
      </div>
    </div>
  );
}
