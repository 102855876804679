
export type InvoiceDetails = {
  discount: number;
  subtotal: number;
  paymentMode: "" |"other" | "cash" | "upi" | "online" | "card" | "bank_transfer" | "check";
  notes: string;
  id?: string;
  entityId?: string;
  entityBranchId?: string;
  clientId?: string;
  clientName?: string;
  invoiceNumber: string;
  invoiceDate: Date;
  dueDate: Date;
  items: LineItem[];
  totalAmount: number;
  status: 'partlyPaid' | 'paid' | 'pending';
  billForAppointments: boolean;
  totalPaid: number;
  tax: number;
  outStandingAmount: number;
  paymentDetails: PaymentDetails[]; 
  taxes: TaxItem[];

};


export type PaymentDetails = {
  amountPaid: number;
  paymentDate: Date;
  paymentMode: "" | "other" | "cash" | "upi" | "online" | "card" | "bank_transfer" | "check";
  note: string;
  transactionId: string;
  audit: Audit;
};

export type Audit = {
  createdBy: string,
  lastUpdatedBy: string,
  createdAt: Date,
  lastUpdatedAt: Date,
}

export type AppointmentDetail = {
  _id: string;
  type: string;
  date: string;
  billedAmount: number;
};

export type LineItem = {
  itemId: string;
  description?: string;
  quantity?: number;
  amount?: number;
  appointments?: AppointmentDetail[];
  total?: number;
  type?: 'service' | 'product' | 'plan' | 'other';
  staffId?: string;
  staffName?: string;

};

export class ConvertBilling {
  public static toBilling(json: string): InvoiceDetails {
    return JSON.parse(json);
  }

  public static billingToJson(value: InvoiceDetails): string {
    return JSON.stringify(value);
  }
}

export interface TaxItem {
  id: string;
  name: string;
  rate: number;
  type: 'vat' | 'gst' | 'cgst' | 'sgst' | 'igst' | 'sales' | 'service' | 'other';
}