import React, { Fragment, useState } from "react";

import PatientSearch from "./PatientSearch";
import { Dialog, Switch, Transition } from "@headlessui/react";

import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";

import Applabel from "@components/hook-form/applabel";
import { z } from "zod";
import { ConvertStaff, Staff } from "./types/StaffType";
import { ValidationError } from "@components/hook-form";
import useAxios from "@routes/hooks/use-axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { endpoints, replaceParams } from "@utils/axios";
import { toast } from "react-toastify";
import { AppointmentDto } from "./types/AppointmentDto";
import {
  AvailableTimeSlots,
  AvailableTimeSlotsConvert,
  BookedAppointment,
  Freeslot,
} from "./types/AvailableTimeSlots";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import {
  EntityUserDetailsWithFreeTimeSlots,
  EntityUserDetailsWithFreeTimeSlotsConvert,
} from "./types/EntityUserDetailsWithFreeTimeSlots";
import tzmoment from "moment-timezone";
import { CancelTokenSource } from "axios";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import ReactPopover from "./ReactPopOver";
import { EntityBranch, EntityBranchConvert } from "./types/EntityBranch";
import PatientSearchV2 from "@common/components/PatientSearch/PatientSearchV2";
import { Convert, Patient } from "@common/types/Patient";

interface AppointmentData {
  AppointmentDuration: number[];
  AppointmentType: string[];
  appointmentString: string[];
}

export const AppointmentSchema = z.object({
  patientId: z
    .string()
    .min(1, { message: "Please select patient name" })
    .default(""),
  appointmentType: z
    .string()
    .min(1, { message: "Please select appointment type" })
    .default(""),
  physioId: z
    .string()
    .min(1, { message: "Please select Physiotherapist" })
    .default(""),
  slotToBook: z.string().min(2, { message: "Please select slot/slots" }),
});

export type AppointmentType = z.infer<typeof AppointmentSchema>;

export default function BookAppointmentPage(props: {
  setQuickPatientAddDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setBookAppointmentDialog: React.Dispatch<React.SetStateAction<boolean>>;
  getAppointments: () => Promise<void>;
}) {
  // Dialog Related
  const userData = getUserPersistedOnLocalStorage();
  let [isOpen, setIsOpen] = React.useState(true);
  function closeModal() {
    setIsOpen(false);
    props.setBookAppointmentDialog(false);
  }

  const [patients, setPatients] = React.useState<Patient[] | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [selectedPatientId, setSelectedPatientId] = React.useState<
    string | null
  >(null);
  const [selectedPatientName, setSelectedPatientName] = React.useState<
    string | null
  >(null);
  const [selectedPatientAddress, setSelectedPatientAddress] = React.useState<
    string | null
  >(null);
  const [selectedPatientEmail, setSelectedPatientEmail] = React.useState<
    string | null
  >(null);
  const [selectedPatientPhone, setSelectedPatientPhone] = React.useState<
    number | null
  >(null);
  const [appointmentNotes, setAppointmentNotes] = React.useState<string>("");
  const [bookedAppointments, setBookedAppointments] = React.useState<
    BookedAppointment[]
  >([]);

  const axios = useAxios();
  const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
  const cancelToken = React.useRef<CancelTokenSource | null>(null);

  const [staffList, setStaffList] = React.useState<Staff[] | null>(null);
  const [selectedEntityUserId, setSelectedEntityUserId] = React.useState("");
  const [selectedEntityUserName, setSelectedEntityUserName] =
    React.useState("");
  const [selectedEntityUserPhone, setSelectedEntityUserPhone] =
    React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectedConsultation, setSelectedConsultation] =
    React.useState("In-Clinic");
  const [entityUserWithFreeTimeSlots, setEntityUserWithFreeTimeSlots] =
    React.useState<EntityUserDetailsWithFreeTimeSlots | null>(null);

  const [appointmentDuration, setAppointmentDuration] = React.useState(0);
  const [selectAppointmentType, setSelectApppointmentType] = React.useState("");
  const [appointmentTypeData, setAppointmentTypeData] =
    React.useState<AppointmentData | null>(null);

  const [requestedAppointments, setRequestedAppointments] = React.useState<
    AppointmentDto[]
  >([]);

  const [availableTimeSlots, setAvailableTimeSlots] = React.useState<
    AvailableTimeSlots[] | null
  >(null);
  const [selectedSessionCredit, setSelectedSessionCredit] = React.useState<
    number | null
  >(null);
  // const [entityId, setSelectedEntityId] = React.useState<string | null>(null);
  const [enabled, setEnabled] = React.useState(false);
  const radioButtonsRef = React.useRef<{
    [key: string]: HTMLInputElement | null;
  }>({});
  const [branches, setBranches] = React.useState<EntityBranch[] | null>(null); // this is the list of branches [{}]
  const [branchId, setBranchId] = React.useState<string | null>(
    userData?.branch_id.toString() ?? ""
  );
  const [selectedPatientBranchId, setSelectedPatientBranchId] = React.useState<
    string | null
  >(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError: setZodError,
    clearErrors,
    formState: { errors },
  } = useForm<AppointmentType>({ resolver: zodResolver(AppointmentSchema) });

  function setStaffDetails(staffid: string): void {
    const employee = staffList?.find((staff) => staff.id === staffid);
    setSelectedEntityUserId(employee?.id ?? "");
    setSelectedEntityUserName(
      `${employee?.salutation}. ${employee?.firstName} ${employee?.lastName}`
    );
    setSelectedEntityUserPhone(employee?.phone ?? 0);
  }

  const handleAppointmentTypeChanged = (
    e: React.ChangeEvent<HTMLSelectElement>,
    appType: string[],
    appDuration: number[]
  ) => {
    var selectedIndex = -1;
    selectedIndex = Number(e.target.value);
    if (selectedIndex !== -1) {
      const selectedType = appType[selectedIndex];
      const selectedDuration = appDuration[selectedIndex];
      setSelectApppointmentType(selectedType);
      setAppointmentDuration(selectedDuration);
      clearErrors("appointmentType");
    } else {
      setSelectApppointmentType("");
      setAppointmentDuration(0);
    }
  };

  const getPatients = async () => {
    try {
      const response = await axios.get(endpoints.patient.list);
      const data = response.data["data"]["clients"];
      if (data != null) {
        const convertedPatientList = data.map((patient: any) =>
          Convert.toPatient(JSON.stringify(patient))
        );
        setPatients(convertedPatientList);
      } else {
        setPatients(null);
      }
    } catch (error) {
      console.error("Error fetching patients list:", error);
      setError(error.message);
    }
  };

  // const getSessionCredit = async (selectedPatientId: string) => {
  //   try {
  //     const paramsMap = new Map<string, string>([['id', selectedPatientId]]);
  //     const filteredEndpoint = replaceParams(
  //       endpoints.appointment.sessionCredits,
  //       paramsMap,
  //     );
  //     const response = await axios.get(filteredEndpoint);
  //     const data = response.data['data'];
  //     if (data != null) {
  //       const convertedSessionCredit = data.map((patient: any) =>
  //         Convert.toPatient(JSON.stringify(patient)),
  //       );

  //       setSelectedSessionCredit(convertedSessionCredit);
  //     } else {
  //       setSelectedSessionCredit(null);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching session credits:', error);
  //     setError(error.message);
  //   }
  // };

  const getAppointmentType = async () => {
    try {
      const userData = getUserPersistedOnLocalStorage();
      const entityIdset = userData?.entity_id;

      // Ensure entityIdset is defined before using it
      if (entityIdset !== undefined) {
        // Create a Map with the entityIdset as the value
        const paramsMap = new Map<string, string>([["id", entityIdset]]);

        // Continue with your logic using paramsMap
        const filteredEndpoint = replaceParams(
          endpoints.appointment.fetchAppointmentDuration,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data: AppointmentData = response.data["data"];
        setAppointmentTypeData(data);
      }
    } catch (error) {
      console.error("Error fetching appointment type:", error);
    }
  };
  const fetchStaffList = async () => {
    try {
      let response;
      if (branchId) {
        response = await axios.get(endpoints.staff.list, {
          params: {
            branchId: branchId.toString() ?? "",
          },
        });
      } else {
        response = await axios.get(endpoints.staff.list);
      }

      const data = response.data.data;
      const convertedStaffList = data.map((staff: any) =>
        ConvertStaff.toStaff(JSON.stringify(staff))
      );
      setStaffList(convertedStaffList);
    } catch (error) {
      console.error("Error fetching staff list:", error);
      setError(error.message);
    }
  };

  React.useEffect(() => {
    getBranches();
  }, []);

  React.useEffect(() => {
    const _init = async () => {
      await getPatients();
    };
    _init();
  }, []);

  React.useEffect(() => {
    const _init = async () => {
      await getAppointmentType();
    };
    _init();
  }, []);

  // React.useEffect(() => {
  //   const _init = async () => {
  //     if (selectedPatientId !== null) {
  //       await getSessionCredit(selectedPatientId);
  //     }
  //   };
  //   _init();
  // }, [selectedPatientId]);

  React.useEffect(() => {
    const _init = async () => {
      await fetchStaffList();
    };
    _init();
  }, []);

  React.useEffect(() => {
    setRequestedAppointments((all) =>
      all.map((a) => ({
        ...a,

        clientId: selectedPatientId ?? "",
        clientName: selectedPatientName ?? "",
        clientPhone: selectedPatientPhone ?? 0,
        clientAddress: selectedPatientAddress ?? 0,
        email: selectedPatientEmail ?? "",
      }))
    );
  }, [selectedPatientId]);

  React.useEffect(() => {
    const getAvailableTimeSlots = async () => {
      try {
        setLoading(true);
        const paramsMap = new Map<string, string>([
          ["userId", selectedEntityUserId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.appointment.arrayOfAvailabletimeSlots,
          paramsMap
        );

        const response = await axios.get(filteredEndpoint, {
          params: {
            requestedDate: moment(new Date(selectedDate)).format("DD-MM-YYYY"),
            requestedSlotInterval: "15",
            requestedAppointmentDuration: appointmentDuration,
            requestedNoOfDays: 15,
          },
        });

        const data = response.data["data"]["availableSlots"];
        if (data != null) {
          const convertedAvailableTimeSlots = data.map((slot: any) =>
            AvailableTimeSlotsConvert.toAvailableTimeSlots(JSON.stringify(slot))
          );
          const timeZone = moment.tz.guess();
          const convertedAvailableTimeSlotsLocalTime =
            convertedAvailableTimeSlots.map((available: AvailableTimeSlots) => {
              return available.freeslots.map((freeslot: Freeslot) => {
                const startMoment = moment.utc(freeslot.startTime, "HH:mm A");
                freeslot.startTime = startMoment.tz(timeZone).format("hh:mm A");
                const endMoment = moment.utc(freeslot.endTime, "HH:mm A");
                freeslot.endTime = endMoment.tz(timeZone).format("hh:mm A");

                return freeslot;
              });
            });
          setAvailableTimeSlots(convertedAvailableTimeSlots);
          const converted =
            EntityUserDetailsWithFreeTimeSlotsConvert.toEntityUserDetailsWithFreeTimeSlots(
              JSON.stringify(response.data["data"])
            );

          setEntityUserWithFreeTimeSlots(converted);
          setLoading(false);
        } else {
          setLoading(false);
          setAvailableTimeSlots(null);
        }
      } catch (error) {
        console.error("Error fetching available time slots...", error);
        setAvailableTimeSlots(null);
        setError(error.message);
        setLoading(false);
      }
    };

    if (
      selectedEntityUserId !== "" &&
      selectedDate !== "" &&
      appointmentDuration !== 0
    ) {
      clearAllBookedAppointments();
      getAvailableTimeSlots();
      setSelectedConsultation("In-Clinic");
    } else {
      clearAllBookedAppointments();
      setEntityUserWithFreeTimeSlots(null);
      setAvailableTimeSlots(null);
    }
  }, [selectedEntityUserId, selectedDate, appointmentDuration]);

  const onSubmit: SubmitHandler<AppointmentType> = async () => {
    if (postInProgress) return;
    // Here we will have 1 ] Appointment Date 2 ] Start Time 3] End Time 4] Start Time Minutes 5] End time Minutes According in Local Time
    // Now Convert the above 5 point in UTC before sending to backend
    requestedAppointments.forEach((val) => {
      let duration = val.endTimeMinutes - val.startTimeMinutes;
      // Adding time to the date Selected and converting it to UTC in 2023-12-20T00:00:00Z format
      val.apptdate = addTimeToTimeZoneAndConvertToISOString(
        val.apptdate,
        val.startTime
      );

      val.date = moment.utc(val.apptdate).format("DD-MM-YYYY");

      // Converting String eg "10:00 AM" to Equivalent UTC String
      val.startTime = moment.utc(val.apptdate).format("hh:mm A");
      val.endTime = moment(val.startTime, "hh:mm A")
        .add(duration, "minutes")
        .format("hh:mm A");

      // Converting startTime Minutes to Equivalent UTC minutes
      val.startTimeMinutes =
        val.apptdate.getUTCHours() * 60 + val.apptdate.getUTCMinutes();
      val.endTimeMinutes =
        val.apptdate.getUTCHours() * 60 +
        val.apptdate.getUTCMinutes() +
        duration;
    });

    var shadow = requestedAppointments;

    if (requestedAppointments.length > 1) {
      shadow = requestedAppointments.map((e, index) => ({
        ...e,
        modeOfConsultation: selectedConsultation,
      }));
    }

    if (cancelToken.current) {
      cancelToken.current.cancel("Request already in progress!");
    }

    cancelToken.current = axios.CancelToken.source();

    shadow = requestedAppointments.map((e, index) => ({
      ...e,
      notes: appointmentNotes,
      clientAddress: selectedPatientAddress,
      entityBranchId: branchId?.toString() ?? "",
    }));

    try {
      setPostInProgress(true);
      await axios.post(
        `${endpoints.appointment.recurringAppointment}`,
        {
          appointmentdetails: shadow,
        },
        {
          cancelToken: cancelToken.current.token,
        }
      );
      setPostInProgress(false);
      props.getAppointments();
      toast.success("Appointment Booked Successfully");
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error["response"]["data"]["data"]);
      clearAllBookedAppointments();
      setPostInProgress(false);
    }
  };

  const formatDate = (d: Date | string) => {
    const dateObject = typeof d === "string" ? new Date(d) : d;

    if (!(dateObject instanceof Date && !isNaN(dateObject.getTime()))) {
      return "Invalid Date";
    }

    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const weekday = dateObject.toLocaleDateString("en-IN", {
      weekday: "short",
    });

    return `${weekday} ${day}/${month}`;
  };

  const addTimeToTimeZoneAndConvertToISOString = (
    date: Date,
    timeString: string
  ): Date => {
    //   Parse input date and time using Moment.js

    const inputDate = moment(date);
    const inputTime = moment(timeString, "hh:mm A");

    // Combine date and time
    let resultDate = inputDate.set({
      hour: inputTime.hours(),
      minute: inputTime.minutes(),
    });
    // Convert to ISO string and return
    return new Date(moment(resultDate).toISOString());
  };
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != "") {
      setSelectedDate(e.target.value);
    }
  };
  const handleAppointmentBookChanges = (
    slots: AvailableTimeSlots,
    selected: Freeslot
  ) => {
    let appoinmentTypeValue: string = selectAppointmentType;

    const _slotDate = moment(new Date(slots.date)).format("DD-MM-YYYY");
    const isAdded =
      requestedAppointments?.findIndex((all) => all.date === _slotDate) >= -1;
    const toUpdate = {
      clientId: selectedPatientId ?? "",
      clientName: selectedPatientName ?? "",
      clientPhone: selectedPatientPhone ?? 0,
      clientAddress: selectedPatientAddress ?? "",
      date: _slotDate,
      apptdate: slots.date,
      appointmentType: appoinmentTypeValue,
      email: selectedPatientEmail ?? "",
      entityBranchId: userData?.branch_id ?? "",
      entityId: userData?.entity_id ?? "",
      notes: appointmentNotes,
      endTime: selected.endTime,
      endTimeMinutes: selected.endTimeMinutes,
      startTime: selected.startTime,
      startTimeMinutes: selected.startTimeMinutes,
      entityUserId: selectedEntityUserId ?? "",
      entityUserName: selectedEntityUserName ?? "",
      appointmentStatus: "Scheduled",
      appointmentSlot: 0,
      duration: appointmentDuration ?? 0,
      entityUserPhone: selectedEntityUserPhone ?? 0,
      statusChangeComment: "",
      modeOfConsultation: selectedConsultation,
    };
    if (isAdded) {
      setRequestedAppointments((all) =>
        all.filter((a) => a.date !== _slotDate)
      );
      setRequestedAppointments((all) => [...all, toUpdate]);
    } else {
      setRequestedAppointments((all) => [...all, toUpdate]);
    }
    console.log("Appointments" + JSON.stringify(requestedAppointments));
  };

  // TODO: Remove after the backend supports multiple appointment booking
  React.useEffect(() => {
    if (requestedAppointments.length > 1 && selectedConsultation === "Online") {
      setSelectedConsultation("In-Clinic");
    }
  }, [requestedAppointments]);

  React.useEffect(() => {
    if (requestedAppointments.length > 1) {
      const shadow: AppointmentDto[] = requestedAppointments.map(
        (e, index) => ({
          ...e,
          modeOfConsultation: selectedConsultation,
        })
      );
      setRequestedAppointments(shadow);
    }
  }, [selectedConsultation]);

  function checkErrorsIfAny() {
    setValue("slotToBook", "selected");
    clearErrors("slotToBook");
  }

  const clearAllBookedAppointments = () => {
    setRequestedAppointments([]);
    Object.values(radioButtonsRef.current).forEach((radioButton) => {
      if (radioButton) {
        radioButton.checked = false;
      }
    });
    setValue("slotToBook", "");
    // setIsAppointmentOnline(false);
  };

  const handleOnlineOfflineAppointment = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    var shadow: AppointmentDto[];
    const value = e.target.value;
    setSelectedConsultation(value);
    shadow = requestedAppointments.map((e, index) => ({
      ...e,
      modeOfConsultation: value,
    }));
    setRequestedAppointments(shadow);
    // console.log("Shadow " + JSON.stringify(shadow))

    return;
  };

  const getBranches = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["entityId", userData?.entity_id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(endpoints.branch.list, paramsMap);

      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      const convertedBranchList = data.map((entity: any) =>
        EntityBranchConvert.toEntityBranch(JSON.stringify(entity))
      );

      setBranches(convertedBranchList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching entity list:", error);
    }
  };

  const clearForDate = (name: Date) => {
    const _slotDate = moment(new Date(name)).format("DD-MM-YYYY");
    const isAdded =
      requestedAppointments?.findIndex((all) => all.date === _slotDate) >= -1;
    if (isAdded) {
      setRequestedAppointments((all) =>
        all.filter((a) => a.date !== _slotDate)
      );
    }

    Object.values(radioButtonsRef.current).forEach((radioButton) => {
      if (radioButton) {
        if (radioButton.name === name.toString()) radioButton.checked = false;
      }
    });
    if (requestedAppointments.length === 0) setValue("slotToBook", "");
  };

  React.useEffect(() => {
    fetchStaffList();
  }, [branchId]);

  React.useEffect(() => {
    setBranchId(selectedPatientBranchId?.toString() ?? "");
  }, [selectedPatientBranchId]);

  const handleBranchSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBranchId(event.target.value);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/*  */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full justify-center sm:2 md:p-6 m-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-full transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 px-6 pt-6 text-gray-900"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex-grow text-xl font-bold">
                        Book Appointment
                      </div>
                      <div className="">
                        <OutlinedButton
                          variant={ButtonVariant.PRIMARY}
                          onClick={() => {
                            closeModal();
                            props.setQuickPatientAddDialog(true);
                          }}
                          className="ml-2"
                          type="button"
                        >
                          New Patient
                        </OutlinedButton>
                      </div>
                    </div>
                  </Dialog.Title>
                  <hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />

                  {/* Form */}
                  <form noValidate>
                    <div className="max-h-[75vh] overflow-auto">
                      <div className="flex w-full flex-col p-2 md:p-6">
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2 w-full">
                          <div className="flex flex-col">
                            <Applabel label="Select Patient Name" />
                            {/* // TODO: Migrate to PatientSearchV2 */}
                            {/* <PatientSearch
                              data={patients || []}
                              setSelectedPatientId={setSelectedPatientId}
                              setSelectedPatientName={setSelectedPatientName}
                              setSelectedPatientEmail={setSelectedPatientEmail}
                              setSelectedPatientPhone={setSelectedPatientPhone}
                              setSelectedPatientBranchId={
                                setSelectedPatientBranchId
                              }
                              setSelectedPatientAddress={
                                setSelectedPatientAddress
                              }
                              register={register}
                              setValue={setValue}
                              clearErrors={clearErrors}
                            /> */}
                            <PatientSearchV2
                              onSelect={(patient: Patient | null) => {
                                if (patient) {
                                  setSelectedPatientId(patient._id);
                                  setSelectedPatientName(
                                    patient.firstName + " " + patient.lastName
                                  );
                                  setSelectedPatientEmail(patient.email);
                                  setSelectedPatientPhone(patient.phone);
                                  setSelectedPatientBranchId(
                                    patient.entityBranchId
                                  );
                                  let address =
                                    patient.address.address +
                                    ", " +
                                    patient.address.city +
                                    ", " +
                                    patient.address.state +
                                    ", " +
                                    patient.address.postalCode;
                                  setSelectedPatientAddress(address);

                                  // set in form
                                  setValue("patientId", patient._id);
                                  clearErrors("patientId");
                                }
                              }}
                            />
                            {errors.patientId && (
                              <ValidationError
                                message={
                                  errors.patientId?.message?.toString() ?? ""
                                }
                              />
                            )}
                            {/* <span className="text-xs text-gray-500 mb-2">
                              Session Count {selectedSessionCredit}
                            </span> */}
                          </div>
                          <div className="flex flex-col">
                            <Applabel label="Select Appointment Type" />
                            <select
                              className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
                              onChange={(e) => {
                                handleAppointmentTypeChanged(
                                  e,
                                  appointmentTypeData?.AppointmentType ?? [],
                                  appointmentTypeData?.AppointmentDuration ?? []
                                );
                                setValue("appointmentType", e.target.value);
                                if (e.target.value !== "") {
                                  clearErrors("appointmentType");
                                }
                              }}
                              required
                            >
                              <option value="">Select Apppointment</option>

                              {appointmentTypeData?.AppointmentType.map(
                                (type, index) => {
                                  return (
                                    <option key={index} value={index}>
                                      {type}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                            {errors.appointmentType && (
                              <ValidationError
                                message={
                                  errors.appointmentType?.message?.toString() ??
                                  ""
                                }
                              />
                            )}
                          </div>

                          <div className="flex flex-col w-full">
                            <Applabel label="Select Date" />
                            <input
                              className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
                              type="date"
                              value={selectedDate}
                              onChange={handleDateChange}
                            />
                          </div>

                          <div className="flex flex-col">
                            <Applabel label="Select Physiotherapist" />
                            <select
                              className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
                              onChange={(e) => {
                                setStaffDetails(e.target.value);
                                setValue("physioId", e.target.value);
                                if (e.target.value !== "") {
                                  clearErrors("physioId");
                                }
                              }}
                              required
                            >
                              <option value="">Select Physiotherapist</option>
                              {staffList &&
                                staffList.map((staff: Staff) => {
                                  if (
                                    (staff.role.role === "PRACTITIONER" ||
                                      staff.role.role === "ENTITY_OWNER" ||
                                      staff.role.role === "BRANCH_ADMIN") &&
                                    staff.isActive
                                  )
                                    return (
                                      <option value={staff.id}>
                                        {`${staff.salutation}. ${
                                          staff.firstName
                                        } ${staff.middleName || ""} ${
                                          staff.lastName || ""
                                        }`}
                                      </option>
                                    );
                                })}
                            </select>
                            {errors.physioId && (
                              <ValidationError
                                message={
                                  errors.physioId?.message?.toString() ?? ""
                                }
                              />
                            )}
                          </div>
                          <div className="flex flex-col">
                            <Applabel label="Appointment Duration" />
                            <select
                              className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
                              value={appointmentDuration}
                              onChange={(e) =>
                                setAppointmentDuration(Number(e.target.value))
                              }
                              required
                            >
                              <option value="">Select Duration</option>

                              {[15, 20, 30, 45, 60, 75, 90, 105, 120].map(
                                (duration) => {
                                  return (
                                    <option key={duration} value={duration}>
                                      {duration}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                            {errors.appointmentType && (
                              <ValidationError
                                message={
                                  errors.appointmentType?.message?.toString() ??
                                  ""
                                }
                              />
                            )}
                          </div>
                          {userData?.user_role === "ENTITY_OWNER" ? (
                            <div className="flex flex-col">
                              <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                                Select Branch
                              </label>

                              <select
                                onChange={handleBranchSelectChange}
                                name="entityBranchId"
                                value={branchId?.toString()}
                                className={`mr-4 lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer`}
                              >
                                {branches?.map((branch) => (
                                  <option key={branch.id} value={branch.id}>
                                    {branch.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      {/* Timeslot List */}
                      <div className="flex flex-col">
                        <div className="flex flex-row justify-between my-2">
                          <div className="flex flex-col">
                            <label className="block text-xs font-semibold leading-6 text-grey-900 pl-4">
                              Timezone: {tzmoment.tz.guess()} (UTC
                              {tzmoment.tz(tzmoment.tz.guess()).format("Z")})
                            </label>
                          </div>
                          <OutlinedButton
                            variant={ButtonVariant.PRIMARY}
                            type="button"
                            className="mr-6"
                            onClick={clearAllBookedAppointments}
                          >
                            Clear All
                          </OutlinedButton>
                        </div>
                        {availableTimeSlots === null &&
                          selectedEntityUserId != "" &&
                          appointmentDuration != 0 && (
                            <div className="flex justify-center w-full h-full text-gray-500 p-4">
                              No Time slots available
                            </div>
                          )}

                        {selectedEntityUserId === "" &&
                          appointmentDuration === 0 && (
                            <div className="flex justify-center w-full h-full text-gray-500 p-4">
                              Please select appointment type & physiotherapist
                            </div>
                          )}

                        {selectedEntityUserId === "" &&
                          appointmentDuration != 0 && (
                            <div className="flex justify-center w-full h-full text-gray-500 p-4">
                              Please select physiotherapist
                            </div>
                          )}
                        {appointmentDuration === 0 &&
                          selectedEntityUserId != "" && (
                            <div className="flex justify-center w-full h-full text-gray-500 p-4">
                              Please select appointment type
                            </div>
                          )}
                        {availableTimeSlots && !loading && (
                          <div className="h-96 overflow-scroll w-full">
                            <div className="mt-2 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row justify-evenly pb-1 overflow-x-scroll w-full md:w-max ">
                              {availableTimeSlots.map(
                                (val: AvailableTimeSlots, i: number) => {
                                  return (
                                    <div className="p-2 flex flex-col space-y-2 mx-2 md:mx-0 lg:mx-0 xl:mx-0 2xl:mx-0 mb-2 ">
                                      <div className="sticky top-0 inline-flex flex-col items-center rounded border border-primary-600 bg-primary-50 px-2 py-2 text-sm font-bold shadow-sm transition duration-150 ease-in-out hover:bg-primary-100 hover:shadow focus:outline-none focus:ring-primary-500 focus:ring-offset-primary-200">
                                        <div className="flex flex-row justify-between w-full">
                                          <span>{formatDate(val.date)}</span>
                                          <button
                                            className=""
                                            type="button"
                                            onClick={() => {
                                              clearForDate(val.date);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-5 w-5 text-black"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M15.707 4.293a1 1 0 0 1 0 1.414L11.414 10l4.293 4.293a1 1 0 1 1-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 1 1-1.414-1.414L8.586 10 4.293 5.707a1 1 0 0 1 1.414-1.414L10 8.586l4.293-4.293a1 1 0 0 1 1.414 0z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                      {availableTimeSlots &&
                                        availableTimeSlots[i].freeslots
                                          .length === 0 && (
                                          <div
                                            className={`text-primary inline-flex justify-evenly rounded border border-gray-600  
                                               bg-red-200 px-2 py-2 text-sm font-normal shadow-sm transition duration-150 ease-in-out hover:bg-red-300 hover:shadow focus:outline-none focus:ring-red-200 focus:ring-offset-red-200
                                                  
                                              `}
                                          >
                                            N/A
                                          </div>
                                        )}
                                      <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-2">
                                        {availableTimeSlots &&
                                          availableTimeSlots[i].freeslots.map(
                                            (slot: Freeslot, index: number) => {
                                              return (
                                                <>
                                                  <div
                                                    className={`text-primary inline-flex justify-evenly rounded border border-gray-600  ${
                                                      slot.appointmentsCount > 0
                                                        ? "bg-[#FFFACD] px-2 py-2 text-sm font-normal shadow-sm transition duration-150 ease-in-out hover:bg-[#FFFACD] hover:shadow focus:outline-none focus:ring-[#FFFACD] focus:ring-offset-[#FFFACD]"
                                                        : "bg-gray-50 px-2 py-2 text-sm font-normal shadow-sm transition duration-150 ease-in-out hover:bg-gray-100 hover:shadow focus:outline-none focus:ring-gray-500 focus:ring-offset-gray-200 flex-col"
                                                    }`}
                                                  >
                                                    <div className="flex items-center mr-2">
                                                      <input
                                                        type="radio"
                                                        id={`${val.date}-${slot.startTime}`}
                                                        name={val.date.toString()}
                                                        key={val.date.toString()}
                                                        ref={(el) =>
                                                          (radioButtonsRef.current[
                                                            `${val.date}-${slot.startTime}`
                                                          ] = el)
                                                        }
                                                        onChange={(e) => {
                                                          handleAppointmentBookChanges(
                                                            availableTimeSlots[
                                                              i
                                                            ],
                                                            slot
                                                          );

                                                          checkErrorsIfAny();
                                                        }}
                                                      />
                                                      <label
                                                        className="pl-1"
                                                        htmlFor={`${val.date}-${slot.startTime}`}
                                                        onClick={() => {
                                                          handleAppointmentBookChanges(
                                                            availableTimeSlots[
                                                              i
                                                            ],
                                                            slot
                                                          );
                                                          checkErrorsIfAny();
                                                        }}
                                                      >
                                                        {slot.startTime} to{" "}
                                                        {slot.endTime}
                                                      </label>
                                                    </div>
                                                    <div>
                                                      {slot.appointmentsCount >
                                                        0 && (
                                                        <ReactPopover
                                                          content={bookedAppointments.map(
                                                            (app) => {
                                                              return (
                                                                <div className="flex flex-col">
                                                                  <p>
                                                                    {
                                                                      app.clientName
                                                                    }
                                                                  </p>
                                                                  <div className="flex flex-row">
                                                                    <p>
                                                                      {
                                                                        app.startTime
                                                                      }{" "}
                                                                      -{" "}
                                                                      {
                                                                        app.endTime
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                  <hr />
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        >
                                                          <button
                                                            type="button"
                                                            className="text-sm rounded border border-primary-900  p-1 flex items-center bg-primary-50 text-primary-500"
                                                            onClick={() =>
                                                              setBookedAppointments(
                                                                slot.bookedAppointments
                                                              )
                                                            }
                                                          >
                                                            <>
                                                              <span className="mr-2">
                                                                +
                                                                {
                                                                  slot.appointmentsCount
                                                                }{" "}
                                                                booked
                                                              </span>
                                                              <ArrowUpRightIcon className="h-4" />
                                                            </>
                                                          </button>
                                                        </ReactPopover>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}
                        {errors.slotToBook && (
                          <div className="my-2 px-2 py-1 bg-error-100 rounded outline outline-1 outline-error-600">
                            <span>
                              {errors.slotToBook?.message?.toString() ?? ""}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="w-full flex bg-gray-50 mt-4">
                        <div className=" w-full flex flex-col md:flex-row m-2">
                          <div className="flex flex-col">
                            <div className="flex flex-col  w-full md:px-6 mt-2 ">
                              <span className="block text-base font-semibold leading-6 text-grey-900">
                                Mode of Consultation
                              </span>
                              <div className="flex">
                                <label className="font-normal mr-1 text-xs md:text-base">
                                  <input
                                    className={`ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 mr-1`}
                                    type="radio"
                                    name="radioValues"
                                    value="In-Clinic"
                                    checked={
                                      selectedConsultation === "In-Clinic"
                                    }
                                    onChange={(e) => {
                                      handleOnlineOfflineAppointment(e);
                                    }}
                                  />
                                  In-Clinic
                                </label>
                                <label className="font-normal mr-1 text-xs md:text-base">
                                  <input
                                    className={`ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 mr-1`}
                                    type="radio"
                                    name="radioValues"
                                    value="homevisit"
                                    checked={
                                      selectedConsultation === "homevisit"
                                    }
                                    onChange={(e) => {
                                      handleOnlineOfflineAppointment(e);
                                    }}
                                  />
                                  Home Visit
                                </label>
                                <label className="font-normal mr-1 text-xs md:text-base">
                                  <input
                                    className={`ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 mr-1`}
                                    type="radio"
                                    name="radioValues"
                                    value="Online"
                                    checked={selectedConsultation === "Online"}
                                    disabled={
                                      requestedAppointments.length > 1
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      handleOnlineOfflineAppointment(e);
                                    }}
                                  />
                                  Remote/Online
                                </label>
                              </div>
                              {selectedConsultation === "Online" &&
                                !entityUserWithFreeTimeSlots?.isGoogleAuthEnabled && (
                                  <div className="my-2 px-4 py-2 bg-[#FFF400] rounded shadow flex items-center justify-between border border-[#FFF600]">
                                    <span className="flex flex-col">
                                      The selected physiotherapy is not logged
                                      in with Google! Please login with Google
                                      to avail online appointments
                                    </span>
                                  </div>
                                )}
                            </div>
                            {requestedAppointments.length > 1 &&
                              selectedConsultation === "Online" && (
                                <div
                                  key="comingSoonBanner"
                                  className="my-2 px-4 py-2 bg-[#FFF400] rounded shadow flex items-center justify-between border border-[#FFF600]"
                                >
                                  <span className="text-black">
                                    Video Consultation for recurring
                                    appointments coming soon
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="flex flex-col mt-2 md:pr-6">
                            <span className="text-base font-semibold">
                              Additional Notes
                            </span>
                            <span className="text-xs text-gray-500 mb-2">
                              Private note specific to the appointment for
                              Physiotherapist's reference
                            </span>
                            <textarea
                              id="message"
                              rows={3}
                              onChange={(e) =>
                                setAppointmentNotes(e.target.value)
                              }
                              className="mt-2 block w-full rounded-lg border border-gray-300 focus:outline-none focus:border-primary-600 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
                              placeholder="Enter additional notes..."
                            ></textarea>
                          </div>
                          {selectedConsultation === "homevisit" && (
                            <div className="flex flex-col mt-2">
                              <span className="text-base font-semibold">
                                Patient Address
                              </span>
                              <span className="text-xs text-gray-500 mb-2">
                                *Address should be compliant to google maps
                              </span>
                              <textarea
                                id="message"
                                rows={3}
                                value={selectedPatientAddress || ""}
                                onChange={(e) =>
                                  setSelectedPatientAddress(e.target.value)
                                }
                                className="mt-2 block w-full rounded-lg border border-gray-300 focus:outline-none focus:border-primary-600 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
                                placeholder="Enter address..."
                              ></textarea>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-50 items-center p-4 ">
                    <div className="flex flex-row w-full justify-end">
                      <OutlinedButton
                        variant={ButtonVariant.PRIMARY}
                        type="button"
                        onClick={closeModal}
                        children="Close"
                        className="mr-2"
                      />
                      <OutlinedButton
                        variant={ButtonVariant.SECONDARY}
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        isDisabled={
                          selectedConsultation === "Online" &&
                          !entityUserWithFreeTimeSlots?.isGoogleAuthEnabled
                        }
                        children="Book Appointment"
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
