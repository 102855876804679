import React, { useEffect, useState } from "react";
import ServiceSelector from "./ServiceSelector";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ValidationError } from "@components/hook-form";
import Applabel from "@components/hook-form/applabel";
import { Service } from "../types/Service";
import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";

export const AuditSchema = z.object({
  createdBy: z.string(),
  lastUpdatedBy: z.string(),
  createdAt: z.coerce.date(),
  lastUpdatedAt: z.coerce.date(),
});
export type Audit = z.infer<typeof AuditSchema>;

export const PlanServiceSchema = z.object({
  serviceId: z.string(),
  quantity: z.number(),
});
export type PlanService = z.infer<typeof PlanServiceSchema>;

export const PlanSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  entityId: z.string(),
  entityBranchId: z.string(),
  services: z.array(PlanServiceSchema),
  totalPrice: z.coerce.number(),
  description: z.string(),
  isActive: z.string(),
  audit: AuditSchema,
});
export type Plan = z.infer<typeof PlanSchema>;

interface PlanFormProps {
  initialData?: Plan;
  services?: Service[];
  branchId: string;
  closeForm: () => void;
  isView?: boolean;
}

const PlanForm = ({
  initialData,
  branchId,
  closeForm,
  isView,
}: PlanFormProps) => {
  const userData = getUserPersistedOnLocalStorage();
  const [services, setServices] = useState<Service[]>([]);

  async function getServices() {
    try {
      const paramsMap = new Map<string, string>([["branchId", branchId]]);
      const filteredEndpoint = replaceParams(
        endpoints.branch.services,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      if (response.status === 200) {
        setServices(response.data["data"]);
      } else {
        toast.error(response.data["message"]);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    async function _init_() {
      await getServices();
    }

    _init_();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<Plan>({
    resolver: zodResolver(PlanSchema),
    defaultValues: {
      id: initialData?.id ?? "",
      name: initialData?.name ?? "",
      description: initialData?.description ?? "",
      isActive: initialData?.isActive ? "true" : "false",
      services: initialData?.services ?? [],
      totalPrice: initialData?.totalPrice ?? 0,
      entityId: userData?.entity_id,
      entityBranchId: branchId,
      audit: {
        createdAt: new Date(),
        lastUpdatedAt: new Date(),
        createdBy: userData?.user_id,
        lastUpdatedBy: userData?.user_id,
      },
    },
  });

  const onSubmit: SubmitHandler<Plan> = async (data) => {
    try {
      if (initialData) {
        const editedData = {
          ...data,
          isActive: data?.isActive === "true" ? true : false,
          audit: {
            lastUpdatedAt: new Date(),
            lastUpdatedBy: userData?.user_id,
          },
        };
        const paramsMap = new Map([["id", initialData.id ?? ""]]);
        const filteredEndpoint = replaceParams(
          endpoints.branch.updatePlan,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, editedData);
        if (response.status === 200) {
          toast.success("Plan updated successfully");
        } else {
          toast.error("Something went wrong");
        }
      } else {
        const finalData = {
          ...data,
          isActive: data?.isActive === "true" ? true : false,
        };
        const response = await axios.post(
          endpoints.branch.createPlan,
          finalData
        );
        if (response.status === 200) {
          toast.success("Plan created successfully");
        } else {
          toast.error("Something went wrong");
        }
      }
      closeForm();
    } catch (error) {
      toast.error(error);
    }
  };

  console.log("errors", errors);

  const handleServicesChange = (newServices: PlanService[]) => {
    setValue("services", newServices);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg pt-2 max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-0">
        <h2 className="text-2xl font-bold text-gray-900 px-6">
          {isView ? "View Plan" : initialData ? "Edit Plan" : "Create New Plan"}
        </h2>
      </div>
      <hr></hr>
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="space-y-2 px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <Applabel label="Plan Name" mandatory></Applabel>
              <input
                {...register("name")}
                value={watch("name")}
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                disabled={isView}
              />
              {errors.name && (
                <ValidationError
                  message={errors.name?.message?.toString() ?? ""}
                />
              )}
            </div>

            <div>
              <Applabel label="Status"></Applabel>
              <select
                {...register("isActive")}
                value={watch("isActive")}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                disabled={isView}
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {errors.isActive && (
                <ValidationError
                  message={errors.isActive?.message?.toString() ?? ""}
                />
              )}
            </div>
          </div>

          <div className="flex flex-col">
            <Applabel label="Description"></Applabel>
            <input
              {...register("description")}
              value={watch("description")}
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              disabled={isView}
            />
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Select Services
              </label>
              <span className="text-sm text-gray-500">
                {watch("services")?.length || 0} services selected
              </span>
            </div>
            <ServiceSelector
              services={services}
              selectedServices={watch("services") || []}
              onChange={handleServicesChange}
              isView={isView}
            />
            {errors.services && (
              <ValidationError
                message={errors.services?.message?.toString() ?? ""}
              />
            )}
          </div>

          <div className="bg-gray-50 pl-4 py-4 rounded-lg">
            <div className="flex justify-end items-center">
              <span className="text-lg font-medium text-gray-900 mr-2">
                Total Cost:
              </span>
              <div className="flex flex-row items-center relative">
                <span className="absolute inset-y-0 left-3 flex items-center text-gray-500">
                  ₹
                </span>
                <input
                  {...register("totalPrice")}
                  value={watch("totalPrice")}
                  type="number"
                  className="pl-8 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm w-1/2"
                  disabled={isView}
                />
              </div>
              {errors.totalPrice && (
                <ValidationError
                  message={errors.totalPrice?.message?.toString() ?? ""}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
          {isView ? (
            <div></div>
          ) : (
            <OutlinedButton
              variant={ButtonVariant.PRIMARY}
              type="button"
              onClick={closeForm}
              children="Close"
              className="mr-2"
            />
          )}

          <div className="flex justify-end gap-4">
            {isView ? (
              <button
                type="button"
                className="px-4 py-2 bg-primary-600 text-white rounded-xl hover:bg-secondary-700"
                onClick={closeForm}
              >
                Close
              </button>
            ) : (
              <button
                type="submit"
                className="px-4 py-2 bg-primary-600 text-white rounded-xl hover:bg-secondary-700"
              >
                {initialData ? "Update Plan" : "Create Plan"}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PlanForm;
