import React, { useState, useRef, useEffect } from "react";
import { PlusCircle, Trash2, ChevronDown } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { TaxItem } from "../types/Invoice";

interface TaxListProps {
  taxes: TaxItem[];
  onTaxesChange: (taxes: TaxItem[]) => void;
  readOnly?: boolean;
}

const TAX_OPTIONS = [
  "GST",
  "VAT",
  "SERVICE TAX",
  "CGST",
  "SGST",
  "IGST",
  "OTHER",
];

export default function TaxList({
  taxes,
  onTaxesChange,
  readOnly = false,
}: TaxListProps) {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [currentTaxes, setCurrentTaxes] = useState<{ [key: string]: string }>(
    {}
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const addTax = () => {
    const newTax: TaxItem = {
      id: uuidv4(),
      name: "",
      rate: 0,
      type: "gst",
    };
    onTaxesChange([...taxes, newTax]);
  };

  const updateTax = (id: string, field: keyof TaxItem, value: any) => {
    const updatedTaxes = taxes.map((tax) =>
      tax.id === id ? { ...tax, [field]: value } : tax
    );
    onTaxesChange(updatedTaxes);
  };

  const deleteTax = (id: string) => {
    onTaxesChange(taxes.filter((tax) => tax.id !== id));
  };

  const handleTaxSelect = (id: string, value: string) => {
    const standardType = value.toUpperCase() as TaxItem["type"];
    if (value === "OTHER") {
      updateTax(id, "type", "other");
      setEditingId(id);
    } else {
      updateTax(id, "type", standardType);
      setEditingId(null);
    }
    setDropdownOpen(null);
  };

  const handleInputChange = (id: string, value: string) => {
    setCurrentTaxes((prev) => ({ ...prev, [id]: value }));
    if (editingId === id) {
      updateTax(id, "name", value);
    } else {
      const matchedTax = TAX_OPTIONS.find((tax) =>
        tax.toUpperCase().startsWith(value.toUpperCase())
      );

      if (matchedTax) {
        handleTaxSelect(id, matchedTax);
      } else {
        updateTax(id, "type", "other");
        updateTax(id, "name", value);
      }
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between mb-2">
        <label className="text-sm text-gray-600">Taxes</label>
        {!readOnly && (
          <button
            onClick={addTax}
            className="flex items-center gap-1 text-primary-600 hover:text-primary-700 text-sm"
          >
            <PlusCircle className="h-3 w-3" />
            Add Tax
          </button>
        )}
      </div>

      <div className="space-y-2">
        {taxes &&
          taxes.map((tax) => (
            <div key={tax.id} className="flex items-center gap-2">
              <div className="relative flex-1" ref={dropdownRef}>
                <div
                  className="text-sm flex items-center cursor-pointer h-9"
                  onClick={() =>
                    setDropdownOpen(dropdownOpen === tax.id ? null : tax.id)
                  }
                  aria-expanded={dropdownOpen === tax.id}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) =>
                    e.key === "Enter" &&
                    setDropdownOpen(dropdownOpen === tax.id ? null : tax.id)
                  }
                >
                  <input
                    type="text"
                    className="input-field text-sm flex-1 outline-none bg-transparent"
                    value={currentTaxes[tax.id] || tax.type.toUpperCase()}
                    onChange={(e) => {
                      handleInputChange(tax.id, e.target.value);
                    }}
                    placeholder="Select tax type or enter custom"
                    aria-label="Tax type selection"
                  />
                  <ChevronDown className="h-4 w-4 text-gray-400" />
                </div>

                {dropdownOpen === tax.id && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                    <div className="py-1">
                      {TAX_OPTIONS.map((taxType) => (
                        <div
                          key={taxType}
                          className="px-3 py-1.5 text-sm hover:bg-gray-50 cursor-pointer"
                          onClick={() => {
                            handleInputChange(tax.id, taxType);
                            setDropdownOpen(null);
                          }}
                        >
                          {taxType}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="relative w-24">
                <span className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400">
                  %
                </span>
                <input
                  type="number"
                  className="number-input pl-6 text-sm text-right h-9"
                  value={tax.rate}
                  min="0"
                  max="100"
                  step="0.1"
                  onChange={(e) =>
                    updateTax(tax.id, "rate", parseFloat(e.target.value))
                  }
                />
              </div>

              <button
                onClick={() => deleteTax(tax.id)}
                className="text-gray-400 hover:text-red-500"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
