import axios, { AxiosRequestConfig } from "axios";
// config
import { HOST_API } from "../config-global";
import { useAuthContext } from "@authentication/hooks/use-auth-context";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { STORAGE_KEYS } from "@constants/storage-keys";

// ----------------------------------------------------------------------

// const axiosInstance = axios.create({
//   baseURL: 'http://13.235.109.230:8081',
//   // baseURL: HOST_API,
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   withCredentials: true,
// });

// // axios.interceptors.request.use(
// //   async (config) => {
// //     const accessToken = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.ACCESS_TOKEN);

// //     config.headers = {
// //       'Authorization': `Bearer ${accessToken}`,
// //       'Accept': 'application/json',
// //       'Content-Type': 'application/x-www-form-urlencoded',
// //     };
// //     return config;
// //   },
// //   (error) => {
// //     Promise.reject(error);
// //   }
// // );
// // // Response interceptor for API calls
// // axios.interceptors.response.use(
// //   (response) => {
// //     return response;
// //   },
// //   async function (error) {
// //     const userData = getUserPersistedOnLocalStorage();
// //     const email = userData?.email;
// //     const refreshToken = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.REFRESH_TOKEN);
// //     const originalRequest = error.config;
// //     if (error.response.status === 403 && !originalRequest._retry) {
// //       originalRequest._retry = true;
// //       const accessToken = await refresh(email ?? '', refreshToken ?? '');

// //       axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
// //       return axiosInstance(originalRequest);
// //     }
// //     return Promise.reject(error);
// //   }
// // );

// export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axios.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------
export const replaceParams = (
  url: string,
  params: Map<string, string>,
  queryParams?: Map<string, string>
) => {
  let tempUrl = url;
  params.forEach((value, key) => {
    const toFind: string = `:${key}`;
    tempUrl = tempUrl.replace(toFind, value.toString());
  });
  if (queryParams && queryParams.size > 0) {
    const queryString = Array.from(queryParams.entries())
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    tempUrl += `?${queryString}`;
  }
  return tempUrl;
};
export const endpoints = {
  themeData: "/api/v1/theme-data",
  auth: {
    login: "/api/v1/auth/login",
    loginWithGoogle: "/api/v1/auth/google/oauth/login",
    logout: "/api/v1/secure/logout",
    forgotpassword: "/api/v1/auth/forgot-password",
    verifypassword: "/api/v1/auth/verify-password",
    resetpassword: "/api/v1/auth/reset-password",
    refresh: "/api/v1/auth/refreshToken",
  },
  health: {
    healthcheck: "/api/v1/auth/healthcheck",
  },
  entity: {
    list: "/api/v1/secure/entities/list",
    onboard: "/api/v1/secure/entity",
    update: "/api/v1/secure/entity/:id",
    getworkinghours: "/api/v1/secure/entity/:entityId/working-hours",
    updateworkinghours: "/api/v1/secure/entity/:entityId/working-hours",
    validateWorkingHours: "/api/v1/secure/working-hours",
    view: "/api/v1/secure/entity/:entityId",
    branches: "/api/v1/secure/entity/:entityId/branches/list",
    staff: "/api/v1/secure/entity/:entityId/user/list",
    patients: "/api/v1/secure/entity/:entityId/client/list",
    therapy: "/api/v1/secure/entity/:id/therapies",
    addFeedbackForm: "/api/v1/secure/feedbackform",
    updateFeedbackForm: "/api/v1/secure/feedbackform/:id",
    fetchFeedbackForm: "/api/v1/secure/feedbackform/:entityId",
    fetchClientConsent: "/api/v1/secure/entity/:id/consentform",
    addClientConsent: "/api/v1/secure/entity/:id/consentform",
    fetchClientNotification: "/api/v1/secure/entity/:id/emailsetting",
    updateClientNotification: "/api/v1/secure/entity/:id/emailsetting",
    fetchAppointmentSetting: "/api/v1/secure/entity/:id/appointmentsetting",
    updateAppointmentSetting: "/api/v1/secure/entity/:id/appointmentsetting",
    deleteAppointmentType:
      "/api/v1/secure/entity/:id/appointmenttypeduration/:type",
    updateProfilePicture: "/api/v1/secure/entity/:id/logo/update",
    getEntityPhoneNumberMask: "/api/v1/secure/entity/:id/phonenosetting",
    updateEntityPhoneNumberMask: "/api/v1/secure/entity/:id/phonenosetting",
    getAppointmentPaymentDetails:
      "/api/v1/secure/entity/:id/appointmenttypesetting",
  },
  appointment: {
    list: "/api/v1/secure/appointments/all",
    view: "/api/v1/secure/appointment/:id",
    availableTimeSlots: "/api/v1/secure/users/available-slots",
    arrayOfAvailabletimeSlots: "/api/v1/secure/user/:userId/available-slots",
    createAppointment: "/api/v1/secure/appointment",
    recurringAppointment: "/api/v1/secure/recurringappointments",
    cancelAppointment: "/api/v1/secure/appointments/:id/cancel",
    rescheduleAppointment:
      "/api/v1/secure/appointment/reschedule/:appointmentId",
    quickAddPatient: "/api/v1/secure/client",
    fetchAppointmentDuration:
      "/api/v1/secure/entity/:id/appointmenttypeduration",
    transfer: "/api/v1/secure/appointment/:id/transfer",
    getPayouts: "/api/v1/secure/appointments/entitybranch/:id/payouts",
  },
  staff: {
    list: "/api/v1/secure/users/list",
    onboard: "/api/v1/secure/user",
    update: "/api/v1/secure/user/:id",
    workingHours: "/api/v1/secure/user/:id/working-hours",
    validateWorkingHours: "/api/v1/secure/working-hours",
    view: "/api/v1/secure/user/:staffId",
    appointments: "/api/v1/secure/user/:staffId/appointments",
    checkStaffEmail: "/api/v1/secure/user/userbyemail/:email",
    updateUserStatus: "/api/v1/secure/user/:id/:status",
    getUserStatus: "/api/v1/secure/user/:id/activestatus",
  },
  branch: {
    list: "/api/v1/secure/entity/:entityId/branches/list",
    onboard: "/api/v1/secure/entity/:entityId/branch",
    getworkinghours:
      "/api/v1/secure/entity/:entityId/branch/:branchId/working-hours",
    updateworkinghours: "/api/v1/secure/entitybranch/:id/working-hours",
    validateWorkingHours: "/api/v1/secure/working-hours",
    update: "/api/v1/secure/entity/:entityId/branch/:branchId",
    view: "/api/v1/secure/entity/:entityId/branch/:branchId",
    staff: "/api/v1/secure/entity/:entityId/branch/:branchId/user/list",
    letterHead: "/api/v1/secure/entitybranch/:id/letterhead",
    services: "/api/v1/secure/services/branch/:branchId/all",
    createService: "/api/v1/secure/services",
    updateService: "/api/v1/secure/services/:id",
    getAllPlans: "/api/v1/secure/plans/branch/:branchId/all",
    createPlan: "/api/v1/secure/plans",
    updatePlan: "/api/v1/secure/plans/:id"
  },
  exercise: {
    list: "/api/v1/secure/exercises",
    onboard: "/api/v1/secure/exercise",
    view: "/api/v1/secure/exercise/:id",
    updateExercise: "/api/v1/secure/exercise/:id",
    addExternalLink: "/api/v1/secure/exercise/:id/external-link",
    addMedia: "/api/v1/secure/exercise/:id/media",
    fetchMedia: "/api/v1/secure/exercise/:id/media",
    updateExternalLink: "/api/v1/secure/exercise/:id/external-link/:linkId",
    deleteExternalLink: "/api/v1/secure/exercise/:id/external-link/:linkId",
    deleteMedia: "/api/v1/secure/exercise/:id/media/:mediaId",
    checkExerciseTitle: "/api/v1/secure/exercisebyname/:name",
    gritupLibrary: "/api/v1/secure/exercises/PlatformLibrary",
  },
  patient: {
    list: "/api/v1/secure/activeclients",
    onboard: "/api/v1/secure/client",
    view: "/api/v1/secure/client/:patientId/personal-information",
    updateInformation: "/api/v1/secure/client/:patientId/personal-information",
    checkPatientEmail: "/api/v1/secure/client/clientbyemail/:email",
    resendOnboardFormLink: "/api/v1/secure/patient/onboard/resend-form-link",
    updateProfilePicture: "/api/v1/secure/client/:patientId/photo/update",
    getPaymentDetails: "/api/v1/secure/client/:patientId/appointments/payment",
    getPaymentAppointments: "/api/v1/secure/client/:id/appointments/payments",
    getUnbilledAppointments: "/api/v1/secure/client/:id/unbilledappointments",
    getPatientProgress: "/api/v1/secure/client/:id/heptrackingcount",
    getPatientRemainingCredits:
      "/api/v1/secure/client/:id/credit-sessions-outstanding-amt",
    getReferrals: "/api/v1/secure/clientreferrals",
    getBillingDetails: "/api/v1/secure/revenue/client/:id/billingdetails",
    downloadPatientDetails: "/api/v1/secure/client/list/download-export",
  },
  medicalHistory: {
    // TODO: Create seprate end point for medical history
    view: "/api/v1/secure/medicalhistory/:id",
    updateMedicalHistory: "/api/v1/secure/medicalhistory/:id",
    addMedicalHistory: "/api/v1/secure/master-assessment/:mid/medicalhistory",
  },
  diagnosis: {
    view: "/api/v1/secure/client/:patientId/diagnosis",
    updateTreatmentCategory:
      "/api/v1/secure/client/:patientId/treatment-category",
    updateProvisionalDiagnosis:
      "/api/v1/secure/client/:patientId/provisional-diagnosis",
    updateChiefComplaints: "/api/v1/secure/client/:patientId/chief-complaints",
    add: "/api/v1/secure/master-assessment/:mid/diagnosis",
    fetch: "/api/v1/secure/diagnosis/:id",
    fetchEntityDiagnosisSetup: "/api/v1/secure/diagnosissetup/:entityid",
    update: "/api/v1/secure/diagnosis/:id",
  },
  treatmentPlan: {
    view: "/api/v1/secure/treatmentplan/:id",
    update: "/api/v1/secure/treatmentplan/:id",
    therapyList: "/api/v1/secure/entity/:entityId/therapies",
    //Create new Prescription
    createPrescription: "/api/v1/secure/master-assessment/:mid/treatmentplan",
    getOutReferrals: "/api/v1/secure/outreferrals",
  },
  treatmentProtocol: {
    view: "/api/v1/secure/treatmentprotocol/:id",

    add: "/api/v1/secure/master-assessment/:mid/treatmentprotocol",
    fetch: "/api/v1/secure/treatmentprotocol/:id",
    fetchEntityTreatmentProtocolSetup:
      "/api/v1/secure/treatmentprotocolsetup/:entityid",
    update: "/api/v1/secure/treatmentprotocol/:id",
  },
  feedback: {
    view: "/api/v1/secure/client/feedback/:clientid",
  },
  rating: {
    view: "/api/v1/secure/rating/:clientid",
    entityUserView: "/api/v1/secure/entityuserrating/:clientid",
  },
  journal: {
    view: "/api/v1/secure/client/:id/journal",
  },
  sessionNotes: {
    view: "/api/v1/secure/sessionnotes/:id",
    updateSessionNotes: "/api/v1/secure/sessionnotes/:id",
    addSessionNotes: "/api/v1/secure/sessionnotes",
  },
  reports: {
    view: "/api/v1/secure/client/:patientId/reports",
    addReport: "/api/v1/secure/client/:patientId/report/upload",
    downloadReport: "/api/v1/secure/client/:patientId/reports/:reportId",
  },
  assessment: {
    masterAssessmentList: "/api/v1/secure/master-assessments/:patientId",
    createMasterAssessment: "/api/v1/secure/master-assessment",
    getMasterAssessment: "/api/v1/secure/master-assessment/:masterAssessmentId",
    deleteMasterAssessment: "/api/v1/secure/master-assessment/:id",
    assessmentSetup: "/api/v1/secure/assessmentsetup/entity/:id",

    // VitalAssessment
    getVitalAssessment:
      "/api/v1/secure/master-assessment/:masterAssessmentId/vitals-assessment",
    createVitalAssessment:
      "/api/v1/secure/master-assessment/:masterAssessmentId/vitals-assessment",
    updateVitalAssessment:
      "/api/v1/secure/vitals-assessment/:vitalAssessmentId",
    // GaitAssessment
    getGaitAssessment: "/api/v1/secure/master-assessment/:mid/gait-assessment",
    createGaitAssessment:
      "/api/v1/secure/master-assessment/:mid/gait-assessment",
    updateGaitAssessment: "/api/v1/secure/gait-assessment/:id",
    updateActiveTillDate: "/api/v1/secure/master-assessment/:id",

    // ROMAssessment
    getRomAssessment:
      "/api/v1/secure/master-assessment/:mid/range-of-motion-assessment",
    createRomAssessment:
      "/api/v1/secure/master-assessment/:mid/range-of-motion-assessment",
    updateRomAssessment: "/api/v1/secure/range-of-motion-assessment/:id",

    // MuscleToneAssessment
    getMuscleToneAssessment:
      "/api/v1/secure/master-assessment/:mid/muscle-tone-assessment",
    createMuscleToneAssessment:
      "/api/v1/secure/master-assessment/:mid/muscle-tone-assessment",
    updateMuscleToneAssessment: "/api/v1/secure/muscle-tone-assessment/:id",

    // PostureAssessment
    getPostureAssessment:
      "/api/v1/secure/master-assessment/:mid/posture-assessment",
    createPostureAssessment:
      "/api/v1/secure/master-assessment/:mid/posture-assessment",
    updatePostureAssessment: "/api/v1/secure/posture-assessment/:id",

    // Pain Assessment
    getPainAssessment: "/api/v1/secure/master-assessment/:mid/pain-assessment",
    createPainAssessment:
      "/api/v1/secure/master-assessment/:mid/pain-assessment",
    updatePainAssessment: "/api/v1/secure/pain-assessment/:id",

    //Reflex Assessment
    getReflexAssessment:
      "/api/v1/secure/master-assessment/:mid/reflex-assessment",
    createReflexAssessment:
      "/api/v1/secure/master-assessment/:mid/reflex-assessment",
    updateReflexAssessment: "/api/v1/secure/reflex-assessment/:id",

    //Oedema Assessment
    getOedemaAssessment:
      "/api/v1/secure/master-assessment/:mid/oedema-assessment",
    createOedemaAssessment:
      "/api/v1/secure/master-assessment/:mid/oedema-assessment",
    updateOedemaAssessment: "/api/v1/secure/oedema-assessment/:id",

    //Limb Length Assessment
    getLimbLengthAssessment:
      "/api/v1/secure/master-assessment/:mid/limblength-assessment",
    createLimbLengthAssessment:
      "/api/v1/secure/master-assessment/:mid/limblength-assessment",
    updateLimbLengthAssessment: "/api/v1/secure/limblength-assessment/:id",

    //Limb Girth Assessment
    getLimbGirthAssessment:
      "/api/v1/secure/master-assessment/:mid/limbgirth-assessment",
    createLimbGirthAssessment:
      "/api/v1/secure/master-assessment/:mid/limbgirth-assessment",
    updateLimbGirthAssessment: "/api/v1/secure/limbgirth-assessment/:id",

    //Muscle Wasting Assessment
    getMuscleWastingAssessment:
      "/api/v1/secure/master-assessment/:mid/musclewasting-assessment",
    createMuscleWastingAssessment:
      "/api/v1/secure/master-assessment/:mid/musclewasting-assessment",
    updateMuscleWastingAssessment:
      "/api/v1/secure/musclewasting-assessment/:id",

    //Balance Assessment
    getBalanceAssessment:
      "/api/v1/secure/master-assessment/:mid/balance-assessment",
    createBalanceAssessment:
      "/api/v1/secure/master-assessment/:mid/balance-assessment",
    updateBalanceAssessment: "/api/v1/secure/balance-assessment/:id",

    //MuscleTightness Assessment
    getMuscleTightnessAssessment:
      "/api/v1/secure/master-assessment/:mid/muscleTightness-assessment",
    createMuscleTightnessAssessment:
      "/api/v1/secure/master-assessment/:mid/muscleTightness-assessment",
    updateMuscleTightnessAssessment:
      "/api/v1/secure/muscleTightness-assessment/:id",

    //CustomAssessment Assessment
    getCustomAssessment:
      "/api/v1/secure/master-assessment/:mid/custom-assessment",
    createCustomAssessment:
      "/api/v1/secure/master-assessment/:mid/custom-assessment",
    updateCustomAssessment: "/api/v1/secure/custom-assessment/:id",
    getDropDownFormat:
      "/api/v1/secure/custom-assessment/entity-format/:entityId",

    //Sensory Assessment
    getSensoryAssessment:
      "/api/v1/secure/master-assessment/:mid/sensory-assessment",
    createSensoryAssessment:
      "/api/v1/secure/master-assessment/:mid/sensory-assessment",
    updateSensoryAssessment: "/api/v1/secure/sensory-assessment/:id",

    //Scar Assessment
    getScarAssessment: "/api/v1/secure/master-assessment/:mid/scar-assessment",
    createScarAssessment:
      "/api/v1/secure/master-assessment/:mid/scar-assessment",
    updateScarAssessment: "/api/v1/secure/scar-assessment/:id",

    //MuscleStrength Assessment
    getMuscleStrengthAssessment:
      "/api/v1/secure/master-assessment/:mid/strength-assessment",
    createMuscleStrengthAssessment:
      "/api/v1/secure/master-assessment/:mid/strength-assessment",
    updateMuscleStrengthAssessment: "/api/v1/secure/strength-assessment/:id",

    //Specail Test Assessment
    getSpecialTestAssessment:
      "/api/v1/secure/master-assessment/:mid/specialtests-assessment",
    createSpecialTestAssessment:
      "/api/v1/secure/master-assessment/:mid/specialtests-assessment",
    updateSpecialTestAssessment: "/api/v1/secure/specialtests-assessment/:id",

    //NerveTest Assessment
    getNerveTestAssessment:
      "/api/v1/secure/master-assessment/:mid/nervetest-assessment",
    createNerveTestAssessment:
      "/api/v1/secure/master-assessment/:mid/nervetest-assessment",
    updateNerveTestAssessment: "/api/v1/secure/nervetest-assessment/:id",

    //Structural Deformities Assessment
    getStructuralDeformitiesAssessment:
      "/api/v1/secure/master-assessment/:mid/structuraldeformities-assessment",
    createStructuralDeformitiesAssessment:
      "/api/v1/secure/master-assessment/:mid/structuraldeformities-assessment",
    updateStructuralDeformitiesAssessment:
      "/api/v1/secure/structuraldeformities-assessment/:id",

    //Vertigo  Assessment
    getVertigoAssessment:
      "/api/v1/secure/master-assessment/:mid/vertigo-assessment",
    createVertigoAssessment:
      "/api/v1/secure/master-assessment/:mid/vertigo-assessment",
    updateVertigoAssessment: "/api/v1/secure/vertigo-assessment/:id",

    //ADL
    getADLQuestions: "/api/v1/secure/adlassessmentsetup/entity/:id",
    createADLAssessment: "/api/v1/secure/master-assessment/:mid/adlassessment",
    updateADLAssessment: "/api/v1/secure/adlassessment/:id",

    getADLAssessment: "/api/v1/secure/master-assessment/:mid/adlassessment",
    getADLGraphData:
      "/api/v1/secure/adlassessment/client/:id/category/:category",
  },
  ownerDashboard: {
    appointmentsCount:
      "/api/v1/secure/appointments/entitybranch/:id/dashboardstatuscount",
    appointmentsCountv2:
      "/api/v1/secure/appointments/entitybranch/:id/appointmentstatusstatics",
    patientsCount:
      "/api/v1/secure/appointments/entitybranch/:id/dashboardclientcount",
    revenueCount: "/api/v1/secure/revenue/entitybranch/:id/getrevenuedetails",
    branches: "/api/v1/secure/entity/:entityId/branches/list",
    patientStats: "api/v1/secure/revenue/entitybranch/:id/getclientcounts",
    updatePayoutStatus: "api/v1/secure/user/:id/payout/:status",
    getStaffOccupancy:
      "/api/v1/secure/appointments/entitybranch/:id/totalappointmentsbystaff",
    getStaffRating:
      "/api/v1/secure/entityuserrating/entitybranch/:id/averagerating",
    getStaffRevenue:
      "/api/v1/secure/appointments/entitybranch/:id/revenuebystaff",
  },
  practitionerDashboard: {
    appointmentsCount:
      "/api/v1/secure/appointments/entityuser/dashboardstatuscount",
    patientsCount:
      "/api/v1/secure/appointments/entityuser/dashboardclientcount",
    appointmentListByStatus: "/api/v1/secure/appointment/all/by-status",
    updateAppointmentStatus:
      "/api/v1/secure/appointment/updatestatus/:id/:status",
  },
  assistantDashboard: {
    appointmentsCount:
      "/api/v1/secure/appointments/entitybranch/:id/dashboardstatuscount",
    patientsCount:
      "/api/v1/secure/appointments/entitybranch/:id/dashboardclientcount",
    appointmentListByStatus: "/api/v1/secure/appointments/branch/:id/by-status",
    updateAppointmentStatus:
      "/api/v1/secure/appointment/updatestatus/:id/:status",
  },
  branchAdminDashboard: {
    appointmentsCount:
      "/api/v1/secure/appointments/entitybranch/:id/dashboardstatuscount",
    appointmentsCountv2:
      "/api/v1/secure/appointments/entitybranch/:id/appointmentstatusstatics",
    patientsCount:
      "/api/v1/secure/appointments/entitybranch/:id/dashboardclientcount",
    revenueCount: "/api/v1/secure/revenue/entitybranch/:id/getrevenuedetails",
  },
  payment: {
    add: "/api/v1/secure/payment",
    updatePaymetDetails: "/api/v1/secure/paymentDetails",
    view: "/api/v1/secure/payment/:appointmentId",
    payout: "/api/v1/secure/payout",
    refund: "/api/v1/secure/refund",
    refundDetails: "/api/v1/secure/refund/client/:id",
  },
  managePatient: {
    getAssignedExercises: "/api/v1/secure/treatmentplan/:id/exercise-program",
    getSingleAssignedExercise:
      "/api/v1/secure/client/:patientId/exercise-program/exercise/:exerciseId",
    getSingleExerciseMedia: "/api/v1/secure/exercise/:exerciseId/media",
    getSingleExternalLink: "/api/v1/secure/exercise/:id/external-links",
    deleteSingleAssignedExercice:
      "/api/v1/secure/treatmentplan/:id/exercise-program/exercise/:exerciseId",
    getAvailableExercises:
      "/api/v1/secure/treatmentplan/:id/available-exercises",
    updateAssignedExerciseDetails:
      "/api/v1/secure/treatmentplan/:id/exercise-program",
    updateClientHomeExercisePromramme:
      "/api/v1/secure/treatmentplan/:id/exercise-program",
    updateClientHomeExerciseProgrameStatus:
      "/api/v1/secure/treatmentplan/:id/exercise-program-status/:status",
    updateImportantNote: "/api/v1/secure/client/:id/notes",
  },
  // mail: {
  //   list: '/api/mail/list',
  //   details: '/api/mail/details',
  //   labels: '/api/mail/labels',
  // },
  // post: {
  //   list: '/api/post/list',
  //   details: '/api/post/details',
  //   latest: '/api/post/latest',
  //   search: '/api/post/search',
  // },
  // product: {
  //   list: '/api/product/list',
  //   details: '/api/product/details',
  //   search: '/api/product/search',
  // },
  dashboardInitialData: {
    initialData: "/api/v1/secure/entity/:userid/initialdata",
  },
  expense: {
    addExpense: "/api/v1/secure/expense",
    getExpenseForEntity: "/api/v1/secure/expense/entitybranch/:id",
    getExpenseForEntityByMonth:
      "/api/v1/secure/expense/bymonth/entitybranch/:id",

    deleteExpense: "/api/v1/secure/expense/:id",
  },
  services: {
    getActiveServicesForBranch: "/api/v1/secure/services/branch/:branchId/active",
    getActiveServicesForEntity: "/api/v1/secure/services/entity/:entityId/active",
    getActivePlansForBranch: "/api/v1/secure/plans/branch/:branchId/active",
    getActivePlansForEntity: "/api/v1/secure/plans/entity/:entityId/active"

  },

  plans: {
    getActiveServicesForBranch: "/api/v1/secure/plans/branch/:branchId/active"
  },

  invoice: {
    addExpense: "/api/v1/secure/invoice",
    addBilling: "/api/v1/secure/billing",
    getBillsForEntity: "/api/v1/secure/billing/entity/:entityId",
    getBillsForBranch: "/api/v1/secure/billing/:entityId/branch/:branchId",
    getBillsForClient: "/api/v1/secure/billing/client/:clientId",
    deleteExpense: "/api/v1/secure/invoice/:id",
    getBillDetails: "/api/v1/secure/billing/:id",
    payBill: "/api/v1/secure/billing/:id/pay",
    lineItem: "/api/v1/secure/billing/:id/lineItem",
    removeLineItem: "/api/v1/secure/billing/:id/lineItem/:itemId",
    addTax: "/api/v1/secure/billing/:id/tax",
    printBillDetails: "/api/v1/secure/billing/print/:id",
    updateBillingDate: "/api/v1/secure/billing/:id/invoiceDate",
    updateBilling: "/api/v1/secure/billing/:id",
    updatePaymentDetails: "/api/v1/secure/billing/:id/paymentDetails",

  },
  referredDocsAndHospitals: {
    getReferredDocsAndHospitals: "/api/v1/secure/clientreferralswithcode",
    getBillsForReferrals: "/api/v1/secure/revenue/referral/billingdetails",
    getBillsExcelSheet:
      "/api/v1/secure/revenue/referral/downloadbillingdetails",
  },
  credits: {
    whatsappCreditDetails: "/api/v1/secure/messagecredit/entity/:entityid",
    whatsappRemainingCreditDetails:
      "/api/v1/secure/messagecredit/remainingcredits/entity/:entityid",
    whatsappMessageDetails: "/api/v1/secure/whatsappmessage/entitybranch/:id",
  },
};
