import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import BranchInformationComponent from "./BranchInformationComponent";
import StaffComponent from "./StaffComponent";
import ConfigurationStep from "./ConfigurationStep";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import LetterHeadPage from "./LetterHead";
import ServicesConfigurationPage from "./ServicesConfigurationPage";
import Plans from "./Plans";

interface Tab {
  name: string;
  current: boolean;
}

const TAB_NAMES = [
  "Information",
  "Working Hours",
  "Staff",
  "Letter Head",
  "Services",
  "Plans"
] as const;

type TabName = typeof TAB_NAMES[number];

function ViewBranchPage() {
  const { branchId, entityId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigator = useNavigate();
  const userData = getUserPersistedOnLocalStorage();
  
  const [timeZone, setBranchTimeZone] = useState("");
  const [hasTimeZoneChanged, setHasTimeZoneChanged] = useState(false);

  // Get initial tab from URL or default to "Information"
  const initialTab = (searchParams.get("tab") || "Information") as TabName;
  const [activeTab, setActiveTab] = useState<TabName>(initialTab);

  const handleTabClick = (tabName: TabName) => {
    setActiveTab(tabName);
    setSearchParams({ tab: tabName, name: searchParams.get("name") || "" });
  };

  const tabComponents: Record<TabName, React.ReactNode> = {
    "Information": (
      <BranchInformationComponent
        branchId={branchId ?? ""}
        entityId={userData?.entity_id ?? ""}
        setBranchTimeZone={setBranchTimeZone}
        hasTimeZoneChanged={setHasTimeZoneChanged}
      />
    ),
    "Working Hours": (
      <ConfigurationStep
        branchId={branchId ?? ""}
        entityId={userData?.entity_id ?? ""}
        timeZone={timeZone}
        hasTimeZoneChanged={hasTimeZoneChanged}
      />
    ),
    "Staff": (
      <StaffComponent
        branchId={branchId ?? ""}
        entityId={userData?.entity_id ?? ""}
      />
    ),
    "Letter Head": <LetterHeadPage branchId={branchId ?? ""}></LetterHeadPage>,
    // Services: <ServicesConfigurationPage></ServicesConfigurationPage>,
    "Services": (
      <ServicesConfigurationPage
        branchId={branchId ?? ""}
        entityId={userData?.entity_id ?? ""}
      />
    ),
    "Plans": <Plans onAdd={() => {}} onDelete={() => {}} onEdit={() => {}} branchId={branchId ?? ""} />
    // Patients: (
    //   <PatientComponent branchId={location.state.id} entityId={userData?.entity_id ?? ''} />
    // ),
  };

  const tabs: Tab[] = TAB_NAMES.map((name) => ({
    name,
    current: activeTab === name
  }));

  return (
    <>
      <div className="flex flex-col">
        <div className="flex-shrink">
          <div className="border-b border-gray-200 pb-5 sm:pb-0">
            <div className="flex flex-row justify-between">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {searchParams.get("name")}
              </h3>
              <Button
                variant={ButtonVariant.PRIMARY}
                type="submit"
                className="ml-3"
                onClick={() => navigator(-1)}
              >
                Back
              </Button>
            </div>
            <div className="mt-3 sm:mt-4">
              {/* Mobile dropdown */}
              <div className="block md:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  value={activeTab}
                  onChange={(e) => handleTabClick(e.target.value as TabName)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.name}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* Desktop tabs */}
              <div className="mt-3 sm:mt-4 hidden md:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={`${
                        tab.current
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      } whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium focus:outline-none`}
                      onClick={() => handleTabClick(tab.name as TabName)}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
        {tabComponents[activeTab]}
      </div>
    </>
  );
}

export default ViewBranchPage;