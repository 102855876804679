import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import { AssessmentDetail, Convert } from "./type/ScarDetails";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import moment from "moment";

type ScarAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function ScarAssessmentComponent(props: ScarAssessmentProps) {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [scarPresentAbsent, setScarPresentAbsent] = React.useState("");
  const [scarLocation, setScarLocation] = React.useState("");
  const [scarLength, setScarLength] = React.useState("");
  const [scarHealedUnhealed, setScarHealedUnhealed] = React.useState("");
  const axios = useAxios();
  const userData = getUserPersistedOnLocalStorage();

  const [lastUpdateAt, setLastUpdatedAt] = React.useState("");

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getScarAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const converted = Convert.toScarDetails(JSON.stringify(data));
          converted.assessmentDetails.map((e: AssessmentDetail) => {
            if (e.name === "Scar Present/Absent") {
              setScarPresentAbsent(e.observation);
            } else if (e.name === "Location") {
              setScarLocation(e.observation);
            } else if (e.name === "Length") {
              setScarLength(e.observation);
            } else if (e.name === "Healed/Unhealed") {
              setScarHealedUnhealed(e.observation);
            }
          });

          setLastUpdatedAt(data["audit"]["lastUpdatedAt"]);

          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Scar assessment:", error);

          setLoading(false);
        }
      }
    };
    initializeAssessment();
  }, []);

  const onSubmit = async () => {
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateScarAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, {
          id: props.assessmentId,
          masterAssessmentId: props.masterAssessmentId,
          clientId: props.patientId,
          entityId: userData?.entity_id ?? "",
          entityBranchId: userData?.branch_id ?? "",
          assessmentDetails: [
            {
              name: "Scar Present/Absent",
              observation: scarPresentAbsent,
            },
            {
              name: "Location",
              observation: scarLocation,
            },
            {
              name: "Length",
              observation: scarLength,
            },
            {
              name: "Healed/Unhealed",
              observation: scarHealedUnhealed,
            },
          ],
        });
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Scar Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    } else {
      try {
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createScarAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, {
          masterAssessmentId: props.masterAssessmentId,
          clientId: props.patientId,
          entityId: userData?.entity_id ?? "",
          entityBranchId: userData?.branch_id ?? "",
          assessmentDetails: [
            {
              name: "Scar Present/Absent",
              observation: scarPresentAbsent,
            },
            {
              name: "Location",
              observation: scarLocation,
            },
            {
              name: "Length",
              observation: scarLength,
            },
            {
              name: "Healed/Unhealed",
              observation: scarHealedUnhealed,
            },
          ],
        });
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Scar Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col md:flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Scar Assessment
        </h3>
        <p className="text-sm text-gray-500">
          {props.assessmentId !== "000000000000000000000000" &&
            `Last updated: ${
              lastUpdateAt &&
              moment(new Date(lastUpdateAt)).format("DD-MM-YYYY") !==
                "01-01-0001"
                ? moment(new Date(lastUpdateAt)).format("DD-MM-YYYY")
                : "N/A"
            }`}
        </p>
      </div>

      <hr className="w-full" />

      <div className="mt-4">
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
            Scar Assessments
          </div>
          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "75%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "25%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Scar Present/Absent
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    type="text"
                    name={`scarPresentAbsent`}
                    value={scarPresentAbsent}
                    onChange={(e) => setScarPresentAbsent(e.target.value)}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Location</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    type="text"
                    value={scarLocation}
                    name={`location`}
                    onChange={(e) => setScarLocation(e.target.value)}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Length</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    type="text"
                    value={scarLength}
                    onChange={(e) => setScarLength(e.target.value)}
                    name={`length`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Healed/Unhealed
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <input
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    type="text"
                    value={scarHealedUnhealed}
                    onChange={(e) => setScarHealedUnhealed(e.target.value)}
                    name={`healedUnhealed`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
        <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
          <OutlinedButton
            type="button"
            onClick={() => {
              props.postSubmitCallback();
            }}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </OutlinedButton>
          {isDisabled ? (
            <Button
              type="button"
              key="EditButton"
              onClick={() => {
                setIsDisabled(false);
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Edit
            </Button>
          ) : props.assessmentId !== "000000000000000000000000" ? (
            <Button
              type="button"
              key="UpdateButton"
              onClick={onSubmit}
              variant={ButtonVariant.PRIMARY}
            >
              Update
            </Button>
          ) : (
            <Button
              type="button"
              key="CreateButton"
              onClick={onSubmit}
              variant={ButtonVariant.PRIMARY}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
