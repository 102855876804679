import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import moment from "moment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

import { Grid } from "gridjs-react";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";

import InputField from "@components/hook-form/input-field";
import { Convert, MuscleToneAssessment } from "./types/MuscleTone";
import { generateForm } from "./InitialMuscleToneData";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";

export const MuscleToneAssessmentSchema = z.object({
  id: z.string().optional().default(""),
  masterAssessmentId: z.string().optional().default(""),
  clientId: z.string().optional().default(""),
  entityId: z.string().optional().default(""),
  entityBranchId: z.string().optional().default(""),
  // Hip
  hipFlexorsLeft: z.number().optional().default(0),
  hipFlexorsRight: z.number().optional().default(0),
  hipExtensorsLeft: z.number().optional().default(0),
  hipExtensorsRight: z.number().optional().default(0),
  hipAbductorsLeft: z.number().optional().default(0),
  hipAbductorsRight: z.number().optional().default(0),
  hipAdductorsLeft: z.number().optional().default(0),
  hipAdductorsRight: z.number().optional().default(0),
  hipMedialRotationLeft: z.number().optional().default(0),
  hipMedialRotationRight: z.number().optional().default(0),
  hipLateralRotationLeft: z.number().optional().default(0),
  hipLateralRotationRight: z.number().optional().default(0),
  // Shoulder
  shoulderFlexorsLeft: z.number().optional().default(0),
  shoulderFlexorsRight: z.number().optional().default(0),
  shoulderExtensorsLeft: z.number().optional().default(0),
  shoulderExtensorsRight: z.number().optional().default(0),
  shoulderAbductorsLeft: z.number().optional().default(0),
  shoulderAbductorsRight: z.number().optional().default(0),
  shoulderAdductorsLeft: z.number().optional().default(0),
  shoulderAdductorsRight: z.number().optional().default(0),
  shoulderMedialRotationLeft: z.number().optional().default(0),
  shoulderMedialRotationRight: z.number().optional().default(0),
  shoulderLateralRotationLeft: z.number().optional().default(0),
  shoulderLateralRotationRight: z.number().optional().default(0),
  shoulderElevatorsLeft: z.number().optional().default(0),
  shoulderElevatorsRight: z.number().optional().default(0),
  shoulderDepressorsLeft: z.number().optional().default(0),
  shoulderDepressorsRight: z.number().optional().default(0),
  shoulderAntepulsorsLeft: z.number().optional().default(0),
  shoulderAntepulsorsRight: z.number().optional().default(0),
  shoulderRetropulsorsLeft: z.number().optional().default(0),
  shoulderRetropulsorsRight: z.number().optional().default(0),
  // Knee
  kneeFlexorsLeft: z.number().optional().default(0),
  kneeFlexorsRight: z.number().optional().default(0),
  kneeExtensorsLeft: z.number().optional().default(0),
  kneeExtensorsRight: z.number().optional().default(0),
  // Elbow
  elbowFlexorsLeft: z.number().optional().default(0),
  elbowFlexorsRight: z.number().optional().default(0),
  elbowExtensorsLeft: z.number().optional().default(0),
  elbowExtensorsRight: z.number().optional().default(0),
  // Ankle
  ankleDorsiFlexorsLeft: z.number().optional().default(0),
  ankleDorsiFlexorsRight: z.number().optional().default(0),
  anklePlantarFlexorsLeft: z.number().optional().default(0),
  anklePlantarFlexorsRight: z.number().optional().default(0),
  ankleInversorsLeft: z.number().optional().default(0),
  ankleInversorsRight: z.number().optional().default(0),
  ankleEversorsLeft: z.number().optional().default(0),
  ankleEversorsRight: z.number().optional().default(0),
  // Fore-arm
  forearmPronatorsLeft: z.number().optional().default(0),
  forearmPronatorsRight: z.number().optional().default(0),
  forearmSupinatorsLeft: z.number().optional().default(0),
  forearmSupinatorsRight: z.number().optional().default(0),
  // Foot
  footFlexorsLeft: z.number().optional().default(0),
  footFlexorsRight: z.number().optional().default(0),
  footExtensorsLeft: z.number().optional().default(0),
  footExtensorsRight: z.number().optional().default(0),
  // Wrist
  wristFlexorsLeft: z.number().optional().default(0),
  wristFlexorsRight: z.number().optional().default(0),
  wristExtensorsLeft: z.number().optional().default(0),
  wristExtensorsRight: z.number().optional().default(0),
  // Trunk
  trunkFlexorsLeft: z.number().optional().default(0),
  trunkFlexorsRight: z.number().optional().default(0),
  trunkExtensorLeft: z.number().optional().default(0),
  trunkExtensorRight: z.number().optional().default(0),
  trunkRBendingLeft: z.number().optional().default(0),
  trunkRBendingRight: z.number().optional().default(0),
  trunkLBendingLeft: z.number().optional().default(0),
  trunkLBendingRight: z.number().optional().default(0),
  trunkRRotationLeft: z.number().optional().default(0),
  trunkRRotationRight: z.number().optional().default(0),
  trunkLRotationLeft: z.number().optional().default(0),
  trunkLRotationRight: z.number().optional().default(0),
  // Fingers
  fingersFlexorsLeft: z.number().optional().default(0),
  fingersFlexorsRight: z.number().optional().default(0),
  fingersExtensorsLeft: z.number().optional().default(0),
  fingersExtensorsRight: z.number().optional().default(0),
  fingersAbductorsLeft: z.number().optional().default(0),
  fingersAbductorsRight: z.number().optional().default(0),
  fingersOppositionLeft: z.number().optional().default(0),
  fingersOppositionRight: z.number().optional().default(0),
});
export type MuscleToneAssessmentSchemaType = z.infer<
  typeof MuscleToneAssessmentSchema
>;

type MuscleToneAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function MuscleToneAssessmentComponent(
  props: MuscleToneAssessmentProps
) {
  const [muscleToneAssessment, setMuscleToneAssessment] =
    React.useState<MuscleToneAssessment | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [error, setError] = React.useState("");

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  const initialize = () => {
    // set default values for the form
    return generateForm(props.masterAssessmentId);
  };
  const userData = getUserPersistedOnLocalStorage();
  const [lastUpdateAt, setLastUpdatedAt] = React.useState("");

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    const initializeVitalAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getMuscleToneAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const convertedMuscleToneAssessment = Convert.toMuscleToneAssessment(
            JSON.stringify(data)
          );
          setMuscleToneAssessment(convertedMuscleToneAssessment);

          methods.reset({ ...convertedMuscleToneAssessment });
          setLastUpdatedAt(data["audit"]["lastUpdatedAt"]);
          setIsDisabled(true);

          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching MuscleTone assessment:", error);
          setError(error.message);
          setLoading(false);
        }
      }
    };
    initializeVitalAssessment();
  }, []);

  const methods = useForm<MuscleToneAssessmentSchemaType>({
    resolver: zodResolver(MuscleToneAssessmentSchema),
    defaultValues: MuscleToneAssessmentSchema.parse(initialize()),
  });

  const control = methods.control;

  const onSubmit: SubmitHandler<MuscleToneAssessmentSchemaType> = async (
    data
  ) => {
    methods.trigger();
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        MuscleToneAssessmentSchema.parse(data);
        data.id = props.assessmentId;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateMuscleToneAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Muscle Tone Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
        setError(error.response.data["data"].toString());
      }
    } else {
      try {
        MuscleToneAssessmentSchema.parse(data);
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createMuscleToneAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Muscle Tone Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
        setError(error.response.data["data"].toString());
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col md:flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Muscle Tone Assessment
        </h3>
        <p className="text-sm text-gray-500">
          {props.assessmentId !== "000000000000000000000000" &&
            `Last updated: ${
              lastUpdateAt &&
              moment(new Date(lastUpdateAt)).format("DD-MM-YYYY") !==
                "01-01-0001"
                ? moment(new Date(lastUpdateAt)).format("DD-MM-YYYY")
                : "N/A"
            }`}
        </p>
      </div>

      <hr className="w-full" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Hip
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Hip-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipFlexorsLeft`}
                          name={`hipFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipFlexorsRight`}
                          name={`hipFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Hip-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipExtensorsLeft`}
                          name={`hipExtensorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipExtensorsRight`}
                          name={`hipExtensorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Hip-Abductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipAbductorsLeft`}
                          name={`hipAbductorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipAbductorsRight`}
                          name={`hipAbductorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Hip-Adductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipAdductorsLeft`}
                          name={`hipAdductorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipAdductorsRight`}
                          name={`hipAdductorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Hip-Lateral Rot.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipLateralRotationLeft`}
                          name={`hipLateralRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipLateralRotationRight`}
                          name={`hipLateralRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Hip-Medial Rot.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipMedialRotationLeft`}
                          name={`hipMedialRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`hipMedialRotationRight`}
                          name={`hipMedialRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Shoulder
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderFlexorsLeft`}
                          name={`shoulderFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderFlexorsRight`}
                          name={`shoulderFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderExtensorsLeft`}
                          name={`shoulderExtensorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderExtensorsRight`}
                          name={`shoulderExtensorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Abductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAbductorsLeft`}
                          name={`shoulderAbductorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAbductorsRight`}
                          name={`shoulderAbductorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Adductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAdductorsLeft`}
                          name={`shoulderAdductorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAdductorsRight`}
                          name={`shoulderAdductorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Lateral Rot.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderLateralRotationLeft`}
                          name={`shoulderLateralRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderLateralRotationRight`}
                          name={`shoulderLateralRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Medial Rot.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderMedialRotationLeft`}
                          name={`shoulderMedialRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderMedialRotationRight`}
                          name={`shoulderMedialRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Elevators
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderElevatorsLeft`}
                          name={`shoulderElevatorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderElevatorsRight`}
                          name={`shoulderElevatorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Depressors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderDepressorsLeft`}
                          name={`shoulderDepressorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderDepressorsRight`}
                          name={`shoulderDepressorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Antepulsors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAntepulsorsLeft`}
                          name={`shoulderAntepulsorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderAntepulsorsRight`}
                          name={`shoulderAntepulsorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Shoulder-Retropulsors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderRetropulsorsLeft`}
                          name={`shoulderRetropulsorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`shoulderRetropulsorsRight`}
                          name={`shoulderRetropulsorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Knee
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Knee-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`kneeFlexorsLeft`}
                          name={`kneeFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`kneeFlexorsRight`}
                          name={`kneeFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Knee-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`kneeExtensorsLeft`}
                          name={`kneeExtensorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`kneeExtensorsRight`}
                          name={`kneeExtensorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Elbow
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Elbow-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`elbowFlexorsLeft`}
                          name={`elbowFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`elbowFlexorsRight`}
                          name={`elbowFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Elbow-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`elbowExtensorsLeft`}
                          name={`elbowExtensorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`elbowExtensorsRight`}
                          name={`elbowExtensorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Ankle
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Ankle-Dorsi Flex.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleDorsiFlexorsLeft`}
                          name={`ankleDorsiFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleDorsiFlexorsRight`}
                          name={`ankleDorsiFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Ankle-Plantar Flex.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`anklePlantarFlexorsLeft`}
                          name={`anklePlantarFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`anklePlantarFlexorsRight`}
                          name={`anklePlantarFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Ankle-Inversors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleInversorsLeft`}
                          name={`ankleInversorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleInversorsRight`}
                          name={`ankleInversorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Ankle-Eversors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleEversorsLeft`}
                          name={`ankleEversorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`ankleEversorsRight`}
                          name={`ankleEversorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Fore-arm
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Forearm-Supinators
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`forearmSupinatorsLeft`}
                          name={`forearmSupinatorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`forearmSupinatorsRight`}
                          name={`forearmSupinatorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Forearm-Pronators
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`forearmPronatorsLeft`}
                          name={`forearmPronatorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`forearmPronatorsRight`}
                          name={`forearmPronatorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Foot
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Foot-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`footFlexorsLeft`}
                          name={`footFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`footFlexorsRight`}
                          name={`footFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Foot-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`footExtensorsLeft`}
                          name={`footExtensorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`footExtensorsRight`}
                          name={`footExtensorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Wrist
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Wrist-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristFlexorsLeft`}
                          name={`wristFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristFlexorsRight`}
                          name={`wristFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Wrist-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristExtensorsLeft`}
                          name={`wristExtensorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`wristExtensorsRight`}
                          name={`wristExtensorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Trunk
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkFlexorsLeft`}
                          name={`trunkFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkFlexorsRight`}
                          name={`trunkFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-Extensor
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkExtensorLeft`}
                          name={`trunkExtensorLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkExtensorRight`}
                          name={`trunkExtensorRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-R. Bending
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkRBendingLeft`}
                          name={`trunkRBendingLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkRBendingRight`}
                          name={`trunkRBendingRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-L. Bending
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLBendingLeft`}
                          name={`trunkLBendingLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLBendingRight`}
                          name={`trunkLBendingRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-R. Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkRRotationLeft`}
                          name={`trunkRRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkRRotationRight`}
                          name={`trunkRRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-L. Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLRotationLeft`}
                          name={`trunkLRotationLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`trunkLRotationRight`}
                          name={`trunkLRotationRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Fingers
                </div>
                <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Fingers-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersFlexorsLeft`}
                          name={`fingersFlexorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersFlexorsRight`}
                          name={`fingersFlexorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Fingers-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersExtensorsLeft`}
                          name={`fingersExtensorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersExtensorsRight`}
                          name={`fingersExtensorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Fingers-Abductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersAbductorsLeft`}
                          name={`fingersAbductorsLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersAbductorsRight`}
                          name={`fingersAbductorsRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Fingers-Opposition
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersOppositionLeft`}
                          name={`fingersOppositionLeft`}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <InputField
                          type="number"
                          key={`fingersOppositionRight`}
                          name={`fingersOppositionRight`}
                          disabled={isDisabled}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {methods.formState.errors && <p className="text-red-500"> {}</p>}
          {/* Add a empty div with height to avoid overlapping */}
          <div className="h-20"></div>
          <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
            <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
              <OutlinedButton
                type="button"
                onClick={() => {
                  props.postSubmitCallback();
                }}
                className="mr-2"
                variant={ButtonVariant.PRIMARY}
              >
                Close
              </OutlinedButton>
              {isDisabled ? (
                <Button
                  type="button"
                  key="EditButton"
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                  variant={ButtonVariant.PRIMARY}
                >
                  Edit
                </Button>
              ) : props.assessmentId !== "000000000000000000000000" ? (
                <Button
                  type="submit"
                  key="UpdateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  key="CreateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
