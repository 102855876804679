import Modal from "@components/modal";
import React, { useEffect, useState } from "react";
import PlanForm from "./PlanForm";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import { Plan } from "../types/Plan";
import { OutlinedButton } from "@components/button";
import SolidButton, { ButtonVariant } from "@components/button/button";
import RightOffcanvas from "@components/right-offcanvas";
import { Service } from "../types/Service";
import { Switch } from "@headlessui/react";

function PlansTable({
  planList,
  searchQuery,
  viewPlanDetails,
  services,
  branchId,
  isEditOpen,
  setIsEditOpen,
  handleCloseIsEditPlan,
  isConfirmationOpen,
  setIsConfirmationOpen,
  handleCloseConfirmation,
}: {
  planList: Plan[];
  searchQuery: string;
  viewPlanDetails: (planId: string) => void;
  services: Service[];
  branchId: string;
  isEditOpen: boolean;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseIsEditPlan: () => void;
  isConfirmationOpen: boolean;
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseConfirmation: () => void;
}) {
  const filteredPlan = planList.filter((plan: Plan) =>
    plan.name
      .replace(/\s/g, "")
      .toLowerCase()
      .includes(searchQuery.replace(/\s/g, "").trim().toLowerCase())
  );

  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [planId, setPlanId] = useState<string>("");
  const [plan, setPlan] = useState<Plan | null>(null);

  const handleCloseViewPlan = () => {
    setIsViewOpen(false);
  };

  const handleOpenConfiguration = (plan: Plan) => {
    setIsConfirmationOpen(true);
    setPlan(plan);
  };

  const handlePlanIsActiveStatus = async () => {
    try {
      const paramsMap = new Map([["id", plan?.id ?? ""]]);
      const filteredEndpoint = replaceParams(
        endpoints.branch.updatePlan,
        paramsMap
      );
      const response = await axios.put(filteredEndpoint, {
        ...plan,
        isActive: plan?.isActive ? false : true,
      });
      if (response.status === 200) {
        toast.success("Plan status updated successfully");
      } else {
        toast.error("Something went wrong");
      }

      handleCloseConfirmation();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div>
        <div className="px-4 sm:px-6 lg:px-2 w-full hidden xl:block">
          <div className="sm:flex sm:items-center"></div>
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Plan Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Active/Inactive
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {filteredPlan.map((plan) => (
                      <tr key={plan.id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {plan.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          ₹{plan.totalPrice}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-80">
                          {plan.description}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex flex-row items-center">
                            <Switch
                              onChange={() => {
                                handleOpenConfiguration(plan);
                              }}
                              checked={plan.isActive}
                              className={`${
                                plan.isActive
                                  ? "bg-secondary-600"
                                  : "bg-secondary-50"
                              }
                                                    relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 `}
                            >
                              <span
                                aria-hidden="true"
                                className={`${
                                  plan.isActive
                                    ? "translate-x-9"
                                    : "translate-x-0"
                                }
                                                    pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary-500">
                          <div className="flex flex-row justify-left">
                            <OutlinedButton
                              type="button"
                              onClick={() => {
                                setPlanId(plan.id);
                                setIsViewOpen(true);
                                setPlan(plan);
                              }}
                              className="mr-2"
                              variant={ButtonVariant.SECONDARY}
                            >
                              View
                            </OutlinedButton>
                            <OutlinedButton
                              type="button"
                              onClick={() => {
                                setPlan(plan);
                                setIsEditOpen(true);
                              }}
                              variant={ButtonVariant.SECONDARY}
                            >
                              Edit
                            </OutlinedButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="block xl:hidden w-full">
          <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 bg-gray-50 -mx-8 p-8 ">
            {filteredPlan.map((plan) => (
              <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white overflow-hidden">
                <div className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4">
                  <div className="flex flex-col w-full">
                    <span className="flex-initial text-md text-black font-bold">
                      {plan.name}
                    </span>
                    <span className="flex-initial truncate text-secondary-500">
                      {plan.description}
                    </span>
                    <span className="flex-initial text-secondary-500">
                      ₹{plan.totalPrice}
                    </span>
                    <div className="flex text-secondary-500 justify-between">
                      <div className="flex flex-row">
                        <OutlinedButton
                          type="button"
                          onClick={() => {
                            setPlanId(plan.id);
                            setIsViewOpen(true);
                            setPlan(plan);
                          }}
                          className="mr-2"
                          variant={ButtonVariant.SECONDARY}
                        >
                          View
                        </OutlinedButton>
                        <OutlinedButton
                          type="button"
                          onClick={() => {
                            setPlan(plan);
                            setIsEditOpen(true);
                          }}
                          className="mr-2"
                          variant={ButtonVariant.SECONDARY}
                        >
                          Edit
                        </OutlinedButton>
                      </div>
                      <div className="flex flex-row justify-end w-full items-center">
                        {plan.isActive ? (
                          <span className="text-gray-500 mr-2">Active</span>
                        ) : (
                          <span className="text-gray-500 mr-2">Inactive</span>
                        )}
                        <Switch
                          onChange={() => {
                            handleOpenConfiguration(plan);
                          }}
                          checked={plan.isActive}
                          className={`${
                            plan.isActive
                              ? "bg-secondary-600"
                              : "bg-secondary-50"
                          }
                                                relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 `}
                        >
                          <span
                            aria-hidden="true"
                            className={`${
                              plan.isActive ? "translate-x-9" : "translate-x-0"
                            }
                                                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                          />
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <Modal isOpen={isViewOpen} onClose={handleCloseViewPlan}>
        <PlanForm
          closeForm={handleCloseViewPlan}
          branchId={branchId}
          initialData={plan}
          isView={true}
        ></PlanForm>
      </Modal>
      <Modal isOpen={isEditOpen} onClose={handleCloseIsEditPlan}>
        <PlanForm
          closeForm={handleCloseIsEditPlan}
          branchId={branchId}
          initialData={plan}
          isView={false}
        ></PlanForm>
      </Modal>
      <Modal isOpen={isConfirmationOpen} onClose={handleCloseConfirmation}>
        <div className="bg-white p-4 rounded-lg">
          <p>
            Are you sure you want to make the service{" "}
            {plan?.isActive ? "inactive" : "active"}?
          </p>
          <div className="flex justify-end mt-4">
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md mr-4"
              onClick={handleCloseConfirmation}
            >
              Close
            </button>
            <SolidButton
              className="mr-1"
              variant={ButtonVariant.SECONDARY}
              type="button"
              onClick={() => handlePlanIsActiveStatus()}
              children="Yes"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

function SearchBar({
  searchQuery,
  onSearch,
}: {
  searchQuery: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <input
        type="text"
        value={searchQuery}
        onChange={onSearch}
        placeholder="Search for Plan Name"
        className="w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500"
      />
    </div>
  );
}

const Plans = ({
  onEdit,
  onDelete,
  onAdd,
  branchId,
}: {
  onEdit: (plan: Plan) => void;
  onDelete: (id: string) => void;
  onAdd: () => void;
  branchId: string;
}) => {
  const [isPlanFormOpen, setIsPlanFormOpen] = useState<boolean>(false);
  const [allPlans, setAllPlans] = useState<Plan[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [services, setServices] = useState<Service[]>([]);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleCloseIsEditPlan = () => {
    setIsEditOpen(false);
  };

  useEffect(() => {
    _init_();

    async function _init_() {
      await getAllPlans();
      await getServices();
    }
  }, [isPlanFormOpen, isEditOpen, isConfirmationOpen]);

  async function getAllPlans() {
    try {
      const paramsMap = new Map<string, string>([["branchId", branchId ?? ""]]);

      const filteredEndpoint = replaceParams(
        endpoints.branch.getAllPlans,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      if (response.status === 200) {
        setAllPlans(response.data["data"]);
      } else {
        toast.error(response.data["message"]);
      }
    } catch (e) {
      toast.error(e);
    }
  }

  async function getServices() {
    try {
      const paramsMap = new Map<string, string>([["branchId", branchId]]);
      const filteredEndpoint = replaceParams(
        endpoints.branch.services,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      if (response.status === 200) {
        setServices(response.data["data"]);
      } else {
        toast.error(response.data["message"]);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  return (
    <div className="mt-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="mt-4 w-full">
          <SearchBar searchQuery={searchQuery} onSearch={handleSearch} />
        </div>
        <button
          onClick={() => {
            setIsPlanFormOpen(true);
          }}
          className="flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-xl hover:bg-primary-700 transition-colors min-w-full md:min-w-max md:ml-2 justify-center"
        >
          Create New Plan
        </button>
      </div>

      <div className="grid gap-6">
        {allPlans?.length === 0 || allPlans === null ? (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <p className="text-gray-500">
              No plans available. Create your first plan!
            </p>
          </div>
        ) : (
          <PlansTable
            planList={allPlans}
            searchQuery={searchQuery}
            viewPlanDetails={() => {}}
            services={services}
            branchId={branchId}
            isEditOpen={isEditOpen}
            setIsEditOpen={setIsEditOpen}
            handleCloseIsEditPlan={handleCloseIsEditPlan}
            isConfirmationOpen={isConfirmationOpen}
            setIsConfirmationOpen={setIsConfirmationOpen}
            handleCloseConfirmation={handleCloseConfirmation}
          ></PlansTable>
        )}
      </div>
      <Modal
        isOpen={isPlanFormOpen}
        onClose={() => {
          setIsPlanFormOpen(false);
        }}
      >
        <PlanForm
          closeForm={() => {
            setIsPlanFormOpen(false);
          }}
          branchId={branchId}
          isView={false}
        ></PlanForm>
      </Modal>
    </div>
  );
};

export default Plans;
