import { useEffect, useMemo, useState, useCallback } from "react";

import React from "react";

import Button, { ButtonVariant } from "@components/button/outline-button";

import { endpoints, replaceParams } from "@utils/axios";
import useAxios from "@routes/hooks/use-axios";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@pages/appointments/appointment-index-table/Pagination";
import {
  filterRows,
  paginateRows,
  sortRows,
} from "@pages/appointments/appointment-index-table/Helpers";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import moment from "moment";
import { InvoiceDetails, ConvertBilling, LineItem } from "../types/BillingDetails";
import RightOffcanvas from "@components/right-offcanvas";
import { PersonalInformationDetails } from "@pages/manage-patient/types/PatientPersonalDetails";
import { paths } from "@routes/paths";
import { ScrollArea, ScrollBar } from "@components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@components/ui/table";
import InvoicePage from "./InvoicePage";
import { BanknoteIcon } from "lucide-react";
import { OutlinedButton } from "@components/button";
import * as XLSX from 'xlsx'; // Import the xlsx library

export default function BillingIndexPage(props: {
  patientId?: string;
  patientInfo?: PersonalInformationDetails;
  getPatientBillingDetails?: () => Promise<void>;
}) {
  const [billing, setBilling] = React.useState<InvoiceDetails[] | undefined>(
    []
  );
  const [loading, setLoading] = React.useState(true);

  const axios = useAxios();
  const [rows, setRows] = useState<InvoiceDetails[]>([]);
  const [isRightOffcanvasBilling, setIsRightOffcanvasBilling] =
    React.useState(false);

  const [selectedInvoice, setSelectedInvoice] = React.useState("");

  const columns = [
    { accessor: "invoiceNumber", label: "Invoice Number" },
    { accessor: "invoiceDate", label: "Date" },
    { accessor: "clientName", label: "Client Name" },
    { accessor: "staffName", label: "Staff/Consultant" },
    { accessor: "totalAmount", label: "Amount" },
    { accessor: "totalPaid", label: "Total Paid" },
    { accessor: "outStandingAmount", label: "Outstanding" },
    { accessor: "status", label: "Status" },
    { accessor: "actions", label: "Actions" },
  ];

  const [activePage, setActivePage] = useState<number>(1);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [sort, setSort] = useState<{ order: string; orderBy: string }>({
    order: "",
    orderBy: "",
  });
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  
  // Function to determine the border color based on the selected status
  const getTextColor = (selectedStatus: string) => {
    if (selectedStatus === "partlyPaid") {
      return "text-warning-500"; // Red border for 'pending' status
    } else if (selectedStatus === "paid") {
      return "text-success-500"; // Green border for 'paid' status
    } else {
      return "text-red-500"; // Default gray border
    }
  };
  const rowsPerPage: number = 10;
  const filteredRows = useMemo(
    () => filterRows(rows, filters),
    [rows, filters]
  );
  const sortedRows = useMemo(
    () => sortRows(filteredRows, sort),
    [filteredRows, sort]
  );

  const calculatedRows = useMemo(
    () => paginateRows(sortedRows, activePage, rowsPerPage),
    [sortedRows, activePage, rowsPerPage]
  );

  const count: number = filteredRows.length;
  const totalPages: number = Math.ceil(count / rowsPerPage);
  const userData = getUserPersistedOnLocalStorage();
  const naviagtor = useNavigate();

  const navigateToReferralBilling = () => {
    naviagtor(paths.referralBilling.index.route);
  };

  const [newBill, setNewBill] = React.useState(false);
  function createNewBill(): void {
    setNewBill(true);
  }

  const getBilling = useCallback(async () => {
    try {
      let endpoint = "";
      if (props.patientId) {
        const paramsMap = new Map<string, string>([
          ["clientId", props.patientId],
        ]);
        endpoint = replaceParams(
          endpoints.invoice.getBillsForClient,
          paramsMap
        );
      } else {
        const paramsMap = new Map<string, string>([
          ["entityId", userData?.entity_id!],
          ["branchId", userData?.branch_id ?? ""],
        ]);
      const queryMap = new Map<string, string>([
        ["requestedFromDate", moment(startDate).format("DD-MM-YYYY")],
        ["requestedToDate", moment(endDate).format("DD-MM-YYYY")],
      ]);
        if (userData?.user_role === "ENTITY_OWNER") {
          endpoint = replaceParams(
            endpoints.invoice.getBillsForEntity,
            paramsMap,
            queryMap
          );
        } else {
          endpoint = replaceParams(
            endpoints.invoice.getBillsForBranch,
            paramsMap,
            queryMap
          );
        }
      }

      const response = await axios.get(endpoint);
      const data = response.data["data"];
      if (data !== null) {
        const convertedList = data.map((expense: any) => {
          return ConvertBilling.toBilling(JSON.stringify(expense));
        });

        setBilling(convertedList);
        setRows(convertedList);
      } else {
        setBilling([]);
        setRows([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching expenses details:", error);

      setLoading(false);
    }
  }, [props.patientId, startDate, endDate, userData]);

  useEffect(() => {
    getBilling();
  }, []);

  // Effect to call getBilling when startDate or endDate changes
  useEffect(() => {
    getBilling(); // Call getBilling whenever startDate or endDate changes
  }, [startDate, endDate]);


  const [isLoading, setIsLoading] = useState(false); // Add loading state

  function handleSearch(value: string) {
    setIsLoading(true); // Set loading to true
    let filteredRows = billing || [];
    filteredRows = filteredRows.filter((row: any) => {
      return (
        row.invoiceNumber.toLowerCase().includes(value.toLowerCase()) ||
        row.clientName?.toLowerCase().includes(value.toLowerCase()) ||
        row.totalAmount
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        row.status.toLowerCase().includes(value.toLowerCase()) ||
        moment(row.dueDate)
          .format("DD-MM-YYYY")
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    });
    setRows(filteredRows);
    setIsLoading(false); // Set loading to false after processing
  }

  // Function to download the search results as an Excel file
  const downloadExcel = () => {
    // Flatten the data to include LineItem and tax details with human-readable headers
    const flattenedData = rows.map((row) => ({
      "Invoice Number": row.invoiceNumber,
      "Invoice Date": moment(row.invoiceDate).format("DD-MM-YYYY"),
      "Client Name": row.clientName,
      "Staff/Consultant": row.items
        ? row.items.map((item) => item.staffName).join(", ")
        : "N/A",
      "Service Names": row.items
        ? row.items.map((item) => item.description).join(", ")
        : "N/A",
      "Tax Rates": row.taxes
        ? row.taxes.map((tax) => tax.rate).join(", ")
        : "N/A",
      Discount: row.discount || 0,
      "Total Amount": row.totalAmount || 0,
      "Total Paid": row.totalPaid || 0,
      "Outstanding Amount": row.outStandingAmount || 0,
      Status: row.status || "Unknown",
    }));

    // Calculate sums for the relevant columns
    const totalBillAmount = flattenedData.reduce((sum, row) => sum + (row["Total Amount"] || 0), 0);
    const totalPaid = flattenedData.reduce((sum, row) => sum + (row["Total Paid"] || 0), 0);
    const totalOutstanding = flattenedData.reduce((sum, row) => sum + (row["Outstanding Amount"] || 0), 0);

    // Add a summary row
    flattenedData.push({
      "Invoice Number": "Total",
      "Invoice Date": "",
      "Client Name": "",
      "Staff/Consultant": "",
      "Service Names": "",
      "Tax Rates": "",
      Discount: 0,
      "Total Amount": totalBillAmount || 0,
      "Total Paid": totalPaid || 0,
      "Outstanding Amount": totalOutstanding || 0,
      Status: "",
    });

    // Convert the flattened data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Billing Data");

    // Apply styles to the first and last columns
    if (worksheet['!ref']) { // Check if '!ref' is defined
        const range = XLSX.utils.decode_range(worksheet['!ref']); // Get the range of the worksheet
        for (let row = range.s.r; row <= range.e.r; row++) {
            const firstCell = worksheet[XLSX.utils.encode_cell({ r: row, c: 0 })]; // First column
            const lastCell = worksheet[XLSX.utils.encode_cell({ r: row, c: range.e.c })]; // Last column

            if (firstCell) {
                firstCell.s = {
                    fill: { fgColor: { rgb: "D3D3D3" } }, // Light gray background
                    font: { bold: true } // Bold text
                };
            }
            if (lastCell) {
                lastCell.s = {
                    fill: { fgColor: { rgb: "D3D3D3" } }, // Light gray background
                    font: { bold: true } // Bold text
                };
            }
        }
    }

    // Write the file
    XLSX.writeFile(workbook, "billing-data-" + moment().format("DD-MM-YYYY") + ".xlsx");
  };

  return (
    <>
      {/* For Widescreen layout */}

      <div className=" flex flex-row ">
        <div className="flex flex-col w-full">
          <div className="flex flex-row p-2 mb-4">
            <div className="flex flex-row gap-2 items-center">
              <label className="mx-2 text-sm text-center">Start Date:</label>
              <input
                type="date"
                value={startDate ? moment(startDate).format("YYYY-MM-DD") : ""}
                onChange={(e) =>
                  setStartDate(e.target.value ? moment(new Date(e.target.value)).format("YYYY-MM-DD") : "")
                }
                className="flex h-10 rounded-md border border-gray-300"
              />
            </div>
            <div className="flex flex-row gap-2 items-center">
              <label className="mx-2 text-sm text-center">End Date:</label>
              <input
                type="date"
                value={endDate ? moment(endDate).format("YYYY-MM-DD") : ""}
                onChange={(e) =>
                  setEndDate(e.target.value ? moment(new Date(e.target.value)).format("YYYY-MM-DD") : "")
                }
                className="flex h-10 rounded-md border border-gray-300"
              />
            </div>
            <div className="flex flex-row gap-2 items-center">
              <Button
                variant={ButtonVariant.SECONDARY}
                type="button"
                onClick={() => {
                  setStartDate(moment().format("YYYY-MM-DD"));
                  setEndDate(moment().format("YYYY-MM-DD"));
                  // No need to call getBilling here, it will be called in the useEffect
                }}
                className="ml-2"
                isDisabled={isLoading} // Change 'disabled' to 'isDisabled'
              >
                Today
              </Button>
            </div>
            {(userData?.user_role === "BRANCH_ADMIN" ||
              userData?.user_role === "ENTITY_OWNER" ||
              userData?.user_role === "ASSISTANT") &&
              !props.patientId && (
                <div className="flex flex-row gap-2 items-center ml-2">
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type="button"
                    onClick={navigateToReferralBilling}
                    className="mr-2 w-full sm:w-auto"
                  >
                    Referral Billing
                  </Button>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type="button"
                    onClick={() => createNewBill()}
                    className="mr-2 w-full sm:w-auto"
                  >
                    <BanknoteIcon className="w-5 h-5 text-secondary-500" />
                    <span className="pl-1 text-sm"> New Bill </span>
                  </Button>
                </div>
              )}
            <div className="flex flex-row gap-2 items-center">
              <Button
                variant={ButtonVariant.PRIMARY}
                type="button"
                onClick={downloadExcel} // Add onClick handler for download
                className="mr-2 w-full sm:w-auto"
              >
                Download Excel
              </Button>
            </div>
          </div>
          <div>
            <input
              type="text"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              placeholder="Search for billings by Invoice Number, Date, Patient Name, Amount and Status"
              className="w-full sm:w-1/2 h-min px-4 py-2 mb-4 text-sm text-gray-900 rounded-lg border border-gray-600 focus:ring-0 focus:border-primary-500"
            />
          </div>
          <ScrollArea className="w-full whitespace-nowrap rounded-md border">
            <div className="overflow-x-auto">
              {/* Billing table start */}
              <Table>
                <TableHeader>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableHead
                          key={column.accessor}
                          className="px-2 py-3 border-b border-gray-300 text-sm"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{column.label}</span>
                          </div>
                        </TableHead>
                      );
                    })}
                  </TableRow>
                </TableHeader>
                <TableBody className="bg-white">
                  {calculatedRows.map((row, index) => {
                    return (
                      <TableRow
                        key={row.id}
                        className={
                          index !== calculatedRows.length - 1
                            ? "border-b border-gray-200"
                            : ""
                        }
                      >
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          {row["invoiceNumber"]}
                        </TableCell>
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          {moment(row["invoiceDate"])
                            .format("DD-MM-YYYY")
                            .toString()}
                        </TableCell>
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          {row["clientName"]}
                        </TableCell>
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          {row.items
                            .map((item: LineItem) => item.staffName)
                            .join(", ")}
                        </TableCell>
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          ₹{row["totalAmount"]}
                        </TableCell>
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          ₹{row["totalPaid"]}
                        </TableCell>
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          ₹{row["outStandingAmount"]}
                        </TableCell>
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          <span
                            className={`font-bold ${getTextColor(
                              row["status"]
                            )}`}
                          >
                            {(() => {
                              switch (row["status"]) {
                                case "partlyPaid":
                                  return "Partly Paid";
                                case "paid":
                                  return "Paid";
                                case "pending":
                                  return "Pending";
                              }
                            })()}
                          </span>
                        </TableCell>
                        <TableCell className={`px-2 py-3 text-gray-500`}>
                          <div className="flex flex-row">
                            <div className="flex-shrink mr-2">
                              <OutlinedButton
                                variant={ButtonVariant.SECONDARY}
                                type="button"
                                onClick={() => {
                                  setIsRightOffcanvasBilling(true);
                                  setSelectedInvoice(row["invoiceNumber"]);
                                }}
                                children={
                                  row["status"] === "paid" ? "View" : "Open"
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {/* Billing table end  */}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
          {count > 0 ? (
            <Pagination
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          ) : (
            <p>No data found</p>
          )}
        </div>
        <hr className="my-4" />
      </div>
      <RightOffcanvas
        isOpen={isRightOffcanvasBilling}
        onClose={() => {
          setIsRightOffcanvasBilling(false);
          getBilling();
        }}
      >
        {isRightOffcanvasBilling && billing && (
          /*           <BillDetailsPage
            onClose={() => {
              setIsRightOffcanvasBilling(false);
              getBilling();
            }}
            bill={
              billing.find((item) => item.invoiceNumber === selectedInvoice) ||
              ConvertBilling.toBilling("{}")
            }
            getPatientBillingDetails={props.getPatientBillingDetails}
          /> */
          <InvoicePage
            clientName=""
            invoiceDetails={
              billing.find((item) => item.invoiceNumber === selectedInvoice) ||
              ConvertBilling.toBilling("{}")
            }
            onClose={() => {
              setIsRightOffcanvasBilling(false);
              getBilling();
            }}
          />
        )}
      </RightOffcanvas>
      <RightOffcanvas
        isOpen={newBill}
        onClose={() => {
          setNewBill(false);
        }}
      >
        {newBill && (
          <>
            <InvoicePage
              clientName=""
              invoiceDetails={{
                invoiceNumber: "",
                invoiceDate: new Date(),
                dueDate: new Date(),
                entityId: userData?.entity_id,
                entityBranchId: userData?.branch_id,
                clientName: "",
                clientId: "",
                items: [],
                totalAmount: 0,
                status: "pending",
                billForAppointments: false,
                totalPaid: 0,
                tax: 0,
                outStandingAmount: 0,
                paymentDetails: [],
                discount: 0,
                subtotal: 0,
                paymentMode: "",
                notes: "",
                taxes: [],
              }}
              onClose={() => {
                setNewBill(false);
                getBilling();
              }}
            />
          </>
        )}
      </RightOffcanvas>
    </>
  );
}
