import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface OffcanvasProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const RightOffcanvas: React.FC<OffcanvasProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <div className="fixed inset-0 overflow-hidden z-50">
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
              onClick={onClose}
            />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex w-full md:w-auto md:min-w-[60vw] lg:min-w-[35vw] max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full max-w-full bg-white shadow-xl">
                <div className="h-full flex flex-col">
                  <div className="flex-1 overflow-y-auto p-4">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
};

export default RightOffcanvas;
