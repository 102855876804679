import React from "react";
import { RangeOfMotionAssessment } from "../type/RangeOfMotion";

type ROMProps = {
  romAssessment: RangeOfMotionAssessment | null;
};

const  RangeOfMotion = (props: ROMProps) => {
  return (
    <>
      {props.romAssessment === null ? (
        <div></div>
      ) : (
        <div className="flex flex-col ">
          <h3 className="mb-4 font-bold text-tertiary-500">Range Of Motion</h3>
          <div className=" flex flex-wrap">
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.hipFlexionLeft !== 0 || 
    props.romAssessment?.hipFlexionRight !== 0 ||
    props.romAssessment?.hipExtensionLeft !== 0 ||
    props.romAssessment?.hipExtensionRight !== 0 ||
    props.romAssessment?.hipAbductionLeft !== 0 ||
    props.romAssessment?.hipAbductionRight !== 0 ||
    props.romAssessment?.hipAdductionLeft !== 0 ||
    props.romAssessment?.hipAdductionRight !== 0 ||
    props.romAssessment?.hipMedialRotationLeft !== 0 ||
    props.romAssessment?.hipMedialRotationRight !== 0 ||
    props.romAssessment?.hipLateralRotationLeft !== 0 ||
    props.romAssessment?.hipLateralRotationRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Hip
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.hipFlexionLeft !== 0 || props.romAssessment?.hipFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.hipExtensionLeft !== 0 || props.romAssessment?.hipExtensionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Extension</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipExtensionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipExtensionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.hipAbductionLeft !== 0 || props.romAssessment?.hipAbductionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Abduction</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipAbductionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipAbductionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.hipAdductionLeft !== 0 || props.romAssessment?.hipAdductionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Adduction</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipAdductionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipAdductionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.hipMedialRotationLeft !== 0 || props.romAssessment?.hipMedialRotationRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Medial Rotation</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipMedialRotationLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipMedialRotationRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.hipLateralRotationLeft !== 0 || props.romAssessment?.hipLateralRotationRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Lateral Rotation</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipLateralRotationLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.hipLateralRotationRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>

<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.shoulderFlexionLeft !== 0 || 
    props.romAssessment?.shoulderFlexionRight !== 0 ||
    props.romAssessment?.shoulderExtensionLeft !== 0 ||
    props.romAssessment?.shoulderExtensionRight !== 0 ||
    props.romAssessment?.shoulderAbductionLeft !== 0 ||
    props.romAssessment?.shoulderAbductionRight !== 0 ||
    props.romAssessment?.shoulderAdductionLeft !== 0 ||
    props.romAssessment?.shoulderAdductionRight !== 0 ||
    props.romAssessment?.shoulderMedialRotationLeft !== 0 ||
    props.romAssessment?.shoulderMedialRotationRight !== 0 ||
    props.romAssessment?.shoulderLateralRotationLeft !== 0 ||
    props.romAssessment?.shoulderLateralRotationRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Shoulder
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.shoulderFlexionLeft !== 0 || props.romAssessment?.shoulderFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300 break-all">Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.shoulderExtensionLeft !== 0 || props.romAssessment?.shoulderExtensionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300 break-all">Extension</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderExtensionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderExtensionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.shoulderAbductionLeft !== 0 || props.romAssessment?.shoulderAbductionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300 break-all">Abduction</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderAbductionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderAbductionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.shoulderAdductionLeft !== 0 || props.romAssessment?.shoulderAdductionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300 break-all">Adduction</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderAdductionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderAdductionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.shoulderMedialRotationLeft !== 0 || props.romAssessment?.shoulderMedialRotationRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300 break-all">Medial Rotation</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderMedialRotationLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderMedialRotationRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.shoulderLateralRotationLeft !== 0 || props.romAssessment?.shoulderLateralRotationRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300 break-all">Lateral Rotation</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderLateralRotationLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.shoulderLateralRotationRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>


{/* Knee Section */}
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.kneeFlexionLeft !== 0 || 
    props.romAssessment?.kneeFlexionRight !== 0 ||
    props.romAssessment?.kneeExtensionLeft !== 0 ||
    props.romAssessment?.kneeExtensionRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Knee
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.kneeFlexionLeft !== 0 || props.romAssessment?.kneeFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.kneeFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.kneeFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.kneeExtensionLeft !== 0 || props.romAssessment?.kneeExtensionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Extension</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.kneeExtensionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.kneeExtensionRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>

{/* Elbow Section */}
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.elbowFlexionLeft !== 0 || 
    props.romAssessment?.elbowFlexionRight !== 0 ||
    props.romAssessment?.elbowExtensionLeft !== 0 ||
    props.romAssessment?.elbowExtensionRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Elbow
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.elbowFlexionLeft !== 0 || props.romAssessment?.elbowFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.elbowFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.elbowFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.elbowExtensionLeft !== 0 || props.romAssessment?.elbowExtensionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Extension</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.elbowExtensionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.elbowExtensionRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>


{/* Ankle Section */}
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.ankleFootDorsiFlexionLeft !== 0 || 
    props.romAssessment?.ankleFootDorsiFlexionRight !== 0 ||
    props.romAssessment?.ankleFootPlantarFlexionLeft !== 0 ||
    props.romAssessment?.ankleFootPlantarFlexionRight !== 0 ||
    props.romAssessment?.ankleFootInversionLeft !== 0 ||
    props.romAssessment?.ankleFootInversionRight !== 0 ||
    props.romAssessment?.ankleFootEversionLeft !== 0 ||
    props.romAssessment?.ankleFootEversionRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Ankle
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 align-center">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.ankleFootDorsiFlexionLeft !== 0 || props.romAssessment?.ankleFootDorsiFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Dorsi Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.ankleFootDorsiFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.ankleFootDorsiFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.ankleFootPlantarFlexionLeft !== 0 || props.romAssessment?.ankleFootPlantarFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Plantar Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.ankleFootPlantarFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.ankleFootPlantarFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.ankleFootInversionLeft !== 0 || props.romAssessment?.ankleFootInversionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Inversion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.ankleFootInversionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.ankleFootInversionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.ankleFootEversionLeft !== 0 || props.romAssessment?.ankleFootEversionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Eversion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.ankleFootEversionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.ankleFootEversionRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>

{/* Fore-arm Section */}
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.forearmPronationLeft !== 0 || 
    props.romAssessment?.forearmPronationRight !== 0 ||
    props.romAssessment?.forearmSupinationLeft !== 0 ||
    props.romAssessment?.forearmSupinationRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Fore-arm
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.forearmPronationLeft !== 0 || props.romAssessment?.forearmPronationRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Pronation</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.forearmPronationLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.forearmPronationRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.forearmSupinationLeft !== 0 || props.romAssessment?.forearmSupinationRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Supination</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.forearmSupinationLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.forearmSupinationRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>


{/* Neck Section */}
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.neckFlexionLeft !== 0 || 
    props.romAssessment?.neckFlexionRight !== 0 ||
    props.romAssessment?.neckExtensionLeft !== 0 ||
    props.romAssessment?.neckExtensionRight !== 0 ||
    props.romAssessment?.neckLateroFlexionRLeft !== 0 ||
    props.romAssessment?.neckLateroFlexionRRight !== 0 ||
    props.romAssessment?.neckLateroFlexionLLeft !== 0 ||
    props.romAssessment?.neckLateroFlexionLRight !== 0 ||
    props.romAssessment?.neckRotationRLeft !== 0 ||
    props.romAssessment?.neckRotationRRight !== 0 ||
    props.romAssessment?.neckRotationLLeft !== 0 ||
    props.romAssessment?.neckRotationLRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Neck
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.neckFlexionLeft !== 0 || props.romAssessment?.neckFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.neckExtensionLeft !== 0 || props.romAssessment?.neckExtensionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Extension</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckExtensionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckExtensionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.neckLateroFlexionRLeft !== 0 || props.romAssessment?.neckLateroFlexionRRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Latero-Flexion R</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckLateroFlexionRLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckLateroFlexionRRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.neckLateroFlexionLLeft !== 0 || props.romAssessment?.neckLateroFlexionLRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Latero-Flexion L</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckLateroFlexionLLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckLateroFlexionLRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.neckRotationRLeft !== 0 || props.romAssessment?.neckRotationRRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Rotation R</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckRotationRLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckRotationRRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.neckRotationLLeft !== 0 || props.romAssessment?.neckRotationLRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Rotation L</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckRotationLLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.neckRotationLRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>

{/* Wrist Section */}
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.wristFlexionLeft !== 0 || 
    props.romAssessment?.wristFlexionRight !== 0 ||
    props.romAssessment?.wristExtensionLeft !== 0 ||
    props.romAssessment?.wristExtensionRight !== 0 ||
    props.romAssessment?.wristAbductionLeft !== 0 ||
    props.romAssessment?.wristAbductionRight !== 0 ||
    props.romAssessment?.wristAdductionLeft !== 0 ||
    props.romAssessment?.wristAdductionRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Wrist
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.wristFlexionLeft !== 0 || props.romAssessment?.wristFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.wristFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.wristFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.wristExtensionLeft !== 0 || props.romAssessment?.wristExtensionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Extension</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.wristExtensionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.wristExtensionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.wristAbductionLeft !== 0 || props.romAssessment?.wristAbductionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Abduction</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.wristAbductionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.wristAbductionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.wristAdductionLeft !== 0 || props.romAssessment?.wristAdductionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Adduction</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.wristAdductionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.wristAdductionRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>



{/* Trunk Section */}
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.trunkGlobalFlexionLeft !== 0 || 
    props.romAssessment?.trunkGlobalFlexionRight !== 0 ||
    props.romAssessment?.trunkThoracicFlexionLeft !== 0 ||
    props.romAssessment?.trunkThoracicFlexionRight !== 0 ||
    props.romAssessment?.trunkLumbarFlexionLeft !== 0 ||
    props.romAssessment?.trunkLumbarFlexionRight !== 0 ||
    props.romAssessment?.trunkGlobalExtensionLeft !== 0 ||
    props.romAssessment?.trunkGlobalExtensionRight !== 0 ||
    props.romAssessment?.trunkLateroFlexionRLeft !== 0 ||
    props.romAssessment?.trunkLateroFlexionRRight !== 0 ||
    props.romAssessment?.trunkLateroFlexionLLeft !== 0 ||
    props.romAssessment?.trunkLateroFlexionLRight !== 0 ||
    props.romAssessment?.trunkRotationRLeft !== 0 ||
    props.romAssessment?.trunkRotationRRight !== 0 ||
    props.romAssessment?.trunkRotationLLeft !== 0 ||
    props.romAssessment?.trunkRotationLRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Trunk
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.trunkGlobalFlexionLeft !== 0 || props.romAssessment?.trunkGlobalFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Global Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkGlobalFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkGlobalFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.trunkThoracicFlexionLeft !== 0 || props.romAssessment?.trunkThoracicFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Thoracic Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkThoracicFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkThoracicFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.trunkLumbarFlexionLeft !== 0 || props.romAssessment?.trunkLumbarFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Lumbar Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkLumbarFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkLumbarFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.trunkGlobalExtensionLeft !== 0 || props.romAssessment?.trunkGlobalExtensionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Global Extension</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkGlobalExtensionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkGlobalExtensionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.trunkLateroFlexionRLeft !== 0 || props.romAssessment?.trunkLateroFlexionRRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Latero-Flexion R</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkLateroFlexionRLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkLateroFlexionRRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.trunkLateroFlexionLLeft !== 0 || props.romAssessment?.trunkLateroFlexionLRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Latero-Flexion L</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkLateroFlexionLLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkLateroFlexionLRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.trunkRotationRLeft !== 0 || props.romAssessment?.trunkRotationRRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Rotation R</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkRotationRLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkRotationRRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.trunkRotationLLeft !== 0 || props.romAssessment?.trunkRotationLRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Rotation L</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkRotationLLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.trunkRotationLRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>

{/* Fingers Section */}
<div className="flex flex-col items-stretch">
  {(
    props.romAssessment?.fingersThumboppositionLeft !== 0 || 
    props.romAssessment?.fingersThumboppositionRight !== 0 ||
    props.romAssessment?.fingersMPFlexionLeft !== 0 ||
    props.romAssessment?.fingersMPFlexionRight !== 0 ||
    props.romAssessment?.fingersMPExtensionLeft !== 0 ||
    props.romAssessment?.fingersMPExtensionRight !== 0 ||
    props.romAssessment?.fingersIPFlexionLeft !== 0 ||
    props.romAssessment?.fingersIPFlexionRight !== 0
  ) ? (
    <>
      <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
        Fingers
      </div>
      <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Name</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Left</th>
            <th className="border px-2 py-1 border-slate-300 text-left p-2">Right</th>
          </tr>
        </thead>
        <tbody>
          {props.romAssessment?.fingersThumboppositionLeft !== 0 || props.romAssessment?.fingersThumboppositionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">Thumb Opposition</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.fingersThumboppositionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.fingersThumboppositionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.fingersMPFlexionLeft !== 0 || props.romAssessment?.fingersMPFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">MP Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.fingersMPFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.fingersMPFlexionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.fingersMPExtensionLeft !== 0 || props.romAssessment?.fingersMPExtensionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">MP Extension</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.fingersMPExtensionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.fingersMPExtensionRight}</p>
              </td>
            </tr>
          ) : null}

          {props.romAssessment?.fingersIPFlexionLeft !== 0 || props.romAssessment?.fingersIPFlexionRight !== 0 ? (
            <tr>
              <td className="border px-2 py-1 border-slate-300">IP Flexion</td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.fingersIPFlexionLeft}</p>
              </td>
              <td className="border px-2 py-1 text-left p-2 border-slate-300">
                <p>{props.romAssessment?.fingersIPFlexionRight}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  ) : null}
</div>

          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </>
  );
};

export default RangeOfMotion;
