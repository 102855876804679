// To parse this data:
//
//   import { Convert, Vitals } from "./file";
//
//   const vitals = Convert.toVitals(json);

export interface Vitals {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  bodyTemperature: number;
  bmi: number;
  oxygenPercentage: number;
  bloodPressureSyst: number;
  bloodPressureDias: number;
  pulseRate: number;
  respiratoryRate: number;
  height: number;
  weight: number;
  other: string;
  audit: Audit;
}

export interface Audit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class ConvertVitals {
  public static toVitals(json: string): Vitals {
    return JSON.parse(json);
  }

  public static vitalsToJson(value: Vitals): string {
    return JSON.stringify(value);
  }
}
