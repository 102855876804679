export interface ServiceList {
  id: string;
  name: string;
  type: string;
  defaultCost: number;
  description: string;
  duration: number;
  isActive: boolean;
}

export interface ServiceFormData {
  id: string;
  name: string;
  description: string;
  defaultCost: number;
  duration: number;
  isActive: boolean;
}

export interface SearchBarProps {
  searchQuery: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setModalOpen: (open: boolean) => void;
}

export interface ServicesConfigurationProps {
  branchId: string;
  entityId: string;
}

export interface ServiceApiResponse {
  data: unknown[];
}

export interface UpdateServiceStatusParams {
  id: string;
  branchId: string;
}

export class Convert {
  public static toServiceList(json: string): ServiceList {
    return JSON.parse(json);
  }

  public static serviceListToJson(value: ServiceList): string {
    return JSON.stringify(value);
  }

  public static toServiceFormData(service: ServiceList): ServiceFormData {
    return {
      id: service.id,
      name: service.name,
      description: service.description,
      defaultCost: service.defaultCost,
      duration: service.duration,
      isActive: service.isActive,
    };
  }
}

export const initialServiceFormState: ServiceFormData = {
  id: "",
  name: "",
  description: "",
  defaultCost: 0,
  duration: 0,
  isActive: true,
};
