import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import SolidButton, { ButtonVariant } from "@components/button/button";
import { NewMasterAssessmentData } from "../types/NewMasterAssessment";
import PdfExportDialog from "./PdfExportDialog";

export interface Assessments {
  [key: string]: AssessmentDetails[];
}

interface AssessmentDetails {
  name: string;
  isChecked: boolean;
}

type PDFProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
};

const PdfCheckboxDialog = (props: PDFProps) => {
  var checklistData: any = {
    medicalHistory: [
      { name: "Chief Complaint", isChecked: false },
      { name: "History of Present Illness", isChecked: false },
      { name: "History of Past Illness", isChecked: false },
      { name: "Pre Existing Health Conditions", isChecked: false },
      { name: "Past Treatment", isChecked: false },
      { name: "Surgeries", isChecked: false },
      { name: "Family Medical History", isChecked: false },
    ],
    physicalAssessments: [],
    diagnosis: [
      // { name: "Chief Complaint", isChecked: false },
      { name: "Provisional Diagnosis", isChecked: false },
      { name: "Differential Diagnosis", isChecked: false },
      { name: "Final Diagnosis", isChecked: false },
      { name: "Radiological Findings", isChecked: false },
      { name: "Medial Findings", isChecked: false },
    ],
    treatmentPlan: [
      { name: "Prescription", isChecked: false },
      { name: "Exercise Library", isChecked: false },
      { name: "Goals", isChecked: false },
    ],
  };

  const [assessmentData, setAssessmentData] = useState<Assessments | null>(
    checklistData
  );
  const [isPdfOpen, setIsPdfOpen] = React.useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (section: string, index: number) => {
    setAssessmentData((prevData) => {
      if (prevData) {
        const newData = { ...prevData };
        newData[section][index].isChecked = !newData[section][index].isChecked;
        return newData;
      }
      return prevData;
    });
  };

  const handleSelectAll = () => {
    const updatedData = { ...assessmentData };
    for (const section in updatedData) {
      updatedData[section as keyof Assessments] = updatedData[
        section as keyof Assessments
      ].map((item) => ({
        ...item,
        isChecked: !selectAll,
      }));
    }
    setAssessmentData(updatedData);
    setSelectAll(!selectAll);
  };

  function closeModal(value: boolean): void {}

  React.useEffect(() => {
    if (
      props.masterAssessmentData?.vitalAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Vitals",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.painAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Pain",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.rangeOfMotionAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Range of Motion",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.adlAssessmentId !== "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "ADL",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.customAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Custom",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.postureAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Posture",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.gaitAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Gait",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.muscleToneAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Muscle Tone",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.reflexAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Reflex",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.oedemaAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Oedema",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.limbLengthAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Limb Length",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.limbGirthAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Limb Girth",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.muscleWastingAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Muscle Wasting",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.sensoryAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Sensory",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.balanceAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Balance",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.muscleTightnessAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Muscle Tightness",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.muscleStrengthAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Muscle Strength",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.scarAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Scar",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.specialTestsAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Special Test",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.nerveTestAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Nerve Test",
        isChecked: false,
      });
    }

    if (
      props.masterAssessmentData?.structuralDeformitiesAssessmentId !==
      "000000000000000000000000"
    ) {
      checklistData.physicalAssessments.push({
        name: "Structural Deformities",
        isChecked: false,
      });
    }

    setAssessmentData(checklistData);
  }, [props.isOpen]);

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-1100"
                  >
                    <h4 className="font-medium text-md mb-1">
                      Examination Report
                    </h4>

                    <p className="text-grey-500 text-sm">
                      Please select the fields you would like to download
                    </p>
                  </Dialog.Title>
                  <div className="mt-2 w-full">
                    <ul>
                      <li className="flex flex-row items-center justify-between">
                        <span className="font-bold">Select All</span>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                          className="focus:outline-none"
                        ></input>
                      </li>
                    </ul>
                    <span className="font-bold">1. Medical History</span>
                    <ul>
                      {assessmentData &&
                        assessmentData.medicalHistory.map((e, index) => (
                          <li className="flex flex-row items-center justify-between">
                            <p>{e.name}</p>
                            <input
                              type="checkbox"
                              checked={e.isChecked}
                              onChange={() =>
                                handleCheckboxChange("medicalHistory", index)
                              }
                              className="focus:outline-none"
                            ></input>
                          </li>
                        ))}
                    </ul>
                    {assessmentData?.physicalAssessments.length !== 0 && (
                      <div>
                        <span className="font-bold">
                          2. Physical Assessments
                        </span>
                        <ul>
                          {assessmentData &&
                            assessmentData.physicalAssessments.map(
                              (e, index) => (
                                <li className="flex flex-row items-center justify-between">
                                  <p>{e.name}</p>
                                  <input
                                    type="checkbox"
                                    checked={e.isChecked}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "physicalAssessments",
                                        index
                                      )
                                    }
                                    className="focus:outline-none"
                                  ></input>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    )}
                    <span className="font-bold">
                      {assessmentData?.physicalAssessments.length === 0 ? (
                        <>2.</>
                      ) : (
                        <>3.</>
                      )}{" "}
                      Diagnosis
                    </span>
                    <ul>
                      {assessmentData &&
                        assessmentData.diagnosis.map((e, index) => (
                          <li className="flex flex-row items-center justify-between">
                            <p>{e.name}</p>
                            <input
                              type="checkbox"
                              checked={e.isChecked}
                              onChange={() =>
                                handleCheckboxChange("diagnosis", index)
                              }
                              className="focus:outline-none"
                            ></input>
                          </li>
                        ))}
                    </ul>
                    {assessmentData?.physicalAssessments.length === 0 ? (
                      <>3.</>
                    ) : (
                      <>4.</>
                    )}{" "}
                    <span className="font-bold"> Treatment Plan</span>
                    <ul>
                      {assessmentData &&
                        assessmentData.treatmentPlan.map((e, index) => (
                          <li className="flex flex-row items-center justify-between">
                            <p>{e.name}</p>
                            <input
                              type="checkbox"
                              checked={e.isChecked}
                              onChange={() =>
                                handleCheckboxChange("treatmentPlan", index)
                              }
                            ></input>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="mt-4 flex flex-row justify-end">
                    <SolidButton
                      variant={ButtonVariant.PRIMARY}
                      className="mr-2"
                      onClick={() => {
                        props.setIsOpen(false);
                        setAssessmentData(checklistData);
                        setSelectAll(false);
                      }}
                    >
                      Close
                    </SolidButton>
                    <SolidButton
                      variant={ButtonVariant.PRIMARY}
                      className="mr-2"
                      onClick={() => {
                        setIsPdfOpen(true);
                      }}
                    >
                      Download / Print
                    </SolidButton>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <PdfExportDialog
        isOpen={isPdfOpen}
        setIsOpen={setIsPdfOpen}
        patientId={props.patientId ?? ""}
        masterAssessmentData={props.masterAssessmentData}
        assessmentData={assessmentData}
        setIsCheckboxDialogOpen={props.setIsOpen}
        setAssessmentData={setAssessmentData}
        setSelectAll={setSelectAll}
      ></PdfExportDialog>
    </>
  );
};

export default PdfCheckboxDialog;
